import React, { CSSProperties } from 'react';
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import type { DraggableProvided } from '../../components/drags/DragComponents';
import type { EmailData } from './types';
import { ALNColors } from '../../utils/color';
import { ReactComponent as EmailBackIcon } from "../../assets/images/email-back-icon.svg";
import { ReactComponent as EmailEyeIcon } from "../../assets/images/email-eye-icon.svg";
// import { ReactComponent as EmailAddressIcon } from "../../assets/images/email-address-icon.svg";
import ALNBtn from '../../components/form/ALNBtn/ALNBtn';
import preventStandardKeyEvents from '../../components/drags/DragComponents/view/use-sensor-marshal/sensors/util/prevent-standard-key-events';

const getBackgroundColor = (
  isDragging: boolean,
  isGroupedOver: boolean,
) => {
  if (isDragging) {
    return ALNColors.white;
  }

  if (isGroupedOver) {
    return ALNColors.email_manual;
  }

  return ALNColors.white;
};

interface ContainerProps {
  isDragging: boolean;
  isGroupedOver: boolean;
}

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDragging' && prop !== 'isGroupedOver',
})<ContainerProps>(({ isDragging, isGroupedOver }) => ({
  borderRadius: '10px',
  border: '1px solid transparent',
  borderColor: isDragging ? ALNColors.email_manual : 'transparent',
  backgroundColor: getBackgroundColor(isDragging, isGroupedOver),
  opacity: isDragging ? 0.4 : 1,
  padding: '10px',
  minHeight: '40px',
  marginBottom: '10px',
  userSelect: 'none',
  color: ALNColors.blue_very_dark,
  '&:hover, &:active': {
    color: ALNColors.blue_very_dark,
    textDecoration: 'none',
  },
  '&:focus': {
    outline: 'none',
    borderColor: ALNColors.email_manual,
    boxShadow: 'none',
  },
  display: 'flex',
}));

function getStyle(provided: DraggableProvided, style?: CSSProperties | null) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

interface Props {
  emailData: EmailData;
  isDragging: boolean;
  provided: DraggableProvided;
  isClone?: boolean;
  isGroupedOver?: boolean;
  style?: CSSProperties;
  index?: number;
  onIgnoreEmailModalOpen: (email: EmailData) => void;
  onRemoveEmailModalOpen: (email: EmailData) => void;
  onDetailedEmailModalOpen: (email: EmailData) => void;
}

function EmailDataItem(props: Props) {
  const { emailData, isDragging, isGroupedOver, provided, style, index, onIgnoreEmailModalOpen, onRemoveEmailModalOpen, onDetailedEmailModalOpen } =
    props;
  const preventStandardKeyEvents = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <Container
      isDragging={isDragging}
      isGroupedOver={Boolean(isGroupedOver)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={emailData.id}
      data-index={index}
      aria-label={`${emailData.team.TeamMember} emailData ${emailData.content}`}
    >
      <Box
        onClick={() => {onDetailedEmailModalOpen(emailData)}}
        sx={{
          flexGrow: 1,
          flexBasis: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <Box
          sx={{
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '21px',
            color: ALNColors.email_manual,
          }}
        >
          {emailData.mail}
        </Box>
        <Box
          sx={{
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '18px',
            color: ALNColors.green_very_dark,
          }}
        >
          {emailData.content}
        </Box>
        <Box
          sx={{
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '9px',
            lineHeight: '21px',
            color: ALNColors.green_very_dark,
          }}
        >
          {
            emailData.date &&
            new Date(emailData.date).toLocaleString("en-US", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
          }
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            gap: "13px",
          }}
        >
          <ALNBtn 
            onClick = {(e: any) => {
              preventStandardKeyEvents(e);
              onRemoveEmailModalOpen(emailData);
            }}
            sx={{
              padding: "0px",
              margin: "0px",
              minWidth: "0px",
            }}
          >
            <EmailBackIcon />
          </ALNBtn>
          <ALNBtn 
            onClick = {(e: any) => {
              preventStandardKeyEvents(e);
              onIgnoreEmailModalOpen(emailData)
            }}  
            sx={{
              padding: "0px",
              margin: "0px",
              minWidth: "0px",
            }}
          >
            <EmailEyeIcon />
          </ALNBtn>
          {/* <ALNBtn onClick = { () => {} }  
            sx={{
              padding: "0px",
              margin: "0px",
              minWidth: "0px",
            }}
          >
            <EmailAddressIcon />
          </ALNBtn> */}
        </Box>
      </Box>
    </Container>
  );
}

export default React.memo<Props>(EmailDataItem);
