import React from 'react';
import { Box, Modal } from '@mui/material';
import ALNLabel from '../../../components/label/ALNLabel';
import ALNBtn from '../../../components/form/ALNBtn/ALNBtn';
import { ALNColors } from '../../../utils/color';

interface IgnoreMailModalProps {
    ignoreEmailModalOpen: boolean;
    handleIgnoreEmailModalClose: () => void;
    handleIgnoreEmailSend: () => void;
    modalTitle: string;
    modalDescription: string;
    modalCancelButton: string;
    modalConfirmButton: string;
}

function IgnoreMailModal ({ ignoreEmailModalOpen, handleIgnoreEmailModalClose, handleIgnoreEmailSend, modalTitle, modalDescription, modalCancelButton, modalConfirmButton }: IgnoreMailModalProps) {
  return (
    <Modal
        open={ignoreEmailModalOpen}
        onClose={handleIgnoreEmailModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '245px',
            height: '148px',
            bgcolor: ALNColors.white,
            borderRadius: '12px',
            border: '1px solid '+ALNColors.email_manual,
            paddingTop: "15px",
            paddingBottom: "10px",
            paddingLeft: "25px",
            paddingRight: "25px"
        }}>
            <ALNLabel
                sx={{
                    fontFamily: "OpenSans",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: 0.02,
                    textAlign: "left",
                    textTransform: 'capitalize',
                    color: ALNColors.email_manual,
                }}
            >
                {modalTitle}
            </ALNLabel>
            <ALNLabel
                sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: 0.03,
                    textAlign: "left",
                    color: ALNColors.black,
                    paddingTop: "25px",
                    paddingRight: "30px"
                }}
            >
                {modalDescription}
            </ALNLabel>
            <Box 
                sx={{
                    position: "absolute",
                    bottom: "20px",
                    left: "28px",
                    display: "flex",
                    gap: "10px",
                }}
            >
                <ALNBtn variant="contained" onClick = { () => {handleIgnoreEmailModalClose()} } 
                    sx={{ 
                        background: ALNColors.white,
                        width: "113px"
                    }}
                >
                    <ALNLabel
                        sx={{
                            fontFamily: "OpenSans",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: 0.02,
                            textAlign: "left",
                            textTransform: 'capitalize',
                            color: ALNColors.black,
                        }}
                    >
                        {modalCancelButton}
                    </ALNLabel>
                </ALNBtn>
                <ALNBtn variant="contained" onClick = { () => {handleIgnoreEmailSend()} } 
                    sx={{ 
                        background: ALNColors.primary_btn,
                        width: "113px"
                    }}
                >
                    <ALNLabel
                        sx={{
                            fontFamily: "OpenSans",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: 0.02,
                            textAlign: "left",
                            textTransform: 'capitalize',
                            color: ALNColors.white,
                        }}
                    >
                        {modalConfirmButton}
                    </ALNLabel>
                </ALNBtn>
            </Box>
        </Box>
    </Modal>
  );
}
export default IgnoreMailModal;
