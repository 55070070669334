import React, { CSSProperties, ReactElement } from 'react';
import { Box } from "@mui/material";
import { Droppable, Draggable } from '../../components/drags/DragComponents';
import type {
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from '../../components/drags/DragComponents';
import EmailDataItem from './emaildata-item';
import type { EmailData, Team } from './types';
import { ALNColors } from '../../utils/color';
import ALNBtn from '../../components/form/ALNBtn/ALNBtn';
import { ReactComponent as EmailAddIcon } from "../../assets/images/email-add-icon.svg";

export const getBackgroundColor = (
  isDraggingOver: boolean,
  isDraggingFrom: boolean,
): string => {
  if (isDraggingOver) {
    return ALNColors.pink_very_white;
  }
  if (isDraggingFrom) {
    return ALNColors.blue_very_sky;
  }
  return ALNColors.background_very_gray;
};

const scrollContainerHeight = 250;

interface Props {
  listId?: string;
  listType?: string;
  emailDatas: EmailData[];
  title?: string;
  internalScroll?: boolean;
  scrollContainerStyle?: CSSProperties;
  isDropDisabled?: boolean;
  isCombineEnabled?: boolean;
  style?: CSSProperties;
  ignoreContainerClipping?: boolean;
  useClone?: boolean;
  onAssignEmailModalOpen: (team: string) => void;
  onIgnoreEmailModalOpen: (email: EmailData) => void;
  onRemoveEmailModalOpen: (email: EmailData) => void;
  onDetailedEmailModalOpen: (email: EmailData) => void;
}

interface EmailDataListProps {
  emailDatas: EmailData[];
  onIgnoreEmailModalOpen: (email: EmailData) => void;
  onRemoveEmailModalOpen: (email: EmailData) => void;
  onDetailedEmailModalOpen: (email: EmailData) => void;
}

function InnerEmailDataList(props: EmailDataListProps): ReactElement {
  return (
    <Box>
      {props.emailDatas.map((emailData: EmailData, index: number) => (
        <Draggable key={emailData.id} draggableId={emailData.id} index={index}>
          {(
            dragProvided: DraggableProvided,
            dragSnapshot: DraggableStateSnapshot,
          ) => (
            <EmailDataItem
              key={emailData.id}
              emailData={emailData}
              isDragging={dragSnapshot.isDragging}
              isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
              provided={dragProvided}
              onIgnoreEmailModalOpen={(email) => props.onIgnoreEmailModalOpen(email)}
              onRemoveEmailModalOpen={(email) => props.onRemoveEmailModalOpen(email)}
              onDetailedEmailModalOpen={(email) => props.onDetailedEmailModalOpen(email)}
            />
          )}
        </Draggable>
      ))}
    </Box>
  );
}

const InnerEmailDataListMemo = React.memo<EmailDataListProps>(InnerEmailDataList);

interface InnerListProps {
  dropProvided: DroppableProvided;
  emailDatas: EmailData[];
  title: string | undefined | null;
  onIgnoreEmailModalOpen: (email: EmailData) => void;
  onRemoveEmailModalOpen: (email: EmailData) => void;
  onDetailedEmailModalOpen: (email: EmailData) => void;
}

function InnerList(props: InnerListProps) {
  const { emailDatas, dropProvided } = props;
  return (
    <Box>
      <Box 
        ref={dropProvided.innerRef}
        sx={{
          minHeight: scrollContainerHeight+'px',
          paddingBottom: '8px',
        }}
      >
        <InnerEmailDataListMemo 
          emailDatas={emailDatas} 
          onIgnoreEmailModalOpen={props.onIgnoreEmailModalOpen}
          onRemoveEmailModalOpen={props.onRemoveEmailModalOpen}
          onDetailedEmailModalOpen={props.onDetailedEmailModalOpen}
        />
        {dropProvided.placeholder}
      </Box>
    </Box>
  );
}

function EmailDataList(props: Props): ReactElement {
  const {
    ignoreContainerClipping,
    internalScroll,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    emailDatas,
    title,
    useClone,
    onAssignEmailModalOpen,
    onIgnoreEmailModalOpen,
    onRemoveEmailModalOpen,
    onDetailedEmailModalOpen,
  } = props;

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <EmailDataItem
                emailData={emailDatas[descriptor.source.index]}
                provided={provided}
                isDragging={snapshot.isDragging}
                isClone
                onIgnoreEmailModalOpen={(email) => onIgnoreEmailModalOpen(email)}
                onRemoveEmailModalOpen={(email) => onRemoveEmailModalOpen(email)}
                onDetailedEmailModalOpen={(email) => onDetailedEmailModalOpen(email)}
              />
            )
          : undefined
      }
    >
      {(
        dropProvided: DroppableProvided,
        dropSnapshot: DroppableStateSnapshot,
      ) => (
        <Box
          style={{
            backgroundColor: getBackgroundColor(dropSnapshot.isDraggingOver, Boolean(dropSnapshot.draggingFromThisWith)),
            display: 'flex',
            flexDirection: 'column',
            opacity: Boolean(isDropDisabled) ? 0.5 : 'inherit',
            border: '8px solid transparent',
            transition: 'background-color 0.2s ease, opacity 0.1s ease',
            userSelect: 'none',
            width: '215px',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
            paddingBottom: '12px',
          }}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <Box sx={{
              overflowX: 'hidden',
              overflowY: 'auto',
              maxHeight: scrollContainerHeight+'px',
            }}>
              <InnerList
                emailDatas={emailDatas}
                title={title}
                dropProvided={dropProvided}
                onIgnoreEmailModalOpen={(email) => onIgnoreEmailModalOpen(email)}
                onRemoveEmailModalOpen={(email) => onRemoveEmailModalOpen(email)}
                onDetailedEmailModalOpen={(email) => onDetailedEmailModalOpen(email)}
              />
              <ALNBtn 
                onClick = {() => { 
                    onAssignEmailModalOpen(listId || "");
                }}
                sx={{
                  fontFamily: "SF Pro",
                  fontWeight: "590",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: ALNColors.email_manual,
                  textTransform: "capitalize",
                }}
              >
                Assign another email +
              </ALNBtn>
            </Box>
          ) : (
            <>
              <InnerList
                emailDatas={emailDatas}
                title={title}
                dropProvided={dropProvided}
                onIgnoreEmailModalOpen={(email) => onIgnoreEmailModalOpen(email)}
                onRemoveEmailModalOpen={(email) => onRemoveEmailModalOpen(email)}
                onDetailedEmailModalOpen={(email) => onDetailedEmailModalOpen(email)}
              />
              <ALNBtn 
                onClick = {() => {
                  onAssignEmailModalOpen(listId || "");
                }}
                sx={{
                  fontFamily: "SF Pro",
                  fontWeight: "590",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: ALNColors.email_manual,
                  textTransform: "capitalize",
                  gap: "10px",
                }}
              >
                Assign another email
                <EmailAddIcon />
              </ALNBtn>
            </>
          )}
        </Box>
      )}
    </Droppable>
  );
}

export default EmailDataList;
