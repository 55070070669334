export const ALNColors = {
    primary: "#00a218",
    secondary: "#6c757d",
    success: "#198754",
    error: "#f44336",
    background: "#fbfbff",
    primary_btn: "#4C4E8D",
    red: "#ff0000",
    red_light: "#f8d7da",
    red_dark: "#721c24",
    red_very: "#D32F2F",
    red_medium_shade :"#ff7043",
    gray: "#999999",
    gray_dark: "#b3b3b3",
    gray_darker: "#666666",
    gray_very_light: "#e6e6e6",
    gray_medium: "#BDBDBD",
    gray_medium_light: "#cfd8dc",
    gray_very_dark: "#4d4d4d",
    gray_very_ligher: "#E0E2E4",
    black: "#000000",
    black_opacity: "#00000014",
    black_semi_transparent: 'rgba(0, 0, 0, 0.5)',
    black_light_grayish: "#C1C4C9",
    black_white: "#8E8E8F",
    green: "#051512",
    green_light: "#6FB391",
    green_very_dark: "#37300E",
    green_very_light: "#E2EDE8",
    green_medium_light: "#a5d6a7",
    yellow: "#e2cb00",
    yellow_very_dark: "#FF8C4B",
    pink_light: "#f8e7e7",
    indigo: "#3A4188",
    blue: "#2DC4A9",
    blue_light: "#3f51b5",
    white: "#ffffff",
    white_blue: "#3A2178",
    white_green: "#F7F8F8",
    white_black: "#4caf50",
    white_gray: "#eff2f5",
    white_medium_gray: "#EEEEEE",
    white_very_gray: "#F4F4F8",
    white_less: "#DCDCDC",
    lightgray: "#d3d3d3",
    ligthgray_dark: "#333333",
    primary_input_border: "#DFE7E5",
    email_auto: "#8CB8A2",
    email_couldnotrespond: "#DCCA9F",
    email_manual: "#4D4E8D",
    email_withissue: "#EF6341",
    email_ignored: "#636C78",
    border_gray: "#E4E4E4",
    border_very_gray: "#334155",
    pink_very_light_shade: "#f8bbd0",
    brown_light_shade: "#ffcc80",
    background_gray: "#00000033",
    background_very_gray: "#F6F6F9",
    backgroud_very_light_gray: "#EDEDF4",
    backgroud_transparent: "#4D4E8D1A",
    black_gray: "#808080",
    blue_very_dark: "#091E42",
    pink_very_white : "#FFEBE6",
    blue_very_sky: "#E6FCFF",
    blue_very_bright: "#0256C5",
    green_very_bright: "#4E48A4",
    red_very_bright: "#D34622",
    pink_very_light: "#FEF7F5",
    blue_background: "#F1F5F9",
    yellow_black: "#C5AE02",
}