/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesDeskAssignment
 */
export interface SalesDeskAssignment {
    /**
     * 
     * @type {string}
     * @memberof SalesDeskAssignment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesDeskAssignment
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesDeskAssignment
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesDeskAssignment
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesDeskAssignment
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesDeskAssignment
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesDeskAssignment
     */
    assignedTo?: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesDeskAssignment
     */
    assignedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesDeskAssignment
     */
    emailId?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesDeskAssignment
     */
    mailbox?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesDeskAssignment
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesDeskAssignment
     */
    version?: number;
}

/**
 * Check if a given object implements the SalesDeskAssignment interface.
 */
export function instanceOfSalesDeskAssignment(value: object): value is SalesDeskAssignment {
    return true;
}

export function SalesDeskAssignmentFromJSON(json: any): SalesDeskAssignment {
    return SalesDeskAssignmentFromJSONTyped(json, false);
}

export function SalesDeskAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesDeskAssignment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'assignedTo': json['assignedTo'] == null ? undefined : json['assignedTo'],
        'assignedDate': json['assignedDate'] == null ? undefined : (new Date(json['assignedDate'])),
        'emailId': json['emailId'] == null ? undefined : json['emailId'],
        'mailbox': json['mailbox'] == null ? undefined : json['mailbox'],
        'order': json['order'] == null ? undefined : json['order'],
        'version': json['version'] == null ? undefined : json['version'],
    };
}

export function SalesDeskAssignmentToJSON(value?: SalesDeskAssignment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdBy': value['createdBy'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedBy': value['modifiedBy'],
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'companyName': value['companyName'],
        'assignedTo': value['assignedTo'],
        'assignedDate': value['assignedDate'] == null ? undefined : ((value['assignedDate']).toISOString()),
        'emailId': value['emailId'],
        'mailbox': value['mailbox'],
        'order': value['order'],
        'version': value['version'],
    };
}

