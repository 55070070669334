import {
  Box,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Services } from "../Services";
import { Global } from "../Global";

import MailIcon from "@mui/icons-material/Mail";
import { TimePeriod } from "../services/models/TimePeriod";
import { MailBox } from "../services/models/MailBox";
import { ALNColors } from "../utils/color";
import ALNLabel from "../components/label/ALNLabel";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNMultiSelect from "../components/form/ALNSelect/ALNMultiSelect";
import { NotProcessedReason } from "../services/models/NotProcessedReason";
import { EmailStatus } from "../services/models/EmailStatus";
import { ProductBreakdownSort } from "../services/models/ProductBreakdownSort";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { User } from "../services/models/User";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";

function Dashboard_Reasons() {
  const [user, setUser] = useState<User | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
      "password" | "company"
  >("password");
  useEffect(() => {
      Global.getUser().then((user) => {
          setUser(user);
      });
  }, []);

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
      setAnchorEl(null);
  };
  const handleUpdateUserModal = () => {
      setUpdateUserModal(true);
  };
  const handleCloseUpdateUserModal = () => {
      setUpdateUserModal(false);
  };
  const handleLogout = () => {
      Global.setJwtToken(undefined);
  };
  const [dashboardData, setDashboardData] = useState<
    NotProcessedReason[] | undefined
  >();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Day);
  const [mailbox, setMailbox] = useState<string>(
    Global.getGlobal().CurrentMailbox ?? "",
  );
  const [allMailboxes, setAllMailboxes] = useState<string[]>([]);
  const [reason, setReason] = useState<string | undefined>(undefined);
    const [parseReasons, setParseReasons] = useState<
    { name: string; display: string }[]
  >([]);
  const [sort, setSort] = useState<ProductBreakdownSort>(
    ProductBreakdownSort.Count,
  );

  const [emailStatus, setEmailStatus] = useState<EmailStatus[]>(['NotProcessed', 'AutoProcessed']);
  const handleTagsChange = (event: any, newValues: any) => {
    setEmailStatus(newValues);
  };

  const token = Global.getJwtToken();
  useEffect(() => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailboxes: MailBox[]) => {
        setAllMailboxes(mailboxes.map((mailbox) => mailbox.emailAddress!));
      });
    Services.dashboardService
      .getParseReasons(Global.getJwtToken()!)
      .then((data) => {
        setParseReasons(data);
    });
  }, [token]);

  useEffect(() => {
    Services.dashboardService
      .getNonProcessedReasons(Global.getJwtToken()!, 
      {
        timePeriod: timePeriod,
        sortBy: sort,
        reason: undefined,
        status: emailStatus,
        mailbox: mailbox
      })
      .then((data) => {
        setDashboardData(data);
      });

  }, [timePeriod, mailbox, sort, reason, emailStatus]);


  const exportToCSV = () => {
    if(dashboardData) {
      const headers = ["User", "Count"];
      const csvContent = [
        headers.join(","),
        ...dashboardData.map(row => [row.reason, row.count].join(",")),
      ].join("\r\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "DashboardReasons.csv";
      link.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "25px 50px",
        background:
          "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <ALNLabel
          sx={{
            fontSize: "10px",
            lineHeight: "12px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Dashboard &gt; DashboardReasons
        </ALNLabel>
        <Box
          sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: "10px",
              padding: "8px 13px",
          }}
          onClick={handleUserInfo}
        >
          <Box
            component="img"
            src={avatarImg}
            alt="avatar"
            sx={{
              borderRadius: "50%",
              width: "30px",
              height: "30px",
            }}
          />
          <Box sx={{ margin: "0 0px" }}>
            <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
            <ALNLabel sx={{ display: "none" }}>
            {"["}
            {Global.getGlobal().company}
            {"]"}
            </ALNLabel>
          </Box>
          <DownIcon />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '40px',
          marginBottom: '20px',
        }}
      >
        <ALNLabel sx={{fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content"}}>
          DashboardReasons
        </ALNLabel>
        <ALNBtn 
          variant="contained"
          sx={{ bgcolor: ALNColors.email_manual, height: 45 }}
          onClick={exportToCSV}
          >
          <ALNLabel
            sx={{
              fontSize: '16px !important',
              fontWeight: 600,
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: 1.5,
              color: ALNColors.white,
              letterSpacing: '2%',
              textTransform: 'capitalize',
            }}
          >
            Export to Excel
          </ALNLabel>
        </ALNBtn>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        sx={{
          background: ALNColors.white,
          padding: "16px",
          borderRadius: "16px",
          border: "1px solid " + ALNColors.background_gray,
          flexGrow: 1,
          height: 'calc(100vh - 202px)',
        }}>
        <Box display="flex" alignItems="center" justifyContent="space-between"
          sx={{
            paddingBottom: "10px",
          }}
        >
          <Box sx={{ minWidth: 200, display: "flex", alignItems: "center" }}>
            <MailIcon
              sx={{
                marginRight: 1,
                marginLeft: 5,
                color: ALNColors.indigo,
                alignItems: "baseline",
              }}
            />
            <FormControl
              variant="standard"
              sx={{ minWidth: 300, color: ALNColors.indigo }}
            >
              <InputLabel id="mailbox-select-label">Mailbox</InputLabel>
              <Select
                labelId="mailbox-select-label"
                id="mailbox-select"
                value={mailbox}
                label="Mailbox"
                onChange={(event) => {
                  Global.getGlobal().CurrentMailbox = event.target.value;
                  setMailbox(event.target.value);
                }}
              >
                {allMailboxes.map((mailbox) => (
                  <MenuItem key={mailbox} value={mailbox}>
                    {mailbox}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <ToggleButtonGroup
            value={timePeriod}
            exclusive
            onChange={(event, newTimePeriod) => {
              if (newTimePeriod !== null) {
                setTimePeriod(newTimePeriod);
              }
            }}
            aria-label="time period"
            sx={{
              minWidth: 500,
              justifyContent: "stretch",
              color: ALNColors.primary,
            }}
          >
            {Object.values(TimePeriod).map((period) => (
              <ToggleButton
                key={period}
                value={period}
                aria-label={period}
                sx={{
                  minWidth: 150,
                  border: "none",
                  "&.Mui-selected": {
                    backgroundColor: ALNColors.indigo,
                    color: ALNColors.white,
                    "&:hover": {
                      backgroundColor: ALNColors.white_blue,
                    },
                  },
                }}
              >
                {period}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          padding={"10px"}
          marginTop={"10px"}
          borderTop={"1px solid #e0e0e0"}
          borderBottom={"1px solid #e0e0e0"}
        >
          <FormControl variant="standard" sx={{ minWidth: 300 }}>
            <InputLabel id="reason-select-label">Reason</InputLabel>
            <Select
              labelId="reason-select-label"
              id="reason-select"
              value={reason}
              label="Reason"
              onChange={(event) => setReason(event.target.value)}
            >
              <MenuItem key={"all"} value={undefined}>
                All
              </MenuItem>
              {parseReasons.map((reason) => (
                <MenuItem key={reason.name} value={reason.name}>
                  {reason.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ minWidth: 300 }}>
            <InputLabel id="sort-select-label">Sort By</InputLabel>
            <Select
              labelId="sort-select-label"
              id="sort-select"
              value={sort}
              label="Sort By"
              onChange={(event) =>
                setSort(event.target.value as ProductBreakdownSort)
              }
            >
              {Object.values(ProductBreakdownSort).map((sortOption) => (
                <MenuItem key={sortOption} value={sortOption}>
                  {sortOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ minWidth: 300 }}>
            <ALNMultiSelect 
              onChange={(event: any, newValues: any) => {
                handleTagsChange(event, newValues)
              }}
            />
          </FormControl>
        </Box>

        {dashboardData && (
          <Box 
            sx={{
              overflow: "auto",
              maxHeight: "80vh",
            }}
            mt={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              p={1}
              bgcolor={ALNColors.gray_very_dark}
              color={ALNColors.white}
            >
              <Box width="50%">User</Box>
              <Box width="50%">Count</Box>
            </Box>
            {dashboardData.map((item, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                p={1}
                bgcolor={index % 2 === 0 ? ALNColors.gray : ALNColors.white}
              >
                <Box width="50%">{item.reason}</Box>
                <Box width="50%">{item.count}</Box>
              </Box>
            ))}
            <Divider></Divider>
          </Box>
        )}
      </Box>
      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Divider />
        <MenuItem
          onClick={() => {
            handleUpdateUserModal();
            setModalTitle("Change Password");
            setCurrentlyChanged("password");
          }}
        >
          <ALNLabel variant="body1">Change Password</ALNLabel>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleUpdateUserModal();
            setModalTitle("Change Company");
            setCurrentlyChanged("company");
          }}
        >
          <ALNLabel variant="body1">Change Company</ALNLabel>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Logout />
          <ALNLabel variant="body1">Log out</ALNLabel>
        </MenuItem>
      </Menu>
      {user && (
        <UpdateUserModal
          currentlyChanged={currentlyChanged}
          open={updateUserModal}
          onClose={handleCloseUpdateUserModal}
          user={user!}
          title={modalTitle}
        />
      )}
    </Box>
  );
}

export default Dashboard_Reasons;
