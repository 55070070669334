import { useEffect, useState } from "react";
import { EmailAddressRules } from "../services/models";
import { Services } from "../Services";
import { EmailAddressRulesItem } from "./emaliAddressRules/EmailAddressRulesItem";
import { EmailAddressRulesModal } from "./emaliAddressRules/EmailAddressRulesModal";
import { Box, Checkbox, Divider, Menu, MenuItem } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Global } from "../Global";
import { ALNColors } from "../utils/color";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../components/label/ALNLabel";
import { ALNConfirmModal } from "../components/modal/ALNConfirmModal";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { User } from "../services/models/User";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";

export function EmailAddressRulesList() {
  const [user, setUser] = useState<User | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
      "password" | "company"
  >("password");
  useEffect(() => {
      Global.getUser().then((user) => {
          setUser(user);
      });
  }, []);

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
      setAnchorEl(null);
  };
  const handleUpdateUserModal = () => {
      setUpdateUserModal(true);
  };
  const handleCloseUpdateUserModal = () => {
      setUpdateUserModal(false);
  };
  const handleLogout = () => {
      Global.setJwtToken(undefined);
    };
  const [emailAddressRulees, setEmailAddressRulees] = useState<
    EmailAddressRules[]
  >([]);
  const [open, setOpen] = useState(false);
  const [editEmailAddressRule, setEditEmailAddressRule] =
    useState<EmailAddressRules>({
      addressWithWildcard: "",
      blockedReason: "",
    });
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [selectedEmailAddressRulees, setSelectedEmailAddressRulees] = useState<EmailAddressRules[]>([]);
  const [deleteEmailAddressRule, setDeleteEmailAddressRule] = useState<EmailAddressRules>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const fetchEmailAddressRulees = () => {
    Services.emailAddressRuleService
      .getEmailAddressRules(Global.getJwtToken()!)
      .then((emails) => {
        setEmailAddressRulees(emails);
      });
  };

  const token = Global.getJwtToken();

  useEffect(() => {
    fetchEmailAddressRulees();
  }, [token]);

  const handleSelectEmailAddressRule = (email: EmailAddressRules) => {
    setSelectedEmailAddressRulees(prev => 
      prev.includes(email) ? prev.filter(e => e.id !== email.id) : [...prev, email]
    );
  };

  const handleDeleteSelectedEmailAddressRulees = () => {
    if (selectedEmailAddressRulees.length > 0) {
      setConfirmModalOpen(true);
    }
  }

  const selectAll = () => {
    if (!checkedAll) {
      setSelectedEmailAddressRulees([...emailAddressRulees]);
    } else {
      setSelectedEmailAddressRulees([]);
    }
    setCheckedAll(!checkedAll);
  }

  const handleConfirmodalClose = () => {
    setConfirmModalOpen(false);
  };

  const confirmDeleteEmailAddressRule = (mail: EmailAddressRules) => {
    setConfirmModalOpen(true);
    setDeleteEmailAddressRule(mail);
  };

  const onDeleteEmailAddressRule = () => {
    if(!checkedAll && deleteEmailAddressRule) {
      Services.emailAddressRuleService
        .deleteEmailAddressRules(Global.getJwtToken()!, deleteEmailAddressRule?.id!)
        .then(() => {
          fetchEmailAddressRulees();
        });
    } else {
      if (selectedEmailAddressRulees.length > 0) {
        for (let i = 0; i < selectedEmailAddressRulees.length; i ++) {
          Services.emailAddressRuleService
          .deleteEmailAddressRules(Global.getJwtToken()!, selectedEmailAddressRulees[i].id!)
          .then(() => {
              if (i === selectedEmailAddressRulees.length-1) {
                fetchEmailAddressRulees();
              }
          });
        }
        setSelectedEmailAddressRulees([]);
        setCheckedAll(false);
      }
    }
  }

  const handleEditEmailAddressRule = (emailAddressRule: EmailAddressRules) => {
    setEditEmailAddressRule(emailAddressRule);
    setOpen(true);
  };

  const handleOpen = () => {
    setEditEmailAddressRule({
      addressWithWildcard: "",
      blockedReason: "",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditEmailAddressRule({
      addressWithWildcard: "",
      blockedReason: "",
    });
    setTimeout(() => {
      fetchEmailAddressRulees();
    }, 200);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
          Email Rules
        </ALNLabel>
        <Box
          sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: "10px",
              padding: "8px 13px",
          }}
          onClick={handleUserInfo}
      >
          <Box
              component="img"
              src={avatarImg}
              alt="avatar"
              sx={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
              }}
          />
          <Box sx={{ margin: "0 0px" }}>
              <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
              <ALNLabel sx={{ display: "none" }}>
              {"["}
              {Global.getGlobal().company}
              {"]"}
              </ALNLabel>
          </Box>
          <DownIcon />
        </Box>
      </Box>
      <Box 
        sx={{
          background: ALNColors.white,
          padding: "25px 25px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          height: 'calc(100vh - 150px)',
          overflowX: 'hidden',
        }}
      >
        {/* Header Part */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddtingTop: "0px",
            marginBottom: "24px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <ArrowBackIcon
              style={{
                marginRight: "14px",
                cursor: "pointer",
                }}
                onClick={() => {
              }}
            />
            <ALNLabel sx={{ fontSize: "24px", lineHeight: "18px", fontWeight: 700 }}>
              Email Rules
            </ALNLabel>
          </Box>
          <ALNBtn
              sx={{
                  borderRadius: '8px',
                  padding: '8px 16px',
                  marginRight: "28px",
                  bgcolor: ALNColors.primary_btn,
                  fontFamily: "OpenSans",
                  fontSize: '15px',
                  fontWeight: 590,
                  lineHeight: '21px',
                  letterSpacing: 0.02,
                  textAlign: "left",
                  color: ALNColors.white,
                  cursor: "pointer",
                  textTransform: 'capitalize',
              }}
              onClick={ handleOpen }
          >
              Add Rule
          </ALNBtn>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "15px",
            }}
          >
            <Checkbox 
              checked={checkedAll} 
              onChange={selectAll}
            />
            <DeleteForeverIcon
              sx={{
                cursor: "pointer"
              }}
              onClick={() => {
                handleDeleteSelectedEmailAddressRulees();
              }}
            />
          </Box>
          <Divider></Divider>
          <Box 
            sx={{ overflowY: 'auto', maxHeight: '100%' }}>
            {emailAddressRulees.map((emailAddressRule) => (
              <EmailAddressRulesItem
                key={emailAddressRule.id}
                emailAddressRule={emailAddressRule}
                onDelete={confirmDeleteEmailAddressRule}
                openEditModal={handleEditEmailAddressRule}
                onSelect={() => handleSelectEmailAddressRule(emailAddressRule)}
                selectedEmailAddressRulees={selectedEmailAddressRulees}
              />
            ))}
          </Box>

          {emailAddressRulees.length === 0 && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              width={"100%"}
              height={"300px"}
            >
              <ALNLabel variant="body1" sx={{ color: ALNColors.secondary}}>
                No Email Rules
              </ALNLabel>
            </Box>
          )}
        </Box>
      </Box>
      <ALNConfirmModal
        modalTitle="Delete Email Rule"
        modalDescription="Are you sure you want to delete this email rule?"
        open={confirmModalOpen}
        onClose={handleConfirmodalClose}
        onDeleteItem={onDeleteEmailAddressRule}
      />
      <EmailAddressRulesModal
        open={open}
        onClose={() => handleClose()}
        emailAddressRule={editEmailAddressRule}
      />
      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Divider />
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Password");
                setCurrentlyChanged("password");
            }}
        >
            <ALNLabel variant="body1">Change Password</ALNLabel>
        </MenuItem>
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Company");
                setCurrentlyChanged("company");
            }}
        >
            <ALNLabel variant="body1">Change Company</ALNLabel>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
            <Logout />
            <ALNLabel variant="body1">Log out</ALNLabel>
        </MenuItem>
      </Menu>
      {user && (
          <UpdateUserModal
              currentlyChanged={currentlyChanged}
              open={updateUserModal}
              onClose={handleCloseUpdateUserModal}
              user={user!}
              title={modalTitle}
          />
      )}
    </Box>
  );
}
