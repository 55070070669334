import { ChangeEvent, useEffect, useState } from "react";
import { EmailAddressRules, RuleType } from "../../services/models";
import { Services } from "../../Services";
import { Box, MenuItem } from "@mui/material";
import { Global } from "../../Global";
import ALNModal from "../../components/modal/ALNModal";
import ALNModalTitle from "../../components/modal/ALNModalTitle";
import ALNModalHeader from "../../components/modal/ALNModalHeader";
import ALNInput from "../../components/form/ALNInput/ALNInput";
import ALNCancelBtn from "../../components/form/ALNBtn/ALNCancelBtn";
import ALNBtn from "../../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../../components/label/ALNLabel";
import { ALNColors } from "../../utils/color";

export type EmailAddressRuleModalProps = {
  open: boolean;
  onClose: () => void;
  emailAddressRule?: EmailAddressRules;
};

export function EmailAddressRulesModal(props: EmailAddressRuleModalProps) {
  const [emailAddressRule, setEmailAddressRule] = useState<EmailAddressRules>(
    {},
  );

  useEffect(() => {
    if (props.emailAddressRule) {
      setEmailAddressRule(props.emailAddressRule);
    } else {
      Services.emailAddressRuleService
        .createEmailAddressRulesData(Global.getJwtToken()!)
        .then((data) => {
          setEmailAddressRule(data);
        });
    }
  }, [props.emailAddressRule]);

  const handleClose = () => {
    setEmailAddressRule({
      addressWithWildcard: "",
      blockedReason: "",
    });
    props.onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmailAddressRule((prevEmailAddressRule) => ({
      ...prevEmailAddressRule,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    Services.emailAddressRuleService.saveEmailAddressRules(
      Global.getJwtToken()!,
      emailAddressRule,
    )
    .then(() => {})
    .catch(err => {
      console.log(err);
    });
    
    handleClose();
  };

  return (
    <ALNModal
      open={props.open}
      onClose={handleClose}
    >
      <ALNModalHeader>
        <ALNModalTitle title = {emailAddressRule.addressWithWildcard !== "" ? "Edit Email Rule" : "New Email Rule"} />
        <ALNCancelBtn onClick = { handleClose } />
      </ALNModalHeader>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        sx={{
          paddingBottom: '20px',
          paddingLeft: "32px",
          paddingRight: "32px",
          paddingTop: '0px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>Edit Email Address</ALNLabel>
        <ALNInput
          label="Email Address"
          id="addressWithWildcard"
          name="addressWithWildcard"
          value={emailAddressRule.addressWithWildcard}
          onChange={handleChange}
        />
        <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>Edit Rule Type</ALNLabel>
        <ALNInput
          type="select"
          label="Rule Type"
          id="ruleType"
          name="ruleType"
          value={emailAddressRule.ruleType}
          onChange={handleChange}
        >
          <MenuItem value={RuleType.Block}>Block</MenuItem>
          <MenuItem value={RuleType.ForwardAll}>Forward All</MenuItem>
          <MenuItem value={RuleType.ForwardOverLimit}>Forward Over Limit</MenuItem>
        </ALNInput>
        {emailAddressRule.ruleType === RuleType.Block && (
          <Box>
            <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>Edit Blocked Reason</ALNLabel>
            <ALNInput 
              label="Blocked Reason"
              id="blockedReason"
              name="blockedReason"
              value={emailAddressRule.blockedReason}
              onChange={handleChange}
            />
          </Box>
        )}

        {emailAddressRule.ruleType !== RuleType.Block && (
          <Box>
            <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>Edit Foward To</ALNLabel>
            <ALNInput 
              label="Forward To"
              id="forwardTo"
              name="forwardTo"
              value={emailAddressRule.forwardTo}
              onChange={handleChange}
            />
          </Box>
        )}

        {emailAddressRule.ruleType === RuleType.ForwardOverLimit && (
          <ALNInput 
            label="Forward Over Limit"
            id="forwardOverLimit"
            name="forwardOverLimit"
            value={emailAddressRule.forwardOverLimit}
            onChange={handleChange}
          />
        )}
        <ALNBtn 
          variant="contained"
          type="submit"
          sx={{ bgcolor: ALNColors.email_manual, mt: 3, width: "120px", ml: "auto", float: "inline-end"}}
          onClick = { () => {} }>
            Submit
        </ALNBtn>
      </Box>
    </ALNModal>
  );
}
