import React, { useEffect, useState } from "react";
import { Box, Modal, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DisplayEmail } from "../DisplayEmail";
import ALNBtn from "../../components/form/ALNBtn/ALNBtn";
import ALNLabel from '../../components/label/ALNLabel';
import { ALNColors } from '../../utils/color';
import { Services } from "../../Services";
import { Global } from "../../Global";
import type { EmailData } from './types';
import { Email } from "../../services/models/Email";

interface MailDetailedProps {
    email?: EmailData;
    mailDetailedModalOpen: boolean;
    handleMailDetailedModalClose: () => void;
}

function MailDetailed ({ email, mailDetailedModalOpen, handleMailDetailedModalClose }: MailDetailedProps) {
  const [page, setPage] = useState<"reply" | "details">(
    "reply",
  );
  const [emailitem, setEmailItem] = React.useState<Email | null>(null);
  const token = Global.getJwtToken()!;

  useEffect(() => {
    setPage("reply");
    if (email) {
        Services.emailService
        .getEmail(token, email?.mailbox!, email?.emailid!)
        .then((data: Email) => {
            setEmailItem(data);
        });
    }
  }, [email]);

return (
    <Modal
        open={mailDetailedModalOpen}
        onClose={handleMailDetailedModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '826px',
            height: '528px',
            bgcolor: ALNColors.white,
            borderRadius: '12px',
            border: '1px solid '+ALNColors.email_manual,
            paddingTop: "15px",
            paddingBottom: "10px",
            paddingLeft: "25px",
            paddingRight: "25px"
        }}>
            <ALNLabel
                sx={{
                    fontFamily: "OpenSans",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: 0.02,
                    textAlign: "left",
                    textTransform: 'capitalize',
                    color: ALNColors.email_manual,
                }}
            >
                Reply to Email
            </ALNLabel>
            {/* Toggle Button */}
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "left",
                        marginTop: "35px",
                        marginLeft: "30px",
                    }}
                >
                    <Box
                        sx={{
                            padding: '5px',
                            background: ALNColors.backgroud_very_light_gray,
                            borderRadius: "6px",
                        }}
                    >
                        <ToggleButtonGroup
                            value={page}
                            exclusive
                            onChange={(event, newValue) => {
                            setPage(newValue);
                            }}
                            sx= {{
                                width: "80%",
                                height: "42px",
                            }}
                        >
                            <ToggleButton
                            sx={{
                                minWidth: 150,
                                borderRadius: "8px",
                                color: ALNColors.border_very_gray,
                                border: "none",
                                "&.Mui-selected": {
                                backgroundColor: ALNColors.indigo,
                                color: ALNColors.white,
                                "&:hover": {
                                    backgroundColor: ALNColors.white_blue,
                                },
                                },
                                textTransform: 'capitalize',
                            }}
                            value="reply"
                            >
                            Reply Mail
                            </ToggleButton>
                            <ToggleButton
                            sx={{
                                minWidth: 150,
                                border: "none",
                                borderRadius: "8px",
                                color: ALNColors.border_very_gray,
                                "&.Mui-selected": {
                                backgroundColor: ALNColors.indigo,
                                color: ALNColors.white,
                                "&:hover": {
                                    backgroundColor: ALNColors.white_blue,
                                },
                                },
                                textTransform: 'capitalize',
                            }}
                            value="details"
                            >
                            Details
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Box>
                {page === "reply" && (
                    <Box
                        sx={{
                            height: "345px",
                            overflowY: "auto",
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "30px",
                            marginRight: "30px",
                            borderRadius: "8px",
                            background: ALNColors.background_very_gray,
                        }}
                    >
                        <DisplayEmail email={emailitem?.reply!} isReply={true} />
                    </Box>
                )}

                {page === "details" && (
                    <Box
                        sx={{ 
                            height: "345px",
                            overflowY: "auto",
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "30px",
                            marginRight: "30px",
                            borderRadius: "8px",
                            background: ALNColors.background_very_gray,
                        }}
                    >
                    {emailitem?.debugInfo && (
                        <Box
                            sx={{
                                padding: '30px',
                                paddingLeft: "45px",
                            }}
                        >
                            <ALNLabel variant="h5" sx={{ color: ALNColors.black, border: "none", marginBottom: "8px", fontWeight: "600" }}>
                                Details:
                            </ALNLabel>
                            <ALNLabel
                                sx={{ 
                                    display: 'block', 
                                    fontFamily: 'monospace', 
                                    whiteSpace: "pre-wrap", 
                                    wordWrap: "break-word",
                                }}
                            >
                                {JSON.stringify(emailitem?.debugInfo, null, 2)}
                            </ALNLabel>
                        </Box>
                    )}
                    </Box>
                )}
            </Box>
            {/* Three Buttons */}
            <Box
                sx={{
                    display: "flex",
                    paddingTop: "20px",
                    gap: "18px"
                }}
            >
                <ALNBtn variant="contained" onClick = { () => {handleMailDetailedModalClose()} } 
                    sx={{ 
                        color: ALNColors.black,
                        background: ALNColors.white,
                        width: "350px",
                        "&:hover": {
                            backgroundColor: ALNColors.primary_btn,
                            color: ALNColors.white,
                        },
                    }}
                >
                    <ALNLabel
                        sx={{
                            fontFamily: "OpenSans",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: 0.02,
                            textAlign: "left",
                            textTransform: 'capitalize',
                        }}
                    >
                        Cancel
                    </ALNLabel>
                </ALNBtn>
                <ALNBtn variant="contained" onClick = { () => {} } 
                    sx={{ 
                        color: ALNColors.black,
                        background: ALNColors.white,
                        width: "350px",
                        "&:hover": {
                            backgroundColor: ALNColors.primary_btn,
                            color: ALNColors.white,
                        },
                    }}
                >
                    <ALNLabel
                        sx={{
                            fontFamily: "OpenSans",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: 0.02,
                            textAlign: "left",
                            textTransform: 'capitalize',
                        }}
                    >
                        Save as Draft
                    </ALNLabel>
                </ALNBtn>
                <ALNBtn variant="contained" onClick = { () => {} } 
                    sx={{ 
                        color: ALNColors.black,
                        background: ALNColors.white,
                        width: "350px",
                        "&:hover": {
                            backgroundColor: ALNColors.primary_btn,
                            color: ALNColors.white,
                        },
                    }}
                >
                    <ALNLabel
                        sx={{
                            fontFamily: "OpenSans",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: 0.02,
                            textAlign: "left",
                            textTransform: 'capitalize',
                        }}
                    >
                        Send
                    </ALNLabel>
                </ALNBtn>
            </Box>
        </Box>
    </Modal>
  );
}
export default MailDetailed;
