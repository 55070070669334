import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EmailSummary } from "../services/models/EmailSummary";
import { EmailStatus } from "../services/models/EmailStatus";
import { ALNColors } from "../utils/color";
import ALNLabel from "./label/ALNLabel";
import { ReactComponent as Rounded3dots } from "../assets/images/rounded-3-dots.svg";
// import avatarImg from "../assets/images/avatar.png";

interface EmailItemProps {
  data: EmailSummary;
  isSelected: boolean;
}

function EmailItem({ data, isSelected }: EmailItemProps) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);
  const [iconUrl, setIconUrl] = useState<string | null>(
    "email-not-respond-icon.png",
  );
  const [colorVal, setColorVal] = useState<string>(`${ALNColors.yellow}`);
  const [emailStatus, setEmailStatus] = useState<string>("");

  useEffect(() => {
    import(`../assets/images/${iconUrl}`)
      .then((module) => setIconSrc(module.default))
      .catch((err) => console.error("Error loading icon:", err));
  }, [iconUrl]);

  useEffect(() => {
    const emailStatus = data.emailStatus;
    switch (emailStatus) {
      case EmailStatus.AutoProcessed:
        setIconUrl("email-auto-respond-icon.png");
        setColorVal(`${ALNColors.email_auto}`);
        setEmailStatus("Auto");
        break;
      case EmailStatus.ManualProcessed:
        setIconUrl("email-manual-respond-icon.png");
        setColorVal(`${ALNColors.email_manual}`);
        setEmailStatus("Manually");
        break;
      case EmailStatus.NotProcessed:
        setIconUrl("email-not-respond-icon.png");
        setColorVal(`${ALNColors.email_couldnotrespond}`);
        setEmailStatus("Could Not Respond");
        break;
      case EmailStatus.Ignored:
        setIconUrl("email-ignore-icon.png");
        setColorVal(`${ALNColors.email_ignored}`);
        setEmailStatus("Ignored");
        break;
      case EmailStatus.IssueReported:
        setIconUrl("email-annotated-icon.png");
        setColorVal(`${ALNColors.email_withissue}`);
        setEmailStatus("Issue Reported");
        break;
      default:
        setIconUrl("email-not-respond-icon.png");
        setColorVal(`${ALNColors.email_couldnotrespond}`);
        setEmailStatus("Could Not Respond");
    }
  }, [data.emailStatus]);

  const removeHTMLTags = (htmlString: string) => {
    return htmlString.replace(/<[^>]*>/g, '');
  }

  const calculateTimeDifference = (minutes: string): string => {
    const givenDate = new Date(minutes);
    const currentDate = new Date();
    const diffMs = currentDate.getTime() - givenDate.getTime();
    const diffMinutes = Math.round(diffMs / (1000 * 60));

    let hours = Math.floor(diffMinutes / 60);
    let remainingMinutes = diffMinutes % 60;
    remainingMinutes = remainingMinutes < 1 ? 1 : remainingMinutes;
    let formattedTime = hours > 0 
        ? `${hours}h ${remainingMinutes}m` 
        : `${remainingMinutes}m`;
    return formattedTime;
}

  return (
    <Box
      sx={{
        display: 'flex',
        height: "80px",
        alignItems: 'center',
        padding: '16px 0px',
        borderRadius: '4px',
        backgroundColor: ALNColors.white,
        marginTop: '2px',
        boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.14)',
        borderLeftWidth: '5px',
        borderLeftColor: ALNColors.yellow,
        justifyContent: 'space-between',
        position: "relative",
      }}
      bgcolor={isSelected ? ALNColors.gray : undefined}
      style={{ borderLeftColor: colorVal }}
    >
      {/* Customer Name */}
      <Box sx={{ width: "20%" }}>
        <ALNLabel 
          sx={{
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '21px',
            letterSpacing: 0.02,
            textAlign: 'left',
            padding: "0 0px",
            paddingRight: "12px",
            wordWrap: "break-word",
            wordBreak: "break-all",
            color: ALNColors.green_very_dark
          }}
        >{data.from}</ALNLabel>
      </Box>
      {/* Sales Person */}
      {/* <Box sx={{ width: "15%", display: "flex" }}>
        <Box
          component="img"
          src={avatarImg}
          alt="avatar"
          sx={{
            borderRadius: "50%",
            width: "20px",
            height: "20px",
          }}
        />
        <ALNLabel 
          sx={{
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: 0.02,
            textAlign: 'left',
            padding: "0 0px",
            paddingRight: "12px",
            wordWrap: "break-word",
            wordBreak: "break-all",
            color: ALNColors.green_very_dark,
            paddingLeft: "7px",
          }}
        >{""}</ALNLabel>
      </Box> */}
      {/* Status */}
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: "0 0px",
          paddingRight: "12px",
          minWidth: "200px",
          maxWidth: "300px"
        }}>
        <Box sx={{
            display: "flex",
            justifyContent: "start",
            gap: "8px",
            width: "fit-content",
            border: "1px solid #000000",
            borderWidth: "1px",
            borderRadius: "100px",
            padding:"5px 12px",
          }}
          style={{ borderColor: colorVal || ALNColors.black }}
        >          
          {iconSrc && 
            <Box
              component="img"
              src={iconSrc}
              alt={data.emailStatus}
              height="12.22px"
              sx={{ paddingTop: "3px" }}
            />
          }
          <ALNLabel
            sx={{
              fontFamily: '"OpenSans"',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '18px',
              letterSpacing: '0.02em',
              textAlign: 'left',
              color: colorVal || ALNColors.black,
              textTransform: 'uppercase'
            }}
          >
            {emailStatus}
          </ALNLabel>
        </Box>
      </Box>
      {/* Email */}
      <Box sx={{ width: "30%", marginRight: "55px" }}>
        <ALNLabel 
          sx={{fontFamily: '"Inter"',
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: '18px',
            padding: "0 0px",
            marginBottom: "6px",
            marginTop: "6px",
            letterSpacing: 0.02,
            textAlign: 'left',
            color: ALNColors.green_very_dark,
          }}
        >
          {data.subject}
        </ALNLabel>
        <ALNLabel 
          sx={{
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '18px',
            padding: "0 0px",
            letterSpacing: '0.02em',
            color: ALNColors.green_very_dark,
            textAlign: 'left',
            overflow: "hidden",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            height: "40px",
          }}
        >
          {data?.partialBody ? removeHTMLTags(data.partialBody).replaceAll("\n", "").replaceAll("&nbsp;", "") : null}
        </ALNLabel>
      </Box>
      {/* Received */}
      <Box sx={{ width: "13%" }}>
        <ALNLabel
          sx = 
          {{
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '500',
            padding: "0 0px",
            lineHeight: '21px',
            letterSpacing: '0.02em',
            color: ALNColors.green_very_dark,
            paddingRight: "12px",
          }}
        >
          {data.emailDate?.toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </ALNLabel>
      </Box>
      {/* Time In Queue */}
      <Box sx={{ width: "20%" }}>
        <ALNLabel
          sx = 
          {{
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '500',
            padding: "0 0px",
            lineHeight: '21px',
            letterSpacing: '0.02em',
            color: ALNColors.green_very_dark,
            paddingLeft: "12px",
          }}
        >
          {
            calculateTimeDifference(
              data.emailDate
                ? data.emailDate.toLocaleString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                : ""
            )
          }
        </ALNLabel>
      </Box>
      <Box sx={{
        position: "absolute",
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      }}>
        <Rounded3dots/>
      </Box>
    </Box>
  );
}

export default EmailItem;
