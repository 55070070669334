import { useEffect, useState } from "react";
import { Box, Checkbox, Divider, Menu, MenuItem } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import { Company } from "../services/models";
import { CompanyListItem } from "./company/CompanyListItem";
import { CompanyModal } from "./company/CompanyModal";
import { Global } from "../Global";
import { Services } from "../Services";
import { ALNColors } from "../utils/color";
import ALNLabel from "../components/label/ALNLabel";
import { ALNConfirmModal } from "../components/modal/ALNConfirmModal";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { User } from "../services/models/User";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";

export function CompaniesList(): JSX.Element {
  const [user, setUser] = useState<User | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
      "password" | "company"
  >("password");
  useEffect(() => {
      Global.getUser().then((user) => {
          setUser(user);
      });
  }, []);

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
      setAnchorEl(null);
  };
  const handleUpdateUserModal = () => {
      setUpdateUserModal(true);
  };
  const handleCloseUpdateUserModal = () => {
      setUpdateUserModal(false);
  };
  const handleLogout = () => {
      Global.setJwtToken(undefined);
  };
  const [companyModalOpen, setCompanyModalOpen] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [editCompany, setEditCompany] = useState<Company>({
    companyName: "",
    companyLogoURL: "",
    maxEmailsPerUserPerDay: 0,
  });
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  const [deleteCompany, setDeleteCompany] = useState<Company>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const token = Global.getJwtToken();

  useEffect(() => {
    refreshCompanies();
  }, [token]);

  const handleSelectCompany = (company: Company) => {
    setSelectedCompanies(prev => 
      prev.includes(company) ? prev.filter(c => c.id !== company.id) : [...prev, company]
    );
  };

  const handleDeleteSelectedCompanies = () => {
    if (selectedCompanies.length > 0) {
      setConfirmModalOpen(true);
    }
  }

  const selectAll = () => {
    if (!checkedAll) {
      setSelectedCompanies([...companies]);
    } else {
      setSelectedCompanies([]);
    }
    setCheckedAll(!checkedAll);
  }

  const handleConfirmodalClose = () => {
    setConfirmModalOpen(false);
  };

  const confirmDeleteCompany = (company: Company) => {
    setConfirmModalOpen(true);
    setDeleteCompany(company);
  };

  const onDeleteCompany = () => {
    if(!checkedAll && deleteCompany) {
      Services.companyService
        .deleteCompany(Global.getJwtToken()!, deleteCompany?.companyName!)
        .then(() => {
          refreshCompanies();
        });
    } else {
      if (selectedCompanies.length > 0) {
        for (let i = 0; i < selectedCompanies.length; i ++) {
          Services.companyService
          .deleteCompany(Global.getJwtToken()!, selectedCompanies[i].companyName!)
          .then(() => {
              if (i === selectedCompanies.length-1) {
                refreshCompanies();
              }
          });
        }
        setSelectedCompanies([]);
        setCheckedAll(false);
      }
    }
  }

  const handleNewCompanyClick = () => {
    setCompanyModalOpen(true);
  };

  const handleCompanyModalClose = () => {
    setCompanyModalOpen(false);
    Services.companyService
      .createCompanyData(Global.getJwtToken()!)
      .then((data) => {
        setEditCompany(data);
      });
    setTimeout(() => {
      Global.resetCompanyList();
    }, 200);
  };

  const handleEditCompany = (company: Company) => {
    setEditCompany((prevCompany) => ({ ...prevCompany, ...company }));
    setCompanyModalOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
          Companies
        </ALNLabel>
        <Box
          sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: "10px",
              padding: "8px 13px",
          }}
          onClick={handleUserInfo}
        >
          <Box
              component="img"
              src={avatarImg}
              alt="avatar"
              sx={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
              }}
          />
          <Box sx={{ margin: "0 0px" }}>
              <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
              <ALNLabel sx={{ display: "none" }}>
              {"["}
              {Global.getGlobal().company}
              {"]"}
              </ALNLabel>
          </Box>
          <DownIcon />
        </Box>
      </Box>
      <Box
        sx={{ 
          background: ALNColors.white,
          padding: "25px 25px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          height: 'calc(100vh - 150px)',
          overflowX: 'hidden',
        }}
      >
        {/* Header Part */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddtingTop: "0px",
            marginBottom: "24px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <ArrowBackIcon
              style={{
                marginRight: "14px",
                cursor: "pointer",
                }}
                onClick={() => {
              }}
            />
            <ALNLabel sx={{ fontSize: "24px", lineHeight: "18px", fontWeight: 700 }}>
              Companies
            </ALNLabel>
          </Box>
          <ALNBtn
              sx={{
                  borderRadius: '8px',
                  padding: '8px 16px',
                  marginRight: "28px",
                  bgcolor: ALNColors.primary_btn,
                  fontFamily: "OpenSans",
                  fontSize: '15px',
                  fontWeight: 590,
                  lineHeight: '21px',
                  letterSpacing: 0.02,
                  textAlign: "left",
                  color: ALNColors.white,
                  cursor: "pointer",
                  textTransform: 'capitalize',
              }}
              onClick={ handleNewCompanyClick }
          >
              New Company
          </ALNBtn>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "15px",
            }}
          >
            <Checkbox 
              checked={checkedAll} 
              onChange={selectAll}
            />
            <DeleteForeverIcon
              sx={{
                cursor: "pointer"
              }}
              onClick={() => {
                handleDeleteSelectedCompanies();
              }}
            />
          </Box>
          <Divider></Divider>
          <Box 
            sx={{ overflowY: 'auto', maxHeight: '100%' }}>
            {companies.map((company: Company) => (
              <CompanyListItem
                key={company.id}
                company={company}
                onDelete={confirmDeleteCompany}
                openEditModal={handleEditCompany}
                onSelect={() => handleSelectCompany(company)}
                selectedCompanies={selectedCompanies}
              />
            ))}
          </Box>

          {companies.length === 0 && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              width={"100%"}
              height={"300px"}
            >
              <ALNLabel variant="body1" sx={{ color: ALNColors.secondary}}>
                No Companies
              </ALNLabel>
            </Box>
          )}
        </Box>
      </Box>
      <ALNConfirmModal
        modalTitle="Delete Company"
        modalDescription="Are you sure you want to delete this company?"
        open={confirmModalOpen}
        onClose={handleConfirmodalClose}
        onDeleteItem={onDeleteCompany}
      />
      <CompanyModal
        company={editCompany}
        open={companyModalOpen}
        onClose={handleCompanyModalClose}
      />
      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Divider />
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Password");
                setCurrentlyChanged("password");
            }}
        >
            <ALNLabel variant="body1">Change Password</ALNLabel>
        </MenuItem>
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Company");
                setCurrentlyChanged("company");
            }}
        >
            <ALNLabel variant="body1">Change Company</ALNLabel>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
            <Logout />
            <ALNLabel variant="body1">Log out</ALNLabel>
        </MenuItem>
        </Menu>
        {user && (
          <UpdateUserModal
              currentlyChanged={currentlyChanged}
              open={updateUserModal}
              onClose={handleCloseUpdateUserModal}
              user={user!}
              title={modalTitle}
          />
        )}
    </Box>
  );

  function refreshCompanies() {
    Services.companyService
      .getAllCompanies(Global.getJwtToken()!)
      .then((data) => {
        setCompanies(data);
      });
  }
}
