import { useEffect } from 'react';
import { error } from '../dev-warning';
import useDev from './use-dev';

export default function useDevSetupWarning(fn: () => void, inputs?: unknown[]) {
  useDev(() => {
    useEffect(() => {
      try {
        fn();
      } catch (e) {
        if (e instanceof Error) {
          error(`
            A setup problem was encountered.
      
            > ${e.message}
          `);
        } else {
          error(`
            A setup problem was encountered.
      
            > An unknown error occurred
          `);
        }
      }
    }, inputs);
  });
}
