import { EmailAddressRules } from "../../services/models";
import { Box, Checkbox } from "@mui/material";
import deleteIcon from "../../assets/images/material-symbols_delete.svg";
import editIcon from "../../assets/images/tabler_edit.svg";
import { ALNColors } from "../../utils/color";
import ALNItem from "../../components/form/ALNItem/ALNItem"
import ALNIconBtn from "../../components/form/ALNBtn/ALNIconBtn"

export type EmailAddressRuleItemProps = {
  emailAddressRule: EmailAddressRules;
  onDelete: (emailAddressRule: EmailAddressRules) => void;
  openEditModal: (emailAddressRule: EmailAddressRules) => void;
  onSelect: (emailAddressRule: EmailAddressRules) => void;
  selectedEmailAddressRulees: EmailAddressRules[];
};

export function EmailAddressRulesItem ({ emailAddressRule, onDelete, openEditModal, onSelect, selectedEmailAddressRulees }: EmailAddressRuleItemProps) {
  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        borderRadius: 1,
        bgcolor: ALNColors.white,
        boxShadow: 1,
        marginTop: "0px",
        cursor: 'pointer',
        '&': { mt: 2 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center"
        }}
      >
        <Checkbox 
          checked={selectedEmailAddressRulees.some(e => e.id === emailAddressRule.id)} 
          onChange={() => onSelect(emailAddressRule)}
        />
        <Box>
          <ALNItem firstName = { emailAddressRule.addressWithWildcard } lastName = "" />
        </Box>
      </Box>
      <Box>
        <ALNIconBtn obj = { emailAddressRule } onClick = {() => openEditModal(emailAddressRule)} src = { editIcon } alt = "Edit" />
        <ALNIconBtn obj = { emailAddressRule } onClick = {() => onDelete(emailAddressRule)} src = { deleteIcon } alt = "Delete" />
      </Box>
    </Box>
  );
};
