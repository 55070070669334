import { Box, Snackbar, Alert, SnackbarCloseReason } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import reorder, { reorderEmailDataMap } from './reorder';
import EmailDataLists from './emaildata-lists';
import IgnoreMailModal from "./modal/IgnoreMailModal";
import AssignMailModal from "./modal/AssignMailModal";
import type { Team, EmailData, EmailDataMap, UnAssignedEmailData } from './types';
import type {
  DropResult,
  DraggableLocation,
  DroppableProvided,
} from '../../components/drags/DragComponents';
import { DragDropContext, Droppable } from '../../components/drags/DragComponents';
import { PartialAutoScrollerOptions } from '../../components/drags/DragComponents/state/auto-scroller/fluid-scroller/auto-scroller-options-types';
import { MailBox } from "../../services/models";
import { Services } from "../../Services";
import { Global } from "../../Global";
import { SalesDeskApi } from "../../services/apis/SalesDeskApi";
interface Props {
  withScrollableColumns?: boolean;
  isCombineEnabled?: boolean;
  containerHeight?: string;
  useClone?: boolean;
  applyGlobalStyles?: boolean;
  autoScrollerOptions?: PartialAutoScrollerOptions;
}

const TimeInQueue: React.FC<Props> = ({
  withScrollableColumns,
  isCombineEnabled = false,
  containerHeight,
  useClone,
  autoScrollerOptions,
}) => {
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [alertStatus, setAlertStatus] = useState<boolean>(false);
  const [alertModalOpen, setAlertModalOpen] = useState<boolean>(false);
  const autoHideDuration = 3000;
  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertModalOpen(false);
  };
  const handleAlert = (status: boolean, msg: string, modalOpen: boolean) => {
    setAlertStatus(status);
    setAlertMsg(msg);
    setAlertModalOpen(modalOpen);
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertModalOpen(false);
    }, autoHideDuration);
    return () => clearTimeout(timer);
  }, []);
  const [teams, setTeams] = useState<Team[]>([]);
  const [emailDatas, setEmailDatas] = useState<EmailData[]>([]);
  const [columns, setColumns] = useState<EmailDataMap>();
  const [ordered, setOrdered] = useState<string[]>([]);
  const [unAssignedEmails, setUnAssignedEmails] = useState<UnAssignedEmailData[]>([]);

  const [assignEmailModalOpen, setAssignEmailModalOpen] = useState<boolean>(false);
  const [ignoreEmailModalOpen, setIgnoreEmailModalOpen] = useState<boolean>(false);
  const [removeEmailModalOpen, setRemoveEmailModalOpen] = useState<boolean>(false);
  const [assignEmail, setAssignEmail] = useState("");
  const [teamTitle, setTeamTitle] = useState("");
  const [ignoreEmail, setIgnoreEmail] = useState<EmailData>();
  const [removeEmail, setRemoveEmail] = useState<EmailData>();

  // Reassign Mail Modal Part
  const handleAssignEmailModalOpen = (teamTitle: string) => {
    setTeamTitle(teamTitle);
    setAssignEmailModalOpen(true);
  };

  const handleAssignEmailModalClose = () => {
    setAssignEmailModalOpen(false);
  };

  const handleAssignEmail = (email: string) => {
    setAssignEmail(email);
  }

  const handleAssignEmailSend = async () => {
    try {
      const token = Global.getJwtToken()!;
      const mailbox = unAssignedEmails.filter(email => assignEmail === email.assignid)[0].mailbox;
      const assignID = assignEmail;
      const assignTo = teams.find(team => team.TeamMember === teamTitle)?.address;
      setAssignEmailModalOpen(false);
      await Services.salesDeskService.assignEmail(token, mailbox!, assignID!, assignTo!);
      fetchTeamInQueueData();
      setAssignEmail("");
      setTeamTitle("");
      handleAlert(true, "Assigned mail Successfully!", true);
    } catch (error) {
      handleAlert(false, String(error), true);
    }
  };

  // Ignore Email Modal Part
  const handleIgnoreEmailModalOpen = (email: EmailData) => {
    setIgnoreEmail(email);
    setIgnoreEmailModalOpen(true);
  };

  const handleIgnoreEmailModalClose = () => {
    setIgnoreEmailModalOpen(false);
  };

  const handleIgnoreEmailSend = async () => {
    try {
      const token = Global.getJwtToken();
      const mailbox = ignoreEmail?.mailbox || '';
      const id = ignoreEmail?.emailid || '';
      if (!mailbox || !id) {
        handleAlert(false, "Missing mailbox or id for ignoring email assignment.", true);
        return;
      }
      setIgnoreEmailModalOpen(false);
      await Services.salesDeskService.ignoreAssignment(token!, mailbox, id);
      fetchTeamInQueueData();
      handleAlert(true, "Ignored mail Successfully!", true);
    } catch (error) {
      handleAlert(false, String(error), true);
    }
  }

  // Remove Email Modal Part
  const handleRemoveEmailModalOpen = (email: EmailData) => {
    setRemoveEmail(email);
    setRemoveEmailModalOpen(true);
  };

  const handleRemoveEmailModalClose = () => {
    setRemoveEmailModalOpen(false);
  };

  const handleRemoveEmailSend = async () => {
    try {
      const token = Global.getJwtToken();
      const mailbox = removeEmail?.mailbox || '';
      const id = removeEmail?.emailid || '';
      if (!mailbox || !id) {
        handleAlert(false, "Missing mailbox or id for ignoring email assignment.", true);
        return;
      }
      setRemoveEmailModalOpen(false);
      await Services.salesDeskService.removeAssignment(token!, mailbox, id);
      fetchTeamInQueueData();
      handleAlert(true, "Removed mail Successfully!", true);
    } catch (error) {
      handleAlert(false, String(error), true);
    }
  }

  // Detailed Email Modal Part
  const handleDetailedEmailModalOpen = (email: EmailData) => {
    console.log("email", email);
  };

  const getEmailDataByTeam = (team: Team, items: EmailData[]): EmailData[] =>
    items.filter((emailData: EmailData) => emailData.team.id === team.id);

  const generateTeamEmailDataMap = (teamsData: Team[], emailDatas: EmailData[]) =>
    teamsData.reduce(
      (previous: EmailDataMap, team: Team) => ({
        ...previous,
        [team.TeamMember]: getEmailDataByTeam(team, emailDatas),
      }),
      {},
    );

  const calculateTimeDifference = (minutes: string): number => {
    const givenDate = new Date(minutes);
    const currentDate = new Date();
    const diffMs = currentDate.getTime() - givenDate.getTime();
    const diffMinutes = Math.round(diffMs / (1000 * 60));
    return diffMinutes;
  }

  const token = Global.getJwtToken();

  const fetchTeamInQueueData = async () => {
    const unassignedColumns: UnAssignedEmailData[] = [];
    let temp_teams: Team[] = [];
    let temp_emailDatas: EmailData[] = [];
    for (let i = 6; i > 1; i--) {
      let teamMember = "";
      if (i === 2) {
        teamMember = "5 min";
      } else if (i === 6) {
        teamMember = "30 min";
      } else if (i === 5) {
        teamMember = "20-30 min";
      } else if (i === 4) {
        teamMember = "10-20 min";
      } else {
        teamMember = "5-10 min";
      }
      temp_teams.push({
        id: String(i),
        address: "noreply@domain.com",
        TeamMember: teamMember,
      })
    }
    setTeams(temp_teams);
    const loadData = async (mailbox: MailBox) => {
      try {
        if (!token) {
          handleAlert(false, "No JWT token available", true);
          return;
        }
        const [assignments, users] = await Promise.all([
          Services.salesDeskService.getTeamAssignments(token, mailbox.emailAddress!),
          Services.salesDeskService.getAssignableUsers(token)
        ]);
        for (let i = 0; i < users.length; i++) {
          if (assignments[users[i].emailAddress].length !== 0) {
            for (let j = 0; j < assignments[users[i].emailAddress].length; j++) {
              const time = assignments[users[i].emailAddress][j].assignment.assignedDate;
              const minutesDiff = calculateTimeDifference(time);
              let team: Team;
              if (minutesDiff <= 5) {
                team = temp_teams[4];
              } else if (5 < minutesDiff && minutesDiff <= 10) {
                team = temp_teams[3];
              } else if (10 < minutesDiff && minutesDiff <= 20) {
                team = temp_teams[2];
              } else if (20 < minutesDiff && minutesDiff <= 30) {
                team = temp_teams[1];
              } else {
                team = temp_teams[0];
              }
              temp_emailDatas.push({
                id: assignments[users[i].emailAddress][j].assignment.id,
                mailbox: mailbox.emailAddress!,
                mail: assignments[users[i].emailAddress][j].assignment.assignedTo,
                emailid: assignments[users[i].emailAddress][j].email.id,
                emailstatus: assignments[users[i].emailAddress][j].email.emailStatus,
                content: assignments[users[i].emailAddress][j].email.cannotReplyReason,
                date: assignments[users[i].emailAddress][j].assignment.createdDate,
                team: team!,
              })
            }
          }
        }
        for (let i = 0; i < assignments.unassigned.length; i++) {
          let minutesDiff = 0;
          const assignedDate = assignments.unassigned[i].assignment.assignedDate?.toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });

          if (assignedDate) {
            minutesDiff = calculateTimeDifference(assignedDate);
          }
          let timeinqueue = "";
          if (minutesDiff <= 5) {
            timeinqueue = "< 5 min";
          } else if (5 < minutesDiff && minutesDiff <= 10) {
            timeinqueue = "5-10 min";
          } else if (10 < minutesDiff && minutesDiff <= 20) {
            timeinqueue = "10-20 min";
          } else if (20 < minutesDiff && minutesDiff <= 30) {
            timeinqueue = "20-30 min";
          } else {
            timeinqueue = "> 30 min";
          }
          unassignedColumns.push({
            id: i,
            customer: assignments.unassigned[i].assignment.companyName,
            email: assignments.unassigned[i].email.from,
            status: assignments.unassigned[i].email.cannotReplyReason,
            received: assignedDate,
            timeinqueue: timeinqueue,
            emailstatus: assignments.unassigned[i].email.emailStatus,
            mailbox: assignments.unassigned[i].assignment.mailbox,
            assignid: assignments.unassigned[i].assignment.id,
          });
        }
      } catch (error) {
        handleAlert(false, String(error), true);
      }
    };
    try {
      const mailboxes = await Services.mailBoxService.getMailBoxes(token!);
      for (const mailbox of mailboxes) {
        await loadData(mailbox);
      }
      let temp_map = generateTeamEmailDataMap(temp_teams, temp_emailDatas);
      setColumns(temp_map);
      setOrdered(Object.keys(temp_map));
      setEmailDatas(temp_emailDatas);
      setUnAssignedEmails(unassignedColumns);
    } catch (error) {
      handleAlert(false, String(error), true);
    }
  };

  useEffect(() => {
    fetchTeamInQueueData();
  }, [token]);

  const onDragEnd = useCallback(async (result: DropResult): Promise<void> => {
    if (result.combine) {
      if (result.type === 'COLUMN') {
        const shallow: string[] = [...ordered];
        shallow.splice(result.source.index, 1);
        setOrdered(shallow);
        return;
      }

      if (!columns) {
        handleAlert(false, "Columns is undefined", true);
        return;
      }
      const column: EmailData[] = columns[result.source.droppableId];
      const withEmailDataRemoved: EmailData[] = [...column];
      withEmailDataRemoved.splice(result.source.index, 1);
      const updatedColumns: EmailDataMap = {
        ...columns,
        [result.source.droppableId]: withEmailDataRemoved,
      };
      setColumns(updatedColumns);
      return;
    }

    if (!result.destination) {
      return;
    }

    const source: DraggableLocation = result.source;
    const destination: DraggableLocation = result.destination;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (result.type === 'COLUMN') {
      const reordered: string[] = reorder(
        ordered,
        source.index,
        destination.index,
      );
      setOrdered(reordered);
      return;
    }

    const updatedData = reorderEmailDataMap({
      emailDataMap: columns || {},
      source,
      destination,
    });

    const auth = Global.getJwtToken()!;
    const mailbox = emailDatas.some(email => email.team.TeamMember === source.droppableId)
      ? emailDatas[source.index].mailbox!
      : null;
    const emailBeingDragged = columns?.[source.droppableId]?.[source.index];
    const assignID = emailBeingDragged?.id;
    const assignTo = teams.find(team => team.TeamMember === destination.droppableId)?.address;
    if (!columns) {
      throw new Error("Columns are undefined. Please ensure 'columns' is properly initialized.");
    }
    const nextEmail: EmailData[] = [...(columns[destination.droppableId] || [])];
    const prevId = nextEmail[destination.index - 1]?.id || undefined;
    const nextId = nextEmail[destination.index]?.id || undefined;
    let prev_columns = columns;
    // Unassign email
    if (assignTo === "unassigned") {
      try {
        setColumns(updatedData.emailDataMap);
        await Services.salesDeskService.unassignEmail(auth, mailbox!, assignID!);
        handleAlert(true, "Unassigned Mail Successfully!", true);
        return;
      } catch (error) {
        handleAlert(false, String(error), true);
        setColumns(prev_columns);
        return;
      }
    }

    // Assign email to empty User
    if (prevId === undefined && nextId === undefined) {
      try {
        setColumns(updatedData.emailDataMap);
        await Services.salesDeskService.assignEmail(auth, mailbox!, assignID!, assignTo!);
        handleAlert(true, "Assigned Mail Successfully!", true);
        return;
      } catch (error) {
        handleAlert(false, String(error), true);
        setColumns(prev_columns);
        return;
      }
    }

    // Assign email particular order
    Services.salesDeskService
      .assignEmail(auth, mailbox!, assignID!, assignTo!, String(prevId)!, String(nextId)!)
      .then(() => {
        handleAlert(true, "Assigned Mail Successfully!", true);
      })
      .catch((err) => {
        handleAlert(false, err.message, true);
        setColumns(prev_columns);
      });
    setColumns(updatedData.emailDataMap);
  }, [columns, ordered]);

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd} autoScrollerOptions={autoScrollerOptions}>
        <Droppable
          droppableId="board"
          type="COLUMN"
          direction="horizontal"
          ignoreContainerClipping={Boolean(containerHeight)}
          isCombineEnabled={isCombineEnabled}
        >
          {(provided: DroppableProvided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                backgroundColor: 'white',
                minHeight: '100vh',
                display: 'inline-flex',
                padding: '20px',
                paddingLeft: "0px",
              }}
            >
              {ordered.map((key: string, index: number) => (
                <EmailDataLists
                  key={key}
                  index={index}
                  title={key}
                  emailDatas={columns?.[key] || []}
                  isScrollable={withScrollableColumns}
                  isCombineEnabled={isCombineEnabled}
                  useClone={useClone}
                  onAssignEmailModalOpen={(team) => handleAssignEmailModalOpen(team)}
                  onIgnoreEmailModalOpen={(email) => handleIgnoreEmailModalOpen(email)}
                  onRemoveEmailModalOpen={(email) => handleRemoveEmailModalOpen(email)}
                  onDetailedEmailModalOpen={(email) => handleDetailedEmailModalOpen(email)}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {/* Assign Another Mail Modal */}
      <AssignMailModal
        assignEmailModalOpen={assignEmailModalOpen}
        handleAssignEmailModalClose={handleAssignEmailModalClose}
        handleAssignEmail={handleAssignEmail}
        handleAssignEmailSend={handleAssignEmailSend}
        users={unAssignedEmails.map(email => ({
          value: email.assignid ? String(email.assignid) : "",
          name: String(email.email || "")
        }))
          .filter(user => user.name !== "" && user.value !== "")
        }
        modalTitle="Assign Another Mail"
        cancelButton="Cancel"
        confirmButton="Assign"
      />
      {/* Ignore Mail Modal */}
      <IgnoreMailModal
        ignoreEmailModalOpen={ignoreEmailModalOpen}
        handleIgnoreEmailModalClose={handleIgnoreEmailModalClose}
        handleIgnoreEmailSend={handleIgnoreEmailSend}
        modalTitle="Ignore Email"
        modalDescription="Are you sure you want to ignore this email?"
        modalCancelButton="Cancel"
        modalConfirmButton="Ignore"
      />
      {/* Remove Mail Modal */}
      <IgnoreMailModal
        ignoreEmailModalOpen={removeEmailModalOpen}
        handleIgnoreEmailModalClose={handleRemoveEmailModalClose}
        handleIgnoreEmailSend={handleRemoveEmailSend}
        modalTitle="Remove Email"
        modalDescription="Are you sure you want to remove this email?"
        modalCancelButton="Cancel"
        modalConfirmButton="Remove"
      />
      {/* Alert */}
      <Snackbar
        open={alertModalOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={autoHideDuration}
      >
        <Alert severity={alertStatus ? "success" : "error"} sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default TimeInQueue;
