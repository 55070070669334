import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  TextField,
  InputAdornment,
  Menu,
  Divider,
  MenuItem,
  MenuProps,
} from "@mui/material";
import { styled, alpha } from '@mui/material/styles';
import EmailState from "../components/EmailState";
import EmailItem from "../components/EmailItem";
import { Services } from "../Services";
import { EmailStatus } from "../services/models/EmailStatus";
import { EmailSummary } from "../services/models/EmailSummary";
import { Global } from "../Global";
import { Email } from "../services/models";
import EmailEditLayer from "./email/layers/EmailEditLayer";
import { ALNColors } from "../utils/color";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../components/label/ALNLabel";
import { ReactComponent as LeftPaginate } from "../assets/images/leftpaginate.svg";
import { ReactComponent as RightPaginate } from "../assets/images/rightpaginate.svg";
import { ReactComponent as SortArrow } from "../assets/images/sortArrow.svg";
import { ReactComponent as AddPlus } from "../assets/images/addPlug.svg";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as SearchIconInBlack } from "../assets/images/searchIconInBlack.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { User } from "../services/models/User";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: "10px",
    paddingTop: "0px",
    paddingBottom: "0px",
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    borderRadius: "16px",
    border: "1px solid " + ALNColors.background_gray,
    '& .MuiMenu-list': {
      padding: '0 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

function EmailList(props: { mailboxName: string }): JSX.Element {
  const token = Global.getJwtToken()!;
  const [user, setUser] = useState<User | undefined>();
  const [anchorElModal, setAnchorElModal] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
      "password" | "company"
  >("password");
  useEffect(() => {
      Global.getUser().then((user) => {
          setUser(user);
      });
  }, [token]);

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElModal(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
      setAnchorElModal(null);
  };
  const handleUpdateUserModal = () => {
      setUpdateUserModal(true);
  };
  const handleCloseUpdateUserModal = () => {
      setUpdateUserModal(false);
  };
  const handleLogout = () => {
      Global.setJwtToken(undefined);
  };
  const EmailStatusArray = Object.values(EmailStatus);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [selectedStatuses, setSelectedStatuses] = useState<EmailStatus[]>(['NotProcessed', 'AutoProcessed']);


  const handleEmailStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusConfig = {
    All: {
      displaytitle: "All E-Mails",
      icon: "",
      color: ALNColors.blue,
    },
    AutoProcessed: {
      displaytitle: "Auto",
      icon: "email-auto-respond-icon.png",
      color: ALNColors.email_auto,
    },
    ManualProcessed: {
      displaytitle: "Manual",
      icon: "email-manual-respond-icon.png",
      color: ALNColors.email_manual,
    },
    NotProcessed: {
      displaytitle: "Could Not Respond",
      icon: "email-not-respond-icon.png",
      color: ALNColors.email_couldnotrespond,
    },
    Ignored: {
      displaytitle: "Ignored",
      icon: "email-ignore-icon.png",
      color: ALNColors.email_ignored,
    },
    IssueReported: {
      displaytitle: "With Issues",
      icon: "email-annotated-icon.png",
      color: ALNColors.email_withissue,
    },
  };

  const mailboxName = props.mailboxName;

  const emailStates = EmailStatusArray.map((status, index) => ({
    id: index + 1,
    status: status,
    title: status,
    displaytitle: statusConfig[status].displaytitle,
    icon: statusConfig[status].icon,
    color: statusConfig[status].color,
  }));

  const [page, setPage] = useState<number>(1);
  const pageSize = 10;

  const [filter, setFilter] = useState<EmailStatus>(EmailStatus.All);

  const [emailToEdit, setEmailToEdit] = React.useState<Email | null>(null);
  const [lastEmailToEdit, setLastEmailToEdit] = React.useState<Email | null>(
    null,
  );

  const [filteredEmailList, setFilteredEmailList] = React.useState<
    EmailSummary[]
  >([]);

  const [searchValue, setSearchValue] = React.useState<string>(
    Global.getGlobal().lastSearchString ?? "",
  );

  useEffect(() => {
    Global.getGlobal().lastSearchString = undefined;
  });

  useEffect(() => {
    if (mailboxName === undefined) {
      setFilteredEmailList([]);
    } else {
      fetchEmails(filter, searchValue, page);
      // fetchEmails(selectedStatuses, searchValue, page);
    }
  }, [searchValue, token, mailboxName, page]);

  const handleManualFilter = (status: EmailStatus) => {
    // if(status === "All") {
    //   let a = selectedStatuses.filter(u => u === "All");
    //   setSelectedStatuses(emailStates.map(state => state.status));
    // } else {
    //   setSelectedStatuses([]);
    // }
    // setSelectedStatuses(prev => 
    //   prev.includes(status) ? prev.filter(u => u !== status) : [...prev, status]
    // );
    // fetchEmails(selectedStatuses, searchValue, page);
    setFilter(status);
    fetchEmails(status, searchValue, page);
    setPage(1);
  };

  const handleClickItem = (data: EmailSummary) => {
    Services.emailService
      .getEmail(token, mailboxName!, data.id!)
      .then((email: Email) => {
        setEmailToEdit(email);
        setLastEmailToEdit(email);
        // Global.getGlobal().CurrentPage = Pages.EmailQuoteRequest;
      });
  };

  // const printFormattedList = (a: any) => {
  //   // Create an empty array
  //   let temp_array = [];

  //   // Loop from 1 to 5 (exclusive) and push each number into the array
  //   for (let i = 1; i <= a; i++) {
  //     temp_array.push(i);
  //   }

  //   const minLength = Math.max(temp_array.length, 4);  
  //   let result = '';
  //   for (let i = 0; i < minLength; i++) {
  //     result += temp_array[i] + (i < minLength - 1 ? ',' : '');
  //   }
  //   return result;
  // }

  function fetchEmails(
    emailStatus: EmailStatus,
    searchValue: string,
    pg: number,
  ) {
    let beginDate: Date | undefined = undefined;
    let endDate: Date | undefined = undefined;

    if (searchValue !== "") {
      Services.emailService
        .searchEmails(
          Global.getJwtToken()!,
          mailboxName,
          searchValue,
          emailStatus,
          beginDate,
          endDate,
          pg,
          pageSize,
        )
        .then((emails: any) => {
          setFilteredEmailList(emails);
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      Services.emailService
        .getEmailsFiltered(
          Global.getJwtToken()!,
          mailboxName,
          emailStatus,
          beginDate,
          endDate,
          pg,
          pageSize,
        )
        .then((emails: any) => {
          setFilteredEmailList(emails);
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchValue(event.target.value);
    setPage(1);
  };

  return (
    <Box
      sx={{
        padding: "25px 50px",
        background:
          "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)",
      }}
    >
      {!emailToEdit && (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <ALNLabel
              sx={{
                fontSize: "10px",
                lineHeight: "12px",
                fontWeight: 500,
                width: "fit-content",
              }}
            >
              Email &gt; replyaitest.dev
            </ALNLabel>
            <Box
              sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  gap: "10px",
                  padding: "8px 13px",
              }}
              onClick={handleUserInfo}
            >
              <Box
                  component="img"
                  src={avatarImg}
                  alt="avatar"
                  sx={{
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                  }}
              />
              <Box sx={{ margin: "0 0px" }}>
                  <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
                  <ALNLabel sx={{ display: "none" }}>
                  {"["}
                  {Global.getGlobal().company}
                  {"]"}
                  </ALNLabel>
              </Box>
              <DownIcon />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px"
            }}
          >
            <ALNLabel
              sx={{
                fontSize: "40px",
                lineHeight: "50px",
                fontWeight: 400,
                width: "fit-content",
              }}
            >
              {Global.getGlobal().CurrentMailbox ?? "No Mailbox Selected"}
            </ALNLabel>
            <Box display={"flex"} justifyContent="space-between">
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <FormControl
                  sx={{
                    minWidth: 300,
                    width: "35vw",
                    border: "1px solid " + ALNColors.background_gray,
                    borderRadius: "100px",
                  }}
                >
                  <TextField
                    id="search-listing"
                    label=""
                    placeholder="Search list by keyword"
                    variant="standard"
                    onChange={handleSearch}
                    value={searchValue}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIconInBlack />
                            {/* <SearchIcon style={{ color: ALNColors.gray }} /> */}
                          </InputAdornment>
                        ),
                      },
                    }}
                    sx={{
                      color: ALNColors.black_white,
                      height: "45px",
                      "& .MuiInputBase-root": {
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "21px",
                        letterSpacing: "0.02em",
                        textAlign: "left",
                        color: ALNColors.black_white,
                        height: "100%",
                        border: "1px solid " + ALNColors.primary_input_border,
                        boxShadow: "0px 1px 1px 0px #00000014",
                        borderRadius: "100px",
                        backgroundColor: ALNColors.white_very_gray,
                        padding: "0 12px",
                      },
                      "& .MuiInputBase-root::before": {
                        border: "none !important",
                      },
                      "& .MuiInputBase-root::after": {
                        border: "none",
                      },
                      '& input[type="text"]': {
                        border: "none",
                        paddingLeft: "0",
                      },
                    }}
                  />
                </FormControl>
                <Box
                  onClick={() => setSearchValue("")}
                  paddingLeft={"5px"}
                  sx={{ display: "none" }}
                >
                  X
                </Box>
              </Box>
              {/* <Box
                sx={{
                  display: "none",
                  alignItems: "center",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRadius: "8px",
                  backgroundColor: ALNColors.black,
                  boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.08)",
                  gap: "8px",
                }}
              >
                {emailStates.map((item) => (
                  <EmailState
                    manualFilter={handleManualFilter}
                    activeStatus={filter}
                    key={item.id}
                    data={item}
                    // selectedStatuses={selectedStatuses}
                  />
                ))}
              </Box> */}
              {/* Status: All */}
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    background: ALNColors.white,
                    border: "1px solid " + ALNColors.background_gray,
                    padding: "9px 12px",
                    cursor: "pointer",
                  }}
                  onClick={handleEmailStatus}
                >
                  <ALNLabel>Status: All</ALNLabel>
                  <DownIcon />
                </Box>
                <ALNBtn
                  variant="contained"
                  sx={{ bgcolor: ALNColors.email_manual, height: 45, borderRadius: 1.5 }}
                  onClick={() => {
                    Services.emailService.checkEmail(
                      Global.getJwtToken()!,
                      Global.getGlobal().CurrentMailbox,
                    );
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontSize: "16px !important",
                      fontWeight: 350,
                      fontFamily: "'Open Sans', sans-serif",
                      lineHeight: 1.5,
                      color: ALNColors.white,
                      letterSpacing: "2%",
                      textTransform: "capitalize",
                      padding: "0px",
                      paddingRight: "12px",
                    }}
                  >
                    Check Now 
                  </ALNLabel>
                  <AddPlus />
                </ALNBtn>
              </Box>
            </Box>
            {/* mails table */}
            <Box
              sx={{
                background: ALNColors.white,
                borderRadius: "16px",
                padding: "24px",
                border: "1px solid " + ALNColors.background_gray,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "16px",
                  paddingBottom: "12px",
                  boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.14)",
                }}
              >
                {/* Customer Name */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 0px",
                    cursor: "pointer",
                    paddingRight: "12px",
                    width: "20%",
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "21px",
                      padding: "0 0px",
                      letterSpacing: "0.02em",
                      textAlign: "left",
                      color: ALNColors.ligthgray_dark,
                      textTransform: "uppercase",
                      width: "fit-content",
                    }}
                  >
                    Customer Name
                  </ALNLabel>
                  <SortArrow />
                </Box>
                {/* Sales person */}
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 0px",
                    cursor: "pointer",
                    paddingRight: "12px",
                    width: "15%",
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "21px",
                      padding: "0 0px",
                      letterSpacing: "0.02em",
                      textAlign: "left",
                      color: ALNColors.ligthgray_dark,
                      textTransform: "uppercase",
                      width: "fit-content",
                    }}
                  >
                    Sales Person
                  </ALNLabel>
                  <SortArrow />
                </Box> */}
                {/* Status */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 0px",
                    cursor: "pointer",
                    paddingRight: "12px",
                    minWidth: "200px",
                    maxWidth: "300px"
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "21px",
                      letterSpacing: "0.02em",
                      padding: "0 0px",
                      textAlign: "left",
                      color: ALNColors.ligthgray_dark,
                      textTransform: "uppercase",
                      width: "fit-content",
                    }}
                  >
                    Status
                  </ALNLabel>
                  <SortArrow />
                </Box>
                {/* Email */}
                <Box sx={{ width: "30%", marginRight: "55px" }}>
                  <ALNLabel
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "21px",
                      letterSpacing: "0.02em",
                      textAlign: "left",
                      color: ALNColors.ligthgray_dark,
                      textTransform: "uppercase",
                      width: "fit-content",
                    }}
                  >
                    Email
                  </ALNLabel>
                </Box>
                {/* Received */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    paddingRight: "12px",
                    width: "13%",
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "21px",
                      letterSpacing: "0.02em",
                      textAlign: "left",
                      color: ALNColors.ligthgray_dark,
                      textTransform: "uppercase",
                      width: "fit-content",
                      paddingLeft: "10px",
                    }}
                  >
                    Received
                  </ALNLabel>
                  <SortArrow />
                </Box>
                {/* Time In Queue */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    paddingRight: "12px",
                    width: "20%",
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "21px",
                      padding: "0 0px",
                      letterSpacing: "0.02em",
                      textAlign: "left",
                      color: ALNColors.ligthgray_dark,
                      textTransform: "uppercase",
                      width: "fit-content",
                      paddingLeft: "8px",
                    }}
                  >
                    Time In Queue
                  </ALNLabel>
                  <SortArrow />
                </Box>
              </Box>
              <Box>
                {filteredEmailList.map((item) => (
                  <Box key={item.id} onClick={() => handleClickItem(item)}>
                    <EmailItem
                      data={item}
                      isSelected={item.id === lastEmailToEdit?.id}
                    />
                  </Box>
                ))}
                {filteredEmailList.length === 0 && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignContent={"center"}
                    alignItems={"center"}
                    width={"100%"}
                    height={"300px"}
                  >
                    No Emails
                  </Box>
                )}
              </Box>
            </Box>
            {/* pagination part */}
            <Box
              sx={{
                height: "37px",
                borderRadius: "16px",
                background: ALNColors.white,
                padding: "10px 50px",
                alignItems: "center",
                border: "1px solid " + ALNColors.background_gray,
              }}
            >
              <ALNLabel
                sx={{ color: ALNColors.gray, fontSize: "15px", marginRight: 1, display: 'inline' }}
              >
                Showing 1-10 of {page} Results 
              </ALNLabel>

              {page > 1 && (
                <ALNBtn
                  variant="text"
                  onClick={() => {
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  <LeftPaginate />
                </ALNBtn>
              )}

              {page === 1 && (
                <ALNBtn
                  variant="text"
                  onClick={() => {}}
                  sx={{ color: ALNColors.lightgray }}
                >
                  <LeftPaginate />
                </ALNBtn>
              )}
                {/* {printFormattedList(page)} */}
              {filteredEmailList.length === pageSize && (
                <ALNBtn
                  variant="text"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  <RightPaginate />
                </ALNBtn>
              )}

              {filteredEmailList.length !== pageSize && (
                <ALNBtn
                  variant="text"
                  onClick={() => {}}
                  sx={{ color: ALNColors.lightgray }}
                >
                  <RightPaginate />
                </ALNBtn>
              )}
            </Box>
            <StyledMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {emailStates.map((item, idx) => (
                <MenuItem key={idx}
                >
                  <EmailState
                    manualFilter={handleManualFilter}
                    activeStatus={filter}
                    key={item.id}
                    data={item}
                    // selectedStatuses={selectedStatuses}
                  />
                </MenuItem>
              ))}
            </StyledMenu>
          </Box>
          {/* Profile Menu */}
          <Menu
            anchorEl={anchorElModal}
            open={Boolean(anchorElModal)}
            onClose={handleProfileMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Divider />
            <MenuItem
                onClick={() => {
                    handleUpdateUserModal();
                    setModalTitle("Change Password");
                    setCurrentlyChanged("password");
                }}
            >
                <ALNLabel variant="body1">Change Password</ALNLabel>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleUpdateUserModal();
                    setModalTitle("Change Company");
                    setCurrentlyChanged("company");
                }}
            >
                <ALNLabel variant="body1">Change Company</ALNLabel>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <Logout />
                <ALNLabel variant="body1">Log out</ALNLabel>
            </MenuItem>
          </Menu>
          {user && (
            <UpdateUserModal
              currentlyChanged={currentlyChanged}
              open={updateUserModal}
              onClose={handleCloseUpdateUserModal}
              user={user!}
              title={modalTitle}
            />
          )}
        </Box>
      )}
      {emailToEdit && (
        <EmailEditLayer
          email={emailToEdit}
          onClose={() => {
            setEmailToEdit(null);
            fetchEmails(filter, searchValue, page);
          }}
        />
      )}
    </Box>
  );
}

export default EmailList;
