import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { EmailStatus } from "../services/models/EmailStatus";
import { ALNColors } from "../utils/color";
import ALNLabel from "./label/ALNLabel";

interface EmailStateProps {
  data: {
    id: number;
    title: string;
    displaytitle: string;
    icon: string;
    color: string;
    status: EmailStatus;
  };
  manualFilter: (status: EmailStatus) => void;
  activeStatus: string;
}

function EmailState({ data, manualFilter, activeStatus }: EmailStateProps) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);

  useEffect(() => {
    if (data.icon === "") return;
    import(`../assets/images/${data.icon}`)
      .then((module) => setIconSrc(module.default))
      .catch((err) => console.error("Error loading icon:", err));
  }, [data.icon]);

  return (
    <Box
      sx={{
        paddingTop: '6px',
        paddingBottom: '6px'
      }}
    >
      {data.title === "All" ? (
        <Box
          style={{
            cursor: "pointer",
          }}
          sx={{
            display: 'flex',
            alignItems: 'start',
            padding: '8px',
            borderRadius: 0,
            width: 'fit-content',
          }}
          onClick={() => manualFilter("All")}
        >
          <ALNLabel
            sx={{
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: 1.5,
              letterSpacing: 0.02,
              textAlign: 'left',
              color: activeStatus === "All" ? data.color : ALNColors.black
            }}
          >
            All emails
          </ALNLabel>
        </Box>
      ) : (      
        <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "8px",
              width: "fit-content",
              border: "1px solid "+data.color,
              borderWidth: "1px",
              borderRadius: "100px",
              padding:"5px 12px",
            }}
            style={{
              cursor: "pointer",
              backgroundColor:
                activeStatus === data.status ? data.color : "transparent",
            }}
          onClick={() => manualFilter(data.status)}
        >
          {iconSrc && (
            <Box
              component="img"
              src={iconSrc}
              alt={data.icon}
              sx={{
                marginTop: '5px',
                filter: activeStatus === data.status
                  ? "brightness(0) invert(1)"
                  : "brightness(100%) saturate(100%) invert(0)",
              }}
            />
          )}
          <ALNLabel
            sx={{
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: 1.5,
              letterSpacing: 0.02,
              textAlign: 'left',
              color: activeStatus === data.status ? ALNColors.white : data.color,
            }}
          >
            {data.displaytitle}
          </ALNLabel>
        </Box>
      )}
    </Box>
  );
}

export default EmailState;
