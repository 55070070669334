import { useEffect, useState } from "react";
import { Box, Checkbox, Divider, Menu, MenuItem } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ALNBtn from "../components/form/ALNBtn/ALNBtn";

import { ALNColors } from "../utils/color";
import { User } from "../services/models";
import { Services } from "../Services";
import { UserListItem } from "./user/UserListItem";
import { NewUserModal } from "./user/NewUserModal";
import { ALNConfirmModal } from "../components/modal/ALNConfirmModal";
import { Global } from "../Global";
import ALNLabel from "../components/label/ALNLabel";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";

export function UsersList(): JSX.Element {
  const [user, setUser] = useState<User | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
      "password" | "company"
  >("password");
  useEffect(() => {
      Global.getUser().then((user) => {
          setUser(user);
      });
  }, []);

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
      setAnchorEl(null);
  };
  const handleUpdateUserModal = () => {
      setUpdateUserModal(true);
  };
  const handleCloseUpdateUserModal = () => {
      setUpdateUserModal(false);
  };
  const handleLogout = () => {
      Global.setJwtToken(undefined);
  };
  const [userList, setUserList] = useState<User[]>([]);
  const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<User>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [deleteUser, setDeleteUser] = useState<User>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const fetchUsers = () => {
    Services.userService
      .getUsers(Global.getJwtToken()!, false)
      .then((users: User[]) => {
        setUserList(users);
      })
      .catch((err) => {
        console.log(err)
      })
  };

  const token = Global.getJwtToken();

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const handleSelectUser = (user: User) => {
    setSelectedUsers(prev => 
      prev.includes(user) ? prev.filter(u => u.id !== user.id) : [...prev, user]
    );
  };

  const handleDeleteSelectedUsers = () => {
    if (selectedUsers.length > 0) {
      setConfirmModalOpen(true);
    }
  }

  const selectAll = () => {
    if (!checkedAll) {
      setSelectedUsers([...userList]);
    } else {
      setSelectedUsers([]);
    }
    setCheckedAll(!checkedAll);
  }

  const handleUserClick = () => {
    setIsEdit(false);
    setUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setUserModalOpen(false);
    setEditUser({});
    setTimeout(() => {
      fetchUsers();
    }, 200);
    Global.resetUser();
  };

  const handleEditUser = (user: User) => {
    setEditUser((prevUser) => ({ ...prevUser, ...user }));
    setIsEdit(true);
    setUserModalOpen(true);
  };

  const handleConfirmodalClose = () => {
    setConfirmModalOpen(false);
  };

  const confirmDeleteUser = (user: User) => {
    setConfirmModalOpen(true);
    setDeleteUser(user);
  };

  const onDeleteUser = () => {
    if(!checkedAll && deleteUser) {
      Services.userService
        .deleteUser(Global.getJwtToken()!, deleteUser?.emailAddress!)
        .then(() => {
          fetchUsers();
        });
    } else {
      if (selectedUsers.length > 0) {
        for (let i = 0; i < selectedUsers.length; i ++) {
          Services.userService
          .deleteUser(Global.getJwtToken()!, selectedUsers[i].emailAddress!)
          .then(() => {
              if (i === selectedUsers.length-1) {
                fetchUsers();
              }
          });
        }
        setSelectedUsers([]);
        setCheckedAll(false);
      }
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
          User List
        </ALNLabel>
        <Box
          sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: "10px",
              padding: "8px 13px",
          }}
          onClick={handleUserInfo}
        >
          <Box
              component="img"
              src={avatarImg}
              alt="avatar"
              sx={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
              }}
          />
          <Box sx={{ margin: "0 0px" }}>
              <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
              <ALNLabel sx={{ display: "none" }}>
              {"["}
              {Global.getGlobal().company}
              {"]"}
              </ALNLabel>
          </Box>
          <DownIcon />
        </Box>
      </Box>
      <Box
        sx={{ 
          background: ALNColors.white,
          padding: "25px 25px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          height: 'calc(100vh - 150px)',
          overflowX: 'hidden',
        }}
      >
        {/* Header Part */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddtingTop: "0px",
            marginBottom: "24px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <ArrowBackIcon
              style={{
                marginRight: "14px",
                cursor: "pointer",
                }}
                onClick={() => {
              }}
            />
            <ALNLabel sx={{ fontSize: "24px", lineHeight: "18px", fontWeight: 700 }}>
              User List
            </ALNLabel>
          </Box>
          <ALNBtn
              sx={{
                  borderRadius: '8px',
                  padding: '8px 16px',
                  marginRight: "28px",
                  bgcolor: ALNColors.primary_btn,
                  fontFamily: "OpenSans",
                  fontSize: '15px',
                  fontWeight: 590,
                  lineHeight: '21px',
                  letterSpacing: 0.02,
                  textAlign: "left",
                  color: ALNColors.white,
                  cursor: "pointer",
                  textTransform: 'capitalize',
              }}
              onClick={ handleUserClick }
          >
              New User
          </ALNBtn>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "15px",
            }}
          >
            <Checkbox 
              checked={checkedAll} 
              onChange={selectAll}
            />
            <DeleteForeverIcon
              sx={{
                cursor: "pointer"
              }}
              onClick={() => {
                handleDeleteSelectedUsers();
              }}
            />
          </Box>
          <Divider></Divider>
          <Box 
            sx={{ overflowY: 'auto', maxHeight: '100%' }}>
            {userList.map((user: User) => (
              <UserListItem
                user={user}
                key={user.id}
                onDelete={confirmDeleteUser}
                openEditModal={handleEditUser}
                onSelect={() => handleSelectUser(user)}
                selectedUsers={selectedUsers}
              />
            ))}
          </Box>

          {userList.length === 0 && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              width={"100%"}
              height={"300px"}
            >
              <ALNLabel variant="body1" sx={{ color: ALNColors.secondary}}>
                No Users
              </ALNLabel>
            </Box>
          )}
        </Box>
      </Box>
      <ALNConfirmModal
        modalTitle="Delete User"
        modalDescription="Are you sure you want to delete this user?"
        open={confirmModalOpen}
        onClose={handleConfirmodalClose}
        onDeleteItem={onDeleteUser}
      />
      <NewUserModal
        isEdit={isEdit}
        open={userModalOpen}
        onClose={handleUserModalClose}
        user={editUser}
      />
      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Divider />
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Password");
                setCurrentlyChanged("password");
            }}
        >
            <ALNLabel variant="body1">Change Password</ALNLabel>
        </MenuItem>
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Company");
                setCurrentlyChanged("company");
            }}
        >
            <ALNLabel variant="body1">Change Company</ALNLabel>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
            <Logout />
            <ALNLabel variant="body1">Log out</ALNLabel>
        </MenuItem>
      </Menu>
      {user && (
        <UpdateUserModal
          currentlyChanged={currentlyChanged}
          open={updateUserModal}
          onClose={handleCloseUpdateUserModal}
          user={user!}
          title={modalTitle}
        />
      )}
    </Box>
  );
}
