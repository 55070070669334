import React, { useEffect, useRef } from 'react';
import {
    CardContent,
    Grid2 as Grid,
} from "@mui/material";
import * as d3 from 'd3';
import { EmailVolume } from '../../services/models/EmailVolume';
import { TimePeriod } from '../../services/models/TimePeriod';
import { ALNColors } from '../../utils/color';
import ALNLabel from '../label/ALNLabel';

interface BarChartProps {
  data: EmailVolume[] | undefined;
  width: number;
  height: number;
  numberOfEmails: number;
  timePeriod: TimePeriod;
}

function BarChart({ data, width, height, numberOfEmails, timePeriod }: BarChartProps) {
  const ref = useRef<SVGSVGElement | null>(null);
  const margin = 20;
  const chageTimeLabel = (label: string) => {
    return label;
  };

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height);

    svg.selectAll("*").remove(); // Clear previous renders

    const filteredData = (data || []).map((d) => ({
      count: d.count ?? 0,
      label: d.label,
    })) as { count: number; label: string }[];

    const tickValues = (data || [])
      .filter((d) => d.showLabel)
      .map((d) => d.label) as string[];

    // Set up scales
    const xScale = d3
      .scaleBand()
      .domain(filteredData.map((d) => d.label))
      .range([margin, width - margin])
      .padding(0.5);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(filteredData, (d) => d.count) || 0])
      .nice()
      .range([height - margin, margin]);

        // Create a tooltip
        const tooltip = d3
            .select('body')
            .append('div')
            // .select('.tooltip')
            .attr('class', 'tooltip-bar')
            .style('opacity', 0)
            .style('position', 'absolute')
            .style('background-color', ALNColors.white)
            .style('width', '64px')
            .style('margin', '5px')
            .style('pointer-events', 'none');

    // Render bars with animation
    const bars = svg
      .selectAll(".bar")
      .data(filteredData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.label)!)
      .attr("y", height - margin) // Start bars at the bottom (for animation)
      .attr("width", xScale.bandwidth())
      .attr("height", 0) // Initial height 0 for animation
      .attr("fill", ALNColors.blue_light)
      .style("cursor", "pointer")
      .style("border-radius", "2px")
      .on("mouseover", (event, d) => {
        tooltip
          .style("opacity", 1)
          .html(
            `<div class="time">${chageTimeLabel(d.label)}</div><div class="value">${d.count.toLocaleString()}</div>`,
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mouseout", () => {
        tooltip.style("opacity", 0);
      });

    // Animate the bars growing to their final height
    bars
      .transition()
      .duration(800)
      .attr("y", (d) => yScale(d.count))
      .attr("height", (d) => height - margin - yScale(d.count)) // Adjusted height
      .delay((d, i) => i * 100);

    // Create the X-axis labels
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickValues(tickValues)
          .tickFormat((d) => chageTimeLabel(d) as string)
          .tickSize(0),
      )
      .selectAll("text")
      .style("font-size", "10px")
      .style("text-anchor", "middle");

    svg.selectAll("path").style("display", "none");
    svg.append("style").text(`
      .axis text {
          font-size: 10px;
          fill: #616161;
      }
      
      .axis path,
      .axis line {
          display: none;
      }
      
      .bar {
          transition: fill 0.2s ease;
          fill: #D8D9E7;
      }
      
      .bar:hover {
          fill: #3A4188;
      }
      
      .tooltip-bar {
          font-family: Arial, sans-serif;
          pointer-events: none;
          display: flex;
          flex-direction: column;
          border: 1px solid #C1C4C9;
          border-radius: 2px;
      }
      
      .tooltip-bar .time {
          font-size: 10px;
          text-align: left;
          color: black;
          background-color: white;
          padding: 5px;
      }
      
      .tooltip-bar .value {
          font-size: 12px;
          text-align: right;
          color: #3A4188;
          background-color: #C1C4C9;
          padding: 5px;
      }
    `);
    // Cleanup tooltip on component unmount
    return () => {
      tooltip.remove();
    };
  }, [data, width, height, margin]);

    return (
      <CardContent>
          <Grid container alignItems="center" justifyContent="space-between">
              <Grid sx={{width: "50%"}}>
                  <ALNLabel variant="h6" sx={{ mb: '0.35em' }}>
                      Total Email Volumes
                  </ALNLabel>
                  <svg ref={ref} />
              </Grid>
              <Grid padding={2} alignSelf={'end'} paddingBottom={4}>
                  <ALNLabel variant="h2" sx={{ color: ALNColors.primary, margin: "0px", align: "right" }}>
                      {numberOfEmails?.toLocaleString()}
                  </ALNLabel>
                  <ALNLabel variant="body1" sx={{ color: ALNColors.email_ignored, fontSize: '14px', align: "right" }}>
                      {timePeriod === "Day" ? "today   " : `this ${timePeriod.toLowerCase()}`}
                  </ALNLabel>
              </Grid>
          </Grid>
      </CardContent>
    );
};

export default BarChart;
