import { Box, Modal } from "@mui/material";
import { useEffect } from "react";

import ALNBtn from "../form/ALNBtn/ALNBtn";
import { ALNColors } from "../../utils/color";
import ALNLabel from "../label/ALNLabel";
interface ALNConfirmModalProps {
  open: boolean;
  modalTitle: string;
  modalDescription: string;
  onClose: () => void;
  onDeleteItem: () => void;
}

export function ALNConfirmModal(props: ALNConfirmModalProps): JSX.Element {
  useEffect(() => {
  }, []);

  const onAgree = () => {
    props.onDeleteItem();
    handleClose();
  };

  const onDisAgree = () => {
    handleClose();
  };
  
  const handleClose = () => {
    props.onClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '245px',
          height: '148px',
          bgcolor: ALNColors.white,
          borderRadius: '12px',
          border: '1px solid '+ALNColors.email_manual,
          paddingTop: "15px",
          paddingBottom: "10px",
          paddingLeft: "25px",
          paddingRight: "25px"
        }}
      >
          <ALNLabel
            sx={{
              fontFamily: "OpenSans",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "20px",
              letterSpacing: 0.02,
              textAlign: "left",
              textTransform: 'capitalize',
              color: ALNColors.email_manual,
            }}
          >
              {props.modalTitle}
          </ALNLabel>
          <ALNLabel
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: 0.03,
              textAlign: "left",
              color: ALNColors.black,
              paddingTop: "25px",
              paddingRight: "30px"
            }}
          >
              {props.modalDescription}
          </ALNLabel>
          <Box 
            sx={{
              position: "absolute",
              bottom: "20px",
              left: "28px",
              display: "flex",
              gap: "10px",
            }}
          >
              <ALNBtn variant="contained" onClick = { () => {onDisAgree()} } 
                sx={{ 
                  background: ALNColors.white,
                  width: "113px"
                }}
              >
                <ALNLabel
                  sx={{
                      fontFamily: "OpenSans",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: 0.02,
                      textAlign: "left",
                      textTransform: 'capitalize',
                      color: ALNColors.black,
                  }}
                >
                  Cancel
                </ALNLabel>
              </ALNBtn>
              <ALNBtn variant="contained" onClick = { () => {onAgree()} } 
                sx={{ 
                    background: ALNColors.primary_btn,
                    width: "113px"
                }}
              >
                <ALNLabel
                  sx={{
                    fontFamily: "OpenSans",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: 0.02,
                    textAlign: "left",
                    textTransform: 'capitalize',
                    color: ALNColors.white,
                  }}
                >
                  Delete
                </ALNLabel>
              </ALNBtn>
          </Box>
      </Box>
    </Modal>
  );
}
