import { Company } from "../../services/models";
import { Box, Checkbox } from "@mui/material";
import ALNItem from "../../components/form/ALNItem/ALNItem";
import ALNIconBtn from "../../components/form/ALNBtn/ALNIconBtn";
import deleteIcon from "../../assets/images/material-symbols_delete.svg"
import editIcon from "../../assets/images/tabler_edit.svg"
import { ALNColors } from "../../utils/color";

type CompanyListItemProps = {   
  company: Company;
  onDelete: (company: Company) => void;
  openEditModal: (company: Company) => void;
  onSelect: (company: Company) => void;
  selectedCompanies: Company[];
}

export function CompanyListItem({ company, onDelete, openEditModal, onSelect, selectedCompanies }: CompanyListItemProps) {
  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        borderRadius: 1,
        bgcolor: ALNColors.white,
        boxShadow: 1,
        marginTop: "0px",
        cursor: 'pointer',
        '&': { mt: 2 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center"
        }}
      >
        <Checkbox 
          checked={selectedCompanies.some(c => c.id === company.id)} 
          onChange={() => onSelect(company)}
        />
        <Box>
          <ALNItem firstName = { company.companyName } lastName = "" />
        </Box>
      </Box>
      <Box>
        <ALNIconBtn obj = { company } onClick = {() => openEditModal(company)} src = { editIcon } alt = "Edit" />
        <ALNIconBtn obj = { company } onClick = {() => onDelete(company)} src = { deleteIcon } alt = "Delete" />
      </Box>
    </Box>
  );
};