import React, { useEffect, useState } from "react";
import { Box, Menu, MenuItem, Divider } from "@mui/material";
import { Global } from "../Global";
import { EmailParseRequest } from "../services/models/EmailParseRequest";
import { Services } from "../Services";
import { Email } from "../services/models/Email";
import { DisplayEmail } from "./DisplayEmail";
import { EmailParseResult } from "../services/models/EmailParseResult";
import { EmailData } from "../services/models/EmailData";
import ReactQuill from "react-quill";
import { ALNColors } from "../utils/color";
import ALNLabel from "../components/label/ALNLabel";
import ALNTextField from "../components/form/ALNInput/ALNTextField";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNToggleBtn from "../components/form/ALNBtn/ALNToggleBtn";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { User } from "../services/models/User";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";

export function ParseTool(props: { emailData?: EmailData }): JSX.Element {
  const [user, setUser] = useState<User | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
      "password" | "company"
  >("password");
  useEffect(() => {
      Global.getUser().then((user) => {
          setUser(user);
      });
  }, []);

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
      setAnchorEl(null);
  };
  const handleUpdateUserModal = () => {
      setUpdateUserModal(true);
  };
  const handleCloseUpdateUserModal = () => {
      setUpdateUserModal(false);
  };
  const handleLogout = () => {
      Global.setJwtToken(undefined);
  };
  const [active, setActive] = useState(false);
  const [from, setFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [htmlBody, setHtmlBody] = useState<string | undefined>(undefined);
  const [result, resultsToShow] = useState("");
  const [resultsPage, setResultsPage] = useState<string>("email");
  const [resultData, setResultData] = useState<Email | undefined>();
  const [emailParseResults, setEmailParseResult] = useState<
    EmailParseResult | undefined
  >();
  const [productName, setProductName] = useState<string[] | undefined>();

  useEffect(() => {
    setFrom(props.emailData?.from?.address || "");
    setSubject(props.emailData?.subject || "");
    setBody(props.emailData?.body || "");
    setHtmlBody(props.emailData?.htmlBody);
  }, [props.emailData]);

  const handleTest = () => {
    let parseRequest: EmailParseRequest = {
      mailboxName: Global.getGlobal().CurrentMailbox!,
      email: {
        subject: subject,
        body: body,
        htmlBody: htmlBody,
        from: { address: from },
      },
    };
    Services.testService
      .parseEmail(Global.getJwtToken()!, parseRequest)
      .then((res) => {
        resultsToShow(JSON.stringify(res, null, 2));
        setResultData(res.email);
        setEmailParseResult(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  function handleProductNameFetch(): void {
    Services.testService
      .getRandomProductNames(
        Global.getJwtToken()!,
        Global.getGlobal().CurrentMailbox!,
      )
      .then((res) => {
        setProductName(res);
      })
      .catch(err => {
        console.log(err);
      });
  }

  let replyEmail = resultData?.reply;

  return (
    <Box
      sx={{ 
        display: "flex", 
        flexDirection: "column",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
          Email Parse Test Tool
        </ALNLabel>
        <Box
          sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: "10px",
              padding: "8px 13px",
          }}
          onClick={handleUserInfo}
        >
          <Box
              component="img"
              src={avatarImg}
              alt="avatar"
              sx={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
              }}
          />
          <Box sx={{ margin: "0 0px" }}>
              <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
              <ALNLabel sx={{ display: "none" }}>
              {"["}
              {Global.getGlobal().company}
              {"]"}
              </ALNLabel>
          </Box>
          <DownIcon />
        </Box>
      </Box>
      <Box
        sx={{
          background: ALNColors.white,
          padding: "25px 25px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          height: 'calc(100vh - 150px)',
        }}
      >
        <Box
            sx={{
                flex: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                paddtingTop: "0px",
            }}
        >
          <ArrowBackIcon
              style={{
                  marginRight: "14px",
                  cursor: "pointer",
                  paddingTop: "11px"
          }}
              onClick={() => {
                  // Global.getGlobal().CurrentPage = Pages.Dashboard;
          }}
          />
          <ALNLabel sx={{ fontSize: "24px", paddingTop: "10px", lineHeight: "18px", fontWeight: 700 }}>
            Email Parse Test Tool
          </ALNLabel>
        </Box>
        <Box
          sx={{
            display: "flex",
            paddingTop: "25px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              paddingRight: "10px",
              borderRight: "1px solid "+ALNColors.black,
            }}
          >
            <Box sx={{ mb: 2 }}>
              <ALNTextField
                label="From"
                variant="filled"
                sx={{ width: "100%" }}
                value={from}
                onChange={setFrom}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <ALNTextField
                label="Subject"
                variant="filled"
                sx={{ width: "100%" }}
                value={subject}
                onChange={setSubject}
              />
            </Box>
            {!htmlBody && (
              <Box sx={{ mb: 2 }}>
                <ALNTextField
                  label="Body"
                  variant="filled"
                  sx={{ width: "100%", border: "none" }}
                  value={body}
                  rows={10}
                  onChange={setBody}
                />
              </Box>
            )}

            {htmlBody && (
              <Box sx={{ mb: 6 }}>
                <ReactQuill
                  theme="snow"
                  value={htmlBody}
                  onChange={setHtmlBody}
                  style={{ height: "300px" }}
                />
              </Box>
            )}
            <ALNBtn variant="contained" onClick = { handleTest } >Test</ALNBtn>
            <ALNBtn onClick = { handleProductNameFetch } >Fetch Random Product Names</ALNBtn>
            {productName && (
              <Box>
                <ALNLabel variant="h5" sx={{ mb: '0.35em' }}>
                  Random Product Names:
                </ALNLabel>
                <Box
                  component="pre"
                  sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                >
                  {productName.join(", ")}
                </Box>
              </Box>
            )}
          </Box>
          <Box 
            sx={{ 
              width: "50%", 
              paddingLeft: "10px",
              height: 'calc(100vh - 230px)',
            }}>
            <ALNLabel variant="h5" sx={{ mb: '0.35em' }}>
              Results:
            </ALNLabel>

            <ALNToggleBtn
              active={active}
              firstToggle="email"
              secondToggle="data"
              aria_label="result type"
              aria_label_first="email"
              aria_label_second="data"
              sx={{ marginBottom: 2 }}
              onChange={ (value) => {
                setResultsPage(value); 
                setActive(!active);
              }}
            />
            {resultsPage === "email" &&
              (replyEmail?.body || replyEmail?.htmlBody) && (
                <Box 
                  sx={{ 
                    maxHeight: "85%",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {emailParseResults && (
                    <Box>
                      <ALNLabel>
                        Time Taken:{" "}
                        {emailParseResults.email?.timeTakenInSeconds?.toFixed(3)}{" "}
                        seconds
                      </ALNLabel>
                      <ALNLabel>
                        Order Total: ${" "}
                        {emailParseResults.email?.totalValue?.toFixed(2)}
                      </ALNLabel>
                      <ALNLabel>
                        Action Proposed: {emailParseResults.email?.emailStatus}
                      </ALNLabel>
                      {emailParseResults.email?.cannotReplyReason &&
                        emailParseResults.email?.cannotReplyReason?.length > 0 && (
                          <Box>
                            <ALNLabel>Issues:</ALNLabel>

                            <ALNLabel
                              sx={{
                                color: ALNColors.red,
                                border: "1px solid "+ALNColors.red,
                                backgroundColor: ALNColors.pink_light,
                                textAlign: "left",
                              }}
                            >
                              {emailParseResults.email?.cannotReplyReason?.map(
                                (reason) => (
                                  <Box key={reason} component="span" display="block">
                                    <ALNLabel variant="body2">- {reason}</ALNLabel>
                                  </Box>
                                ),
                              )}
                            </ALNLabel>
                          </Box>
                        )}
                    </Box>
                  )}

                  <Box sx={{ mb: 2 }}></Box>
                  <DisplayEmail email={replyEmail!} isReply={true} />
                </Box>
              )}

            {resultsPage === "data" && (
              <Box
                component="pre"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  maxHeight: "81%",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {result}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Divider />
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Password");
                setCurrentlyChanged("password");
            }}
        >
            <ALNLabel variant="body1">Change Password</ALNLabel>
        </MenuItem>
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Company");
                setCurrentlyChanged("company");
            }}
        >
            <ALNLabel variant="body1">Change Company</ALNLabel>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
            <Logout />
            <ALNLabel variant="body1">Log out</ALNLabel>
        </MenuItem>
      </Menu>
      {user && (
        <UpdateUserModal
            currentlyChanged={currentlyChanged}
            open={updateUserModal}
            onClose={handleCloseUpdateUserModal}
            user={user!}
            title={modalTitle}
        />
      )}
    </Box>
  );
}

export default ParseTool;
