import { Box } from "@mui/material";
import { useState, ChangeEvent, useEffect } from "react";
import { Company } from "../../services/models";
import { Services } from "../../Services";
import { Global } from "../../Global";
import ALNModal from "../../components/modal/ALNModal";
import ALNModalHeader from "../../components/modal/ALNModalHeader";
import ALNModalTitle from "../../components/modal/ALNModalTitle";
import ALNINput from "../../components/form/ALNInput/ALNInput";
import ALNBtn from "../../components/form/ALNBtn/ALNBtn";
import ALNCancelBtn from "../../components/form/ALNBtn/ALNCancelBtn";
import ALNLabel from "../../components/label/ALNLabel";
import { ALNColors } from "../../utils/color";

interface CompanyModalProps {
  open: boolean;
  onClose: () => void;
  company?: Company;
}

export function CompanyModal(props: CompanyModalProps): JSX.Element {
  const [company, setCompany] = useState<Company>({});

  useEffect(() => {
    if (props.company) {
      setCompany(props.company);
    } else {
      Services.companyService
        .createCompanyData(Global.getJwtToken()!)
        .then((data) => {
          setCompany(data);
        });
    }
  }, [props.company]);

  const handleClose = () => {
    setCompany({
      companyName: "",
      companyLogoURL: "",
      maxEmailsPerUserPerDay: 0,
    });
    props.onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompany((prevCompany) => ({
      ...prevCompany,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    Services.companyService
      .saveCompany(Global.getJwtToken()!, company)
      .then(() => {})
      .catch(err => {
        console.log(err);
      });
      
    handleClose();
  };

  return (
    <ALNModal
      open={props.open}
      onClose={handleClose}
    >
      <ALNModalHeader>
        <ALNModalTitle title = {company.companyName !== "" ? "Edit Company" : "New Company"} />
        <ALNCancelBtn onClick = { handleClose } />
      </ALNModalHeader>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        sx= {{
          paddingBottom: '20px',
          paddingLeft: "32px",
          paddingRight: "32px",
          paddingTop: '0px',
          display: 'flex',
          flexDirection: 'column',
          gap: '0px',
        }}
      >
        <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>Edit Company Name</ALNLabel>
        <ALNINput
          id="companyName"
          name="companyName"
          label="Company Name"
          placeholder="-"
          value={company.companyName}
          onChange={handleChange}
        />
        <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>Edit Company Logo URL</ALNLabel>
        <ALNINput
          id="companyLogoURL"
          name="companyLogoURL"
          label="Company Logo URL"
          placeholder="-"
          value={company.companyLogoURL}
          onChange={handleChange}
        />
        <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>Edit Max Emails Per User Per Day</ALNLabel>
        <ALNINput
          id="maxEmailsPerUserPerDay"
          name="maxEmailsPerUserPerDay"
          label="Max Emails Per User Per Day"
          placeholder="-"
          onChange={handleChange}
          type="number"
          value={company.maxEmailsPerUserPerDay}
        />
        <ALNBtn 
          variant="contained"
          type="submit"
          sx={{ bgcolor: ALNColors.email_manual, mt: 3, width: "120px", ml: "auto", float: "inline-end"}}
          onClick = { () => {} }>
            Submit
        </ALNBtn>
      </Box>
    </ALNModal>
  );
}
