import React from "react";
import { Box, ToggleButton, ToggleButtonGroup, List, ListItem, Menu, MenuItem, Divider } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Global } from "../Global";
import { Services } from "../Services";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { User } from "../services/models/User";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";
import { ALNColors } from "../utils/color";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../components/label/ALNLabel";

type LogFileNames = {
  [key: string]: string[];
};

export function Support() {
  const [user, setUser] = useState<User | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
      "password" | "company"
  >("password");

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
      setAnchorEl(null);
  };
  const handleUpdateUserModal = () => {
      setUpdateUserModal(true);
  };
  const handleCloseUpdateUserModal = () => {
      setUpdateUserModal(false);
  };
  const handleLogout = () => {
      Global.setJwtToken(undefined);
  };
  
  const [view, setView] = useState("logs");

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string,
  ) => {
    setView(newView);
  };

  let supportService = Services.supportService;

  const [productCounts, setProductCounts] = useState<
    { mailbox: string; count: number }[]
  >([]);
  const [logFileNames, setLogFileNames] = useState<LogFileNames>({});
  const [logFile, setLogFile] = useState<string>("");
  const [isOpen, setIsOpen] = useState<string>("");
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [currentLogFile, setCurrentLogFile] = useState<string>("");

  let token = Global.getJwtToken();

  const toggleCollapse = (index: any) => {
    if(isOpen === index)
        setIsOpen("")
    else setIsOpen(index);
  };

  const fetchData = async () => {
    try {
      const productCountsData = await supportService.getProductCount(
        Global.getJwtToken()!,
      );
      setProductCounts(productCountsData);

      const logFileNamesData = await supportService.getLogFileNames(
        Global.getJwtToken()!,
      );
      
      type LogGroups = { [date: string]: string[] };
      const logsByDate: LogGroups = logFileNamesData.reduce((acc: LogGroups, log: string) => {
        const match = log.match(/2024-\d{2}-\d{2}/);
        if (match) {
          const date = match[0]; // Match result is guaranteed to exist here
          if (!acc[date]) acc[date] = [];
          acc[date].push(log);
        }
        return acc;
      }, {});
      
      // Sort logs within each date
      for (const date in logsByDate) {
        logsByDate[date].sort((a, b) => {
          const numA = parseInt(a.match(/-(\d+)\.log$/)?.[1] || "0", 10);
          const numB = parseInt(b.match(/-(\d+)\.log$/)?.[1] || "0", 10);
          return numA - numB;
        });
      }
      setLogFileNames(logsByDate);

      // const errorMessagesData = await supportService.getErrorMessages(
      //   Global.getJwtToken()!,
      // );

      await supportService.getErrorMessages(
        Global.getJwtToken()!,
      );
      
      setErrorMessages(errorMessages);

      if (currentLogFile) {
        const logFileData = await supportService.getLogFile(
          Global.getJwtToken()!,
          currentLogFile,
        );
        setLogFile(logFileData);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    Global.getUser().then((user) => {
      setUser(user);
    });
  }, [token]);

  useEffect(() => {
    const fetchLogFile = async () => {
      try {
        const logFileData = await supportService.getLogFile(
          Global.getJwtToken()!,
          currentLogFile,
        );
        setLogFile(logFileData);
      } catch (error) {
        console.error("Error fetching log file", error);
      }
    };

    if (currentLogFile) {
      fetchLogFile();
    }
  }, [currentLogFile]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
          Support
        </ALNLabel>
        <Box
          sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: "10px",
              padding: "8px 13px",
          }}
          onClick={handleUserInfo}
        >
          <Box
              component="img"
              src={avatarImg}
              alt="avatar"
              sx={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
              }}
          />
          <Box sx={{ margin: "0 0px" }}>
              <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
              <ALNLabel sx={{ display: "none" }}>
              {"["}
              {Global.getGlobal().company}
              {"]"}
              </ALNLabel>
          </Box>
          <DownIcon />
        </Box>
      </Box>
      <Box 
        sx={{ 
            background: ALNColors.white,
            padding: "25px 25px", 
            borderRadius : "16px",
            border: "1px solid "+ALNColors.background_gray,
            height: 'calc(100vh - 150px)',
            overflowX: 'hidden',
        }} 
      >
        {/* Header Part */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddtingTop: "0px",
            marginBottom: "24px"
          }}
      >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <ArrowBackIcon
              style={{
                marginRight: "14px",
                cursor: "pointer",
                }}
                onClick={() => {
              }}
            />
            <ALNLabel sx={{ fontSize: "24px", lineHeight: "18px", fontWeight: 700 }}>
              Support
            </ALNLabel>
          </Box>
          <ALNBtn
              sx={{
                  borderRadius: '8px',
                  padding: '8px 16px',
                  marginRight: "28px",
                  bgcolor: ALNColors.primary_btn,
                  fontFamily: "OpenSans",
                  fontSize: '15px',
                  fontWeight: 590,
                  lineHeight: '21px',
                  letterSpacing: 0.02,
                  textAlign: "left",
                  color: ALNColors.white,
                  cursor: "pointer",
                  textTransform: 'capitalize',
              }}
              onClick={ fetchData }
          >
              Refresh
          </ALNBtn>
        </Box>
        <Box
          sx={{
            paddingLeft: "34px",
            paddingRight: "34px",
            gap: "12px",
          }}
        >
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view selection"
          >
            <ToggleButton value="logs" aria-label="logs">
              Logs
            </ToggleButton>
            <ToggleButton value="errors" aria-label="errors">
              Errors
            </ToggleButton>
            <ToggleButton value="productCounts" aria-label="product counts">
              Product Counts
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {view === "logs" && (
          <Box
            sx={{
              paddingLeft: "34px",
              paddingRight: "34px",
              maxHeight: "85%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <ALNLabel variant="h6" sx={{ paddingTop: "10px"}}>
              Log Files
            </ALNLabel>
            <Box display={"flex"} flexDirection={"row"} width={"100%"}>
              <ul>
                {Object.keys(logFileNames)
                  .sort()
                  .map((date) => (
                    <li key={date}>
                      <ALNLabel sx={{ fontWeight: 'bold', cursor: "pointer", width: "120px" }} onClick={() => { toggleCollapse(date); }}>
                        {date}
                      </ALNLabel>
                      {isOpen === date && 
                      <List>
                        {logFileNames[date]?.map((log) => (
                          <ListItem key={log} sx={{ padding: "0px"}}>
                            <ALNBtn
                              onClick={() => setCurrentLogFile(log)}
                              sx={{
                                color: currentLogFile === log ? "blue" : "inherit",
                              }}
                            >
                              <ALNLabel
                                sx={{
                                  fontFamily: "OpenSans",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  letterSpacing: 0.02,
                                  textAlign: "left",
                                  textTransform: "lowercase",
                                }}
                              >
                                {log}
                              </ALNLabel>
                            </ALNBtn>
                          </ListItem>
                        ))}
                      </List>
                      }
                    </li>
                  ))}
              </ul>
              <Box width={"10px"}></Box>
              <Box
                component="pre"
                sx={{
                  maxHeight: "75vh",
                  // overflowY: "auto",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {logFile}
              </Box>
            </Box>
          </Box>
        )}

        {view === "errors" && (
          <Box
            sx={{
              paddingLeft: "34px",
              paddingRight: "34px",
              maxHeight: "85%",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <ALNLabel variant="h6" sx={{ paddingTop: "10px"}}>
              Error Messages
            </ALNLabel>
            <ul>
              {errorMessages.map((error) => (
                <li key={error}>{error}</li>
              ))}
              {errorMessages.length === 0 && <li>No errors</li>}
            </ul>
          </Box>
        )}

        {view === "productCounts" && (
          <Box 
            sx={{
              paddingLeft: "34px",
              paddingRight: "34px",
              maxHeight: "86%",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
            display="flex" flexDirection="column">
            {productCounts.map((productCount, index) => (
              <Box
                key={productCount.mailbox}
                display="flex"
                justifyContent="space-between"
                width="100%"
                bgcolor={index % 2 === 0 ? ALNColors.white_medium_gray : ALNColors.white}
                sx={{ marginTop: "15px" }}
              >
                <ALNLabel variant="body1" sx={{ display: 'inline' }}>
                  {productCount.mailbox}
                </ALNLabel>
                <ALNLabel variant="body1" sx={{ display: 'inline' }}>
                  {productCount.count}
                </ALNLabel>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Divider />
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Password");
                setCurrentlyChanged("password");
            }}
        >
          <ALNLabel variant="body1">Change Password</ALNLabel>
        </MenuItem>
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Company");
                setCurrentlyChanged("company");
            }}
        >
          <ALNLabel variant="body1">Change Company</ALNLabel>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Logout />
          <ALNLabel variant="body1">Log out</ALNLabel>
        </MenuItem>
      </Menu>
      {user && (
        <UpdateUserModal
            currentlyChanged={currentlyChanged}
            open={updateUserModal}
            onClose={handleCloseUpdateUserModal}
            user={user!}
            title={modalTitle}
        />
      )}
    </Box>
  );
}
