import type { Rect, Position } from 'css-box-model';

interface Args {
  container: Rect;
  subject: Rect;
  proposedScroll: Position;
}

const adjustForSizeLimits = ({
  container,
  subject,
  proposedScroll,
}: Args): Position | null => {
  const isTooBigVertically: boolean = subject.height > container.height;
  const isTooBigHorizontally: boolean = subject.width > container.width;

  // not too big on any axis
  if (!isTooBigHorizontally && !isTooBigVertically) {
    return proposedScroll;
  }

  // too big on both axis
  if (isTooBigHorizontally && isTooBigVertically) {
    return null;
  }

  // Only too big on one axis
  // Exclude the axis that we cannot scroll on
  return {
    x: isTooBigHorizontally ? 0 : proposedScroll.x,
    y: isTooBigVertically ? 0 : proposedScroll.y,
  };
};

export default adjustForSizeLimits;
