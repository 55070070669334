import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { TimePeriod } from "../../services/models/TimePeriod";
import { EmailVolume } from "../../services/models/EmailVolume";
import { CardContent, Grid2 as Grid } from "@mui/material";
import { ALNColors } from "../../utils/color";
import ALNLabel from "../label/ALNLabel";

interface LineChartProps {
  data: EmailVolume[] | undefined;
  width: number;
  height: number;
  timePeriod: TimePeriod;
  quoteValue: number;
}

function LineChart({
  data,
  width,
  height,
  timePeriod,
  quoteValue,
}: LineChartProps) {
  const ref = useRef<SVGSVGElement | null>(null);
  const margin = 20;

  const chageTimeLabel = (label: string) => {
    return label;
  };

  function outputQuoteValue(quoteValue: number): string {
    let outputQuoteValue = Math.round(quoteValue);
    if (outputQuoteValue > 1000000) {
      return (outputQuoteValue / 1000000).toFixed(1).toLocaleString() + " M";
    } else if (outputQuoteValue > 1000) {
      outputQuoteValue = Math.round(quoteValue / 1000);
      return outputQuoteValue.toLocaleString() + " K";
    } else {
      return outputQuoteValue.toLocaleString();
    }
  }

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height);
    svg.selectAll("*").remove(); // Clear previous renders

    // Prepare the filtered data
    const filteredData = (data || []).map((d) => ({
      quoteValue: d.quoteValue ?? 0,
      label: d.label,
    })) as { quoteValue: number; label: string }[];

    const tickValues = (data || [])
      .filter((d) => d.showLabel)
      .map((d) => d.label) as string[];

    // Set up the scales for x and y axes
    const xScale = d3
      .scaleBand()
      .domain(filteredData.map((d) => d.label))
      .range([margin, width - margin])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(filteredData, (d) => d.quoteValue) || 1]) // Adjusted scale to fit all values
      .range([height - margin, margin]); // Inverted range for proper orientation

    // Define the line generator function
    const line = d3
      .line<{ quoteValue: number; label: string }>()
      .x((d) => xScale(d.label)! + xScale.bandwidth() / 2) // Center points within bands
      .y((d) => yScale(d.quoteValue));
    // .curve(d3.curveMonotoneX); // Smooth line

    // Append the path element for the line with animation
    svg
      .append("path")
      .datum(filteredData)
      .attr("fill", "none")
      .attr("stroke", ALNColors.green_light)
      .attr("stroke-width", 1)
      .attr("d", line)
      .attr("stroke-dasharray", function () {
        const length = (this as SVGPathElement).getTotalLength();
        return `${length} ${length}`;
      })
      .attr("stroke-dashoffset", function () {
        return (this as SVGPathElement).getTotalLength();
      })
      .transition()
      .duration(1500)
      .ease(d3.easeCubic)
      .attr("stroke-dashoffset", 0);

    // Create tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip-line")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", ALNColors.white)
      .style("width", "64px")
      .style("margin", "5px")
      .style("pointer-events", "none");

    // Add circles for each data point with tooltip functionality
    svg
      .selectAll("circle")
      .data(filteredData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.label)! + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.quoteValue))
      .attr("r", 5)
      .style("cursor", "pointer")
      .attr("fill", ALNColors.green_light)
      .style("opacity", 0)
      .style("transition", "opacity 0.2s ease")
      .on("mouseover", (event, d) => {
        d3.select(event.currentTarget).style("opacity", 1);
        tooltip
          .style("opacity", 1)
          .html(
            `<div class="time">${chageTimeLabel(d.label)}</div><div class="value">$${outputQuoteValue(d.quoteValue)}</div>`,
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mouseout", (event) => {
        d3.select(event.currentTarget).style("opacity", 0);
        tooltip.style("opacity", 0);
      });

    // Create X-axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickValues(tickValues)
          .tickFormat((d) => chageTimeLabel(d) as string)
          .tickSize(0),
      )
      .selectAll("text")
      .style("text-anchor", "middle")
      .style("font-size", "10px");
    svg.append("style").text(`
          .tooltip-line {
            font-size: 14px;
            pointer-events: none;
            transition: opacity 0.2s ease;
        
            font-family: Arial, sans-serif;
            pointer-events: none;
            display: flex;
            flex-direction: column;
            border: 1px solid #C1C4C9;
            border-radius: 2px;
        
            .time {
                font-size: 10px;
                text-align: left;
                color: black;
                background-color: white;
                padding: 5px 5px 5px 5px;
            }
            .value{
                font-size: 12px;
                text-align: right;
                color: #3A4188;
                background-color: #C1C4C9;
                padding: 5px 5px 5px 5px;
            }
        }
        
        circle {
            transition: r 0.2s ease, fill 0.2s ease;
        }
        
        circle:hover {
            fill: #6FB391;
        }
      `);
    return () => {
      tooltip.remove();
    };
  }, [data, width, height, margin]);

  return (
    <CardContent>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid sx={{ width: "50%" }}>
          <ALNLabel variant="h6" sx={{ mb: '0.35em' }}>
            Total Quote Volumes
          </ALNLabel>
          <svg ref={ref} />
        </Grid>
        <Grid padding={2} alignSelf={"end"} paddingBottom={4}>
          <ALNLabel
            variant="h3"
            sx={{ color: ALNColors.success, align: "right" }}
          >
            ${outputQuoteValue(quoteValue)}
          </ALNLabel>
          <ALNLabel variant="body2" sx={{ align: "right" }}>
            {timePeriod === "Day"
              ? "today"
              : `this ${timePeriod.toLowerCase()}`}
          </ALNLabel>
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default LineChart;
