/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {any}
     * @memberof User
     */
    id?: any | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    passwordHash?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastLoginDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    canAdministerUsers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    canAdministerCompanies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    canAdministerMailBoxes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    canRespondToEmails?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    canAdminsterEmailRules?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    salesDeskManager?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    salesDeskUser?: boolean;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): value is User {
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'password': json['password'] == null ? undefined : json['password'],
        'passwordHash': json['passwordHash'] == null ? undefined : json['passwordHash'],
        'lastLoginDate': json['lastLoginDate'] == null ? undefined : (new Date(json['lastLoginDate'])),
        'isActive': json['isActive'] == null ? undefined : json['isActive'],
        'canAdministerUsers': json['canAdministerUsers'] == null ? undefined : json['canAdministerUsers'],
        'canAdministerCompanies': json['canAdministerCompanies'] == null ? undefined : json['canAdministerCompanies'],
        'canAdministerMailBoxes': json['canAdministerMailBoxes'] == null ? undefined : json['canAdministerMailBoxes'],
        'canRespondToEmails': json['canRespondToEmails'] == null ? undefined : json['canRespondToEmails'],
        'canAdminsterEmailRules': json['canAdminsterEmailRules'] == null ? undefined : json['canAdminsterEmailRules'],
        'salesDeskManager': json['salesDeskManager'] == null ? undefined : json['salesDeskManager'],
        'salesDeskUser': json['salesDeskUser'] == null ? undefined : json['salesDeskUser'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdBy': value['createdBy'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedBy': value['modifiedBy'],
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'emailAddress': value['emailAddress'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'password': value['password'],
        'passwordHash': value['passwordHash'],
        'lastLoginDate': value['lastLoginDate'] == null ? undefined : ((value['lastLoginDate']).toISOString()),
        'isActive': value['isActive'],
        'canAdministerUsers': value['canAdministerUsers'],
        'canAdministerCompanies': value['canAdministerCompanies'],
        'canAdministerMailBoxes': value['canAdministerMailBoxes'],
        'canRespondToEmails': value['canRespondToEmails'],
        'canAdminsterEmailRules': value['canAdminsterEmailRules'],
        'salesDeskManager': value['salesDeskManager'],
        'salesDeskUser': value['salesDeskUser'],
    };
}

