import React, { useState, useEffect } from "react";
import { Box, Menu, MenuItem, Divider } from "@mui/material";
import { Email, EmailStatus } from "../../../services/models";
import IssueReported from "./IssueReported";
import AutoResponded from "./AutoResponded";
import Ignored from "./Ignored";
import ManuallyResponded from "./ManuallyResponded";
import CouldNotRespondLayer from "./CouldNotRespondLayer";
import ALNLabel from "../../../components/label/ALNLabel";
import { ALNColors } from "../../../utils/color";
import { ReactComponent as DownIcon } from "../../../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../../../assets/images/log-out.svg";
import avatarImg from "../../../assets/images/avatar.png";
import { User } from "../../../services/models/User";
import { Global } from "../../../Global";
import { UpdateUserModal } from "../../../pages/user/UpdateUserModal";

export function EmailEditLayer(props: {
  email: Email;
  onClose: () => void;
}): JSX.Element {
  const [user, setUser] = useState<User | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
      "password" | "company"
  >("password");
  let token = Global.getJwtToken();
  useEffect(() => {
      Global.getUser().then((user) => {
          setUser(user);
      });
  }, [token]);

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
      setAnchorEl(null);
  };
  const handleUpdateUserModal = () => {
      setUpdateUserModal(true);
  };
  const handleCloseUpdateUserModal = () => {
      setUpdateUserModal(false);
  };
  const handleLogout = () => {
      Global.setJwtToken(undefined);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <ALNLabel
          sx={{
            fontSize: "10px",
            lineHeight: "12px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Email &gt; replyaitest.dev &gt; Email: Quote Request
        </ALNLabel>
        <Box
          sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: "10px",
              padding: "8px 13px",
          }}
          onClick={handleUserInfo}
        >
          <Box
              component="img"
              src={avatarImg}
              alt="avatar"
              sx={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
              }}
          />
          <Box sx={{ margin: "0 0px" }}>
              <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
              <ALNLabel sx={{ display: "none" }}>
              {"["}
              {Global.getGlobal().company}
              {"]"}
              </ALNLabel>
          </Box>
          <DownIcon />
        </Box>
      </Box>
      <Box
        sx={{
          background: ALNColors.white,
          borderRadius: "16px",
          paddingLeft: "18px",
          paddingRight: "18px",
          paddingTop: "14px",
          paddingBottom: "14px",
        }}
      >
        {props.email.emailStatus === EmailStatus.IssueReported && (
          <IssueReported emailData={props.email} onClose={props.onClose} />
        )}
        {props.email.emailStatus === EmailStatus.AutoProcessed && (
          <AutoResponded emailData={props.email} onClose={props.onClose} />
        )}
        {props.email.emailStatus === EmailStatus.Ignored && (
          <Ignored emailData={props.email} onClose={props.onClose} />
        )}
        {props.email.emailStatus === EmailStatus.ManualProcessed && (
          <ManuallyResponded
            emailData={props.email}
            onClose={props.onClose}
          />
        )}
        {props.email.emailStatus === EmailStatus.NotProcessed && (
          <CouldNotRespondLayer
            emailData={props.email}
            onClose={props.onClose}
          />
        )}
      </Box>
      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Divider />
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Password");
                setCurrentlyChanged("password");
            }}
        >
            <ALNLabel variant="body1">Change Password</ALNLabel>
        </MenuItem>
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Company");
                setCurrentlyChanged("company");
            }}
        >
            <ALNLabel variant="body1">Change Company</ALNLabel>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
            <Logout />
            <ALNLabel variant="body1">Log out</ALNLabel>
        </MenuItem>
      </Menu>
      {user && (
          <UpdateUserModal
              currentlyChanged={currentlyChanged}
              open={updateUserModal}
              onClose={handleCloseUpdateUserModal}
              user={user!}
              title={modalTitle}
          />
      )}
    </Box>
  );
}

export default EmailEditLayer;
