import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Checkbox,
  Tabs,
  Tab,
  FormControl,
} from "@mui/material";

import {
  DynamicSetting,
  EmailHandleType,
  MailBox,
  PluginConfig,
  PluginConfigDataDef,
} from "../../services/models";

import { Services } from "../../Services";
import { Global } from "../../Global";
import ALNModal from "../../components/modal/ALNModal";
import ALNModalTitle from "../../components/modal/ALNModalTitle";
import ALNCancelBtn from "../../components/form/ALNBtn/ALNCancelBtn";
import ALNModalHeader from "../../components/modal/ALNModalHeader";
import ALNInput from "../../components/form/ALNInput/ALNInput";
import ALNBtn from "../../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../../components/label/ALNLabel";
import ALNFormControlLabel from "../../components/form/ALNFormControlLabel/ALNFormControlLabel";
import { ALNColors } from "../../utils/color";

export enum MailboxSubPage {
  General = "General",
  Rules = "Rules",
  EmailServer = "Email Server",
  ERP = "ERP Setup",
  Prompt = "Prompt",
}

interface MailboxModalProps {
  open: boolean;
  onClose: () => void;
  pmailbox?: MailBox;
  isNew: boolean;
}

function MailboxModal ({ open, onClose, pmailbox, isNew }: MailboxModalProps) {
  const [mailbox, setMailbox] = useState<MailBox>({});

  const [subpage, setSubpage] = useState<MailboxSubPage>(
    MailboxSubPage.General,
  );

  const [erpMetaData, setErpMetaData] = useState<PluginConfig>({});
  const [emailMetaData, setEmailMetaData] = useState<PluginConfig>({});
  const [url, setUrl] = useState<string>("");

  const [mailboxTest, setMailboxTest] = useState<string>("");

  useEffect(() => {
    if (mailbox.erpInterfacePlugin)
      Services.mailBoxService
        .getERPSytemInterfacePluginConfigDefinition(
          Global.getJwtToken()!,
          mailbox.erpInterfacePlugin,
        )
        .then((data) => {
          setErpMetaData(data);
        });
  }, [mailbox.erpInterfacePlugin]);

  useEffect(() => {
    if (mailbox.emailInterfacePlugin)
      Services.mailBoxService
        .getEmailInterfaceConfigDefinition(
          Global.getJwtToken()!,
          mailbox.emailInterfacePlugin,
        )
        .then((data) => {
          setEmailMetaData(data);
        });
  }, [mailbox.emailInterfacePlugin]);

  useEffect(() => {
    if (pmailbox) {
      setMailbox(pmailbox);
    } else {
      Services.mailBoxService
        .createMailboxData(Global.getJwtToken()!)
        .then((data) => {
          setMailbox(data);
        });
    }
  }, [pmailbox]);

  const handleClose = () => {
    setUrl("");
    onClose();
  };

  const handleSubmit = () => {
    setUrl("");
    Services.mailBoxService.saveMailBox(Global.getJwtToken()!, mailbox!)
    .then(() => {})
    .catch(err => {
      console.log(err);
    });
    
    handleClose();
  };

  return (
    <ALNModal
      open={open}
      onClose={handleClose}
    >
      <ALNModalHeader>
        <ALNModalTitle title = {!isNew ? "Edit Mailbox" : "New Mailbox"} />
        <ALNCancelBtn onClick = { handleClose } />
      </ALNModalHeader>
      <Box
        sx={{
          p: 2,
          bgcolor: ALNColors.red_light,
          color: ALNColors.red_dark,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 1,
        }}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <ALNLabel variant="body2">
            {mailbox.needsAuthorization && !url && (
              <p>Authorization Token mail server needs to be refreshed</p>
            )}
            {!mailbox.needsAuthorization && !url && (
              <p>Click to refresh token</p>
            )}
            {url && <p>Click to authorize mailbox</p>}
          </ALNLabel>
          {mailboxTest && (
            <ALNLabel
              sx={{
                wordWrap: "break-word",
                color: ALNColors.red
              }}
            >
              Test Result: {mailboxTest}
            </ALNLabel>
          )}
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          {!url && (
            <ALNBtn 
              variant="contained"
              sx={{
                color: ALNColors.white,
                backgroundColor: ALNColors.red_very
              }}
              onClick = { () => {
                setMailboxTest("");
                Services.mailBoxService
                  .beginAuthorization(
                    Global.getJwtToken()!,
                    mailbox.emailAddress!,
                  )
                  .then((url) => {
                    setUrl(url.url);
                  });
              }}>
                Refresh Access Token
            </ALNBtn>
          )}
          {url && (
            <ALNBtn 
              variant="contained"
              sx={{
                backgroundColor: ALNColors.primary_btn,
                color: ALNColors.white,
              }}
              onClick = { () => {
                window.open(url, "_blank");
                setUrl("");
                setMailboxTest("");
                onClose();
              }}>
              Open Authorization URL
            </ALNBtn>
          )}

          {!mailbox.needsAuthorization && (
            <ALNBtn 
              variant="contained"
              sx={{
                backgroundColor: ALNColors.primary_btn,
                color: ALNColors.white,
                mt: 2
              }}
              onClick = { () => {
                setUrl("");
                setMailboxTest("Testing...");
                Services.mailBoxService
                  .testEmail(Global.getJwtToken()!, mailbox.emailAddress!)
                  .then((data) => {
                    setMailboxTest(data);
                  });
              }}>
              Test Mail Server
            </ALNBtn>
          )}
        </Box>
      </Box>
      <Box sx={{ pl: 4, pr: 4, pt: 2 }}>
        <ALNLabel variant="subtitle2">Edit Mailbox</ALNLabel>
        <ALNInput
          id="emailAddress"
          type="email"
          label="E-Mail Address"
          name="emailAddress"
          value={mailbox.emailAddress ?? ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (isNew) {
              setMailbox({
                ...mailbox,
                emailAddress: e.target.value,
              });
            }
          }}
        />

        <Tabs
          value={subpage}
          onChange={(event, value) => {
            setSubpage(value);
          }}
          sx={{ borderColor: ALNColors.gray_medium }}
        >
          <Tab label="General" value={MailboxSubPage.General} />
          <Tab label="Rules" value={MailboxSubPage.Rules} />
          <Tab label="Email Server" value={MailboxSubPage.EmailServer} />
          <Tab label="ERP Setup" value={MailboxSubPage.ERP} />
          <Tab label="Prompt" value={MailboxSubPage.Prompt} />
        </Tabs>

        {subpage === MailboxSubPage.General && (
          <Box padding={2} border={1} margin={0} borderColor={ALNColors.black_light_grayish}>
            <ALNLabel variant="subtitle2">Email Type</ALNLabel>
            <ALNInput
              id="emailType"
              label="E-Mail Type"
              name="emailType"
              type="select"
              value={mailbox!.emailType || "sales"}
              onChange={(e) => {
                const updatedMailbox = {
                  ...mailbox,
                  emailType: e.target.value,
                };
                // @ts-ignore
                setMailbox(updatedMailbox);
              }}
            >
              <MenuItem value="sales">Sales</MenuItem>
              <MenuItem value="support">Support</MenuItem>
            </ALNInput>
            <Box height={"10px"} />

            <FormControl
              component="fieldset"
              fullWidth
              margin="none"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "start",
              }}
            >
              <ALNFormControlLabel
                control={
                  <Checkbox
                    checked={mailbox!.autoCheck || false}
                    onChange={(e) =>
                      setMailbox({
                        ...mailbox,
                        autoCheck: e.target.checked,
                      })
                    }
                  />
                }
                label="Auto Check"
                labelPlacement="end"
              />
            </FormControl>
            <Box height={"10px"} />
            <ALNInput
              id="emailHandleStrategy"
              name="emailHandleStrategy"
              label="Email Handle Strategy"
              type="select"
              value={mailbox!.emailHandleStrategy || ""}
              onChange={(e) =>
                setMailbox({
                  ...mailbox,
                  // @ts-ignore
                  emailHandleStrategy: e.target.value,
                })
              }
            >
              {Object.values(EmailHandleType).map((strategy) => (
                <MenuItem key={strategy} value={strategy}>
                  {strategy}
                </MenuItem>
              ))}
            </ALNInput>
            <Box height={"10px"} />
            <ALNInput
              type="email"
              id="forwardEmail"
              name="forwardEmail"
              label="Forward Email"
              value={mailbox!.forwardEmail}
              required={
                mailbox.emailHandleStrategy
                  ?.toLowerCase()
                  .indexOf("Forward") !== -1
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const updatedMailbox = {
                  ...mailbox,
                  forwardEmail: e.target.value,
                };
                setMailbox(updatedMailbox);
              }}
            />
          </Box>
        )}

        {subpage === MailboxSubPage.Rules && (
          <Box padding={2} border={1} margin={0} borderColor={ALNColors.black_light_grayish}>
            <ALNInput 
              type="number"
              id="autoReplyThreshold"
              name="autoReplyThreshold"
              label="Auto Reply Threshold"
              value={mailbox!.autoReplyThreshold}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const updatedMailbox = {
                  ...mailbox,
                  autoReplyThreshold: parseInt(e.target.value),
                };
                setMailbox(updatedMailbox);
              }}
            />
            <Box height={"10px"} />
            <ALNInput 
              type="number"
              id="targetPriceAutoReplyThreshold"
              name="targetPriceAutoReplyThreshold"
              label="Target Price Auto Reply Threshold"
              value={mailbox!.targetPriceAutoReplyThreshold}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const updatedMailbox = {
                  ...mailbox,
                  targetPriceAutoReplyThreshold: parseInt(e.target.value),
                };
                setMailbox(updatedMailbox);
              }}
            />
            <Box height={"10px"} />
            <ALNInput 
              id="productsToExclude"
              name="productsToExclude"
              label="Products to Exclude"
              type="multiline"
              rows={4}
              value={mailbox!.productsToExclude?.join("\n") || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const updatedMailbox = {
                  ...mailbox,
                  productsToExclude: e.target.value.split("\n"),
                };
                setMailbox(updatedMailbox);
              }}
            />
          </Box>
        )}

        {subpage === MailboxSubPage.EmailServer && (
          <Box padding={2} border={1} margin={0} borderColor={ALNColors.black_light_grayish}>
            <ALNInput
              id="emailGeneratorPlugin"
              name="emailGeneratorPlugin"
              label="Email Generator Plugin"
              type = "select"
              value={mailbox!.emailGeneratorPlugin || ""}
              onChange={(e) =>
                setMailbox({
                  ...mailbox,
                  emailGeneratorPlugin: e.target.value,
                })
              }
            >
              {Global.getGlobal().emailGeneratorPluginList!.map((plugin) => (
                <MenuItem key={plugin} value={plugin}>
                  {plugin}
                </MenuItem>
              ))}
            </ALNInput>
            <Box height={"10px"} />
            <ALNInput
              id="emailParserPlugin"
              name="emailParserPlugin"
              label="Email Parser Plugin"
              type = "select"
              value={mailbox!.emailParserPlugin || ""}
              onChange={(e) =>
                setMailbox({
                  ...mailbox,
                  emailParserPlugin: e.target.value,
                })
              }
            >
              {Global.getGlobal().emailParserPluginList!.map((plugin) => (
                <MenuItem key={plugin} value={plugin}>
                  {plugin}
                </MenuItem>
              ))}
            </ALNInput>
            <Box height={"10px"} />
            <ALNInput 
              id="emailInterfacePlugin"
              name="emailInterfacePlugin"
              label="Email Interface Plugin"
              type="select"
              value={mailbox!.emailInterfacePlugin || ""}
              onChange={(e) =>
                setMailbox({
                  ...mailbox,
                  emailInterfacePlugin: e.target.value,
                })
              }
            >
              {Global.getGlobal().emailInterfacePluginList!.map((plugin) => (
                <MenuItem key={plugin} value={plugin}>
                  {plugin}
                </MenuItem>
              ))}
            </ALNInput>
            
            <Box height={"10px"} />
            <DynamicEditor
              value={mailbox.mailSettings}
              metaData={emailMetaData}
              onChange={(value) =>
                setMailbox({
                  ...mailbox,
                  mailSettings: value,
                })
              }
            />
          </Box>
        )}

        {subpage === MailboxSubPage.ERP && (
          <Box padding={2} border={1} margin={0} borderColor={ALNColors.black_light_grayish}>
            <ALNInput
              id="erpInterfacePlugin"
              name="erpInterfacePlugin"
              label="ERP Interface Plugin"
              type = "select"
              value={mailbox!.erpInterfacePlugin || ""}
              onChange={(e) =>
                setMailbox({
                  ...mailbox,
                  erpInterfacePlugin: e.target.value,
                })
              }
            >
              {Global.getGlobal().erpInterfacePluginList!.map((plugin) => (
                <MenuItem key={plugin} value={plugin}>
                  {plugin}
                </MenuItem>
              ))}
            </ALNInput>
            <Box height={"10px"} />
            <DynamicEditor
              value={mailbox.erpSettings}
              metaData={erpMetaData}
              onChange={(value) =>
                setMailbox({
                  ...mailbox,
                  erpSettings: value,
                })
              }
            />
          </Box>
        )}
        {subpage === MailboxSubPage.Prompt && (
          <Box padding={2} border={1} margin={0} borderColor={ALNColors.black_light_grayish}>
            <ALNInput
              id="currentPrompt"
              name="currentPrompt"
              label="Current Prompt"
              type = "multiline"
              rows={8}
              value={mailbox!.currentPrompt}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const updatedMailbox = {
                  ...mailbox,
                  currentPrompt: e.target.value,
                };
                setMailbox(updatedMailbox);
              }}
            />
          </Box>
        )}
      </Box>
      <ALNBtn 
        variant="contained"
        sx={{ bgcolor: ALNColors.email_manual, mt: 2, width: "120px", mr: 4, mb: 2 ,ml: "auto", float: "inline-end"}}
        onClick = { handleSubmit }>
          Submit
      </ALNBtn>
    </ALNModal>
  );
};

function DynamicEditor(props: {
  value?: DynamicSetting[];
  metaData: PluginConfig;
  onChange: (value: any) => void;
}): JSX.Element {

  let value: DynamicSetting[] = props.value ?? [];

  if (!props.metaData || !props.metaData.fields) {
    return <Box>No Settings</Box>;
  } else {
    if (!value) value = [];

    for (let i of props.metaData.fields ?? []) {
      if (value.find((v) => v.name === i.name) === undefined) {
        value.push({ name: i.name!, value: "" });
      }
    }
    return (
      <Box>
        {props.metaData.fields.map((field) => (
          <Box>
            <DynamicField
              key={field.name}
              field={field}
              value={value!.find((v) => v.name === field.name)!}
              onChange={() => {
                props.onChange(value);
              }}
            />
            <Box height={"10px"} />
          </Box>
        ))}
      </Box>
    );
  }
}

function DynamicField(props: {
  field: PluginConfigDataDef;
  value: DynamicSetting;
  onChange: (value: any) => void;
}): JSX.Element {
  const field = props.field;
  const value = props.value;
  const onChange = props.onChange;

  return (
    <FormControl fullWidth margin="none" variant="filled">
      <TextField
        variant="filled"
        label={field.description}
        type={field.dataType}
        id={field.name}
        name={field.name}
        value={value.value ?? ""}
        onChange={(e) => {
          value.value = e.target.value;
          onChange(value);
        }}
      />
    </FormControl>
  );
}

export default MailboxModal;
