/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SalesDeskAssignment } from './SalesDeskAssignment';
import {
    SalesDeskAssignmentFromJSON,
    SalesDeskAssignmentFromJSONTyped,
    SalesDeskAssignmentToJSON,
} from './SalesDeskAssignment';
import type { EmailSummary } from './EmailSummary';
import {
    EmailSummaryFromJSON,
    EmailSummaryFromJSONTyped,
    EmailSummaryToJSON,
} from './EmailSummary';

/**
 * Represents an assignment with its associated email details
 * @export
 * @interface AssignmentWithEmail
 */
export interface AssignmentWithEmail {
    /**
     * 
     * @type {SalesDeskAssignment}
     * @memberof AssignmentWithEmail
     */
    assignment: SalesDeskAssignment;
    /**
     * 
     * @type {EmailSummary}
     * @memberof AssignmentWithEmail
     */
    email: EmailSummary;
}

/**
 * Check if a given object implements the AssignmentWithEmail interface.
 */
export function instanceOfAssignmentWithEmail(value: object): value is AssignmentWithEmail {
    if (!('assignment' in value) || value['assignment'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function AssignmentWithEmailFromJSON(json: any): AssignmentWithEmail {
    return AssignmentWithEmailFromJSONTyped(json, false);
}

export function AssignmentWithEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignmentWithEmail {
    if (json == null) {
        return json;
    }
    return {
        
        'assignment': SalesDeskAssignmentFromJSON(json['assignment']),
        'email': EmailSummaryFromJSON(json['email']),
    };
}

export function AssignmentWithEmailToJSON(value?: AssignmentWithEmail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'assignment': SalesDeskAssignmentToJSON(value['assignment']),
        'email': EmailSummaryToJSON(value['email']),
    };
}

