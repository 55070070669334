import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  MenuProps,
  Snackbar, Alert, SnackbarCloseReason
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { styled, alpha } from '@mui/material/styles';
import EmailState from "../../components/EmailState";
import { EmailStatus } from "../../services/models/EmailStatus";
import AssignMailModal from "./modal/AssignMailModal";
import ALNBtn from "../../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../../components/label/ALNLabel";
import { ALNColors } from "../../utils/color";
import { Services } from "../../Services";
import { Global } from "../../Global";
import { MailBox } from "../../services/models";
import { ReactComponent as AssignEmailIcon } from "../../assets/images/assignemail-icon.svg";
import { ReactComponent as DownIcon } from "../../assets/images/down-icon.svg";
import { Team, UnAssignedEmailData } from "./types";
import { SalesDeskApi } from "../../services/apis/SalesDeskApi";

type Order = 'asc' | 'desc';

const getComparator = (order: 'asc' | 'desc', orderBy: keyof UnAssignedEmailData) => {
  return (a: UnAssignedEmailData, b: UnAssignedEmailData): number => {
    const aValue = a[orderBy] ?? ''; // Fallback to an empty string if undefined
    const bValue = b[orderBy] ?? '';
    if (order === 'asc') {
      return aValue > bValue ? 1 : -1;
    }
    return aValue < bValue ? 1 : -1;
  };
};

interface HeadCell {
  disablePadding: boolean;
  id: keyof UnAssignedEmailData;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'customer',
    numeric: false,
    disablePadding: true,
    label: 'CUSTOMER',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'EMAIL',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'STATUS',
  },
  {
    id: 'received',
    numeric: true,
    disablePadding: false,
    label: 'RECEIVED',
  },
  {
    id: 'timeinqueue',
    numeric: true,
    disablePadding: false,
    label: 'TIME IN QUEUE',
  },
];
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof UnAssignedEmailData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof UnAssignedEmailData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{ border: '1px solid ' + ALNColors.black, textAlign: "center" }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ border: '1px solid ' + ALNColors.black, textAlign: "center", color: ALNColors.black_gray }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: "10px",
    paddingTop: "0px",
    paddingBottom: "0px",
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    borderRadius: "16px",
    border: "1px solid " + ALNColors.background_gray,
    '& .MuiMenu-list': {
      padding: '0 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

const Unassigned = () => {
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [alertStatus, setAlertStatus] = useState<boolean>(false);
  const [alertModalOpen, setAlertModalOpen] = useState<boolean>(false);
  const autoHideDuration = 3000;
  const handleAlertModalClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertModalOpen(false);
  };

  const handleAlert = (status: boolean, msg: string, modalOpen: boolean) => {
    setAlertStatus(status);
    setAlertMsg(msg);
    setAlertModalOpen(modalOpen);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertModalOpen(false);
    }, autoHideDuration);

    return () => clearTimeout(timer);
  }, []);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof UnAssignedEmailData>('customer');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<EmailStatus>(EmailStatus.All);
  const EmailStatusArray = Object.values(EmailStatus);
  const [teams, setTeams] = useState<Team[]>([]);
  const [ordered, setOrdered] = useState<string[]>([]);
  const [assignEmailModalOpen, setAssignEmailModalOpen] = useState<boolean>(false);
  const [unAssignedEmails, setUnAssignedEmails] = useState<UnAssignedEmailData[]>([]);
  const [assignEmail, setAssignEmail] = useState("");
  // Reassign Mail Modal Part
  const handleAssignEmailModalOpen = () => {
    setAssignEmail(ordered[0]);
    setAssignEmailModalOpen(true);
  };

  const handleAssignEmailModalClose = () => {
    setAssignEmailModalOpen(false);
  };

  const handleAssignEmail = (email: string) => {
    setAssignEmail(email);
  }

  const handleAssignEmailSend = async () => {
    const auth = Global.getJwtToken()!;
    const assignTo = teams.find(team => team.TeamMember === assignEmail)?.address;
    if (selected.length !== 0) {
      for (let i = 0; i < selected.length; i++) {
        const assignID = unAssignedEmails[i].assignid;
        const mailbox = unAssignedEmails[i].mailbox;
        try {
          setAssignEmailModalOpen(false);
          await Services.salesDeskService.assignEmail(auth, mailbox!, assignID!, assignTo!);
          handleAlert(true, "Assigned mail Successfully!", true);
        } catch (error) {
          handleAlert(false, String(error), true);
        }
      }
      let temp_emails = unAssignedEmails.filter(
        item => item.id !== undefined && typeof item.id === "number" && !selected.includes(item.id)
      );
      setUnAssignedEmails(temp_emails);
      setSelected([]);
    }
    setAssignEmail(ordered[0]);
    setAssignEmailModalOpen(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UnAssignedEmailData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = (unAssignedEmails ?? [])
        .map((n) => n.id)
        .filter((id): id is number => id !== undefined); // Filter out undefined values
      setSelected(newSelected);
      return;
    }
    setSelected([])
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (unAssignedEmails?.length || 0)) : 0;

  const handleEmailStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManualFilter = (status: EmailStatus) => {
    setFilter(status);
  };
  const calculateTimeDifference = (minutes: string): number => {
    const givenDate = new Date(minutes);
    const currentDate = new Date();
    const diffMs = currentDate.getTime() - givenDate.getTime();
    const diffMinutes = Math.round(diffMs / (1000 * 60));
    return diffMinutes;
  }

  const token = Global.getJwtToken();

  const fetchUnAssignedData = async () => {
    const unassignedColumns: UnAssignedEmailData[] = [];
    let temp_teams: Team[] = [];
    let temp_ordered: string[] = [];
    const loadData = async (mailbox: MailBox) => {
      try {
        if (!token) {
          handleAlert(false, "No JWT token available", true);
          return;
        }
        const [assignments, users] = await Promise.all([
          Services.salesDeskService.getTeamAssignments(token, mailbox.emailAddress!),
          Services.salesDeskService.getAssignableUsers(token)
        ]);
        temp_teams = users.map((user) => {
          temp_ordered.push(user.fullName);
          return {
            id: user.id,
            address: user.emailAddress,
            TeamMember: user.fullName,
          }
        });
        for (let i = 0; i < assignments.unassigned.length; i++) {
          let minutesDiff = 0;
          const assignedDate = assignments.unassigned[i].assignment.assignedDate?.toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });

          if (assignedDate) {
            minutesDiff = calculateTimeDifference(assignedDate);
          }
          let timeinqueue = "";
          if (minutesDiff <= 5) {
            timeinqueue = "< 5 min";
          } else if (5 < minutesDiff && minutesDiff <= 10) {
            timeinqueue = "5-10 min";
          } else if (10 < minutesDiff && minutesDiff <= 20) {
            timeinqueue = "10-20 min";
          } else if (20 < minutesDiff && minutesDiff <= 30) {
            timeinqueue = "20-30 min";
          } else {
            timeinqueue = "> 30 min";
          }
          unassignedColumns.push({
            id: i,
            customer: assignments.unassigned[i].assignment.companyName,
            email: assignments.unassigned[i].email.from,
            status: assignments.unassigned[i].email.cannotReplyReason,
            received: assignedDate,
            timeinqueue: timeinqueue,
            emailstatus: assignments.unassigned[i].email.emailStatus,
            mailbox: assignments.unassigned[i].assignment.mailbox,
            assignid: assignments.unassigned[i].assignment.id,
          });
        }
      } catch (error) {
        handleAlert(false, String(error), true);
      }
    };
    try {
      const mailboxes = await Services.mailBoxService.getMailBoxes(token!);
      for (const mailbox of mailboxes) {
        await loadData(mailbox);
      }
      setTeams(temp_teams);
      setOrdered(temp_ordered);
      setUnAssignedEmails(unassignedColumns);
    } catch (error) {
      handleAlert(false, String(error), true);
    }
  };
  useEffect(() => {
    fetchUnAssignedData();
  }, [token]);

  const statusConfig = {
    All: {
      displaytitle: "All E-Mails",
      icon: "",
      color: ALNColors.blue,
    },
    AutoProcessed: {
      displaytitle: "Auto",
      icon: "email-auto-respond-icon.png",
      color: ALNColors.email_auto,
    },
    ManualProcessed: {
      displaytitle: "Manual",
      icon: "email-manual-respond-icon.png",
      color: ALNColors.email_manual,
    },
    NotProcessed: {
      displaytitle: "Could Not Respond",
      icon: "email-not-respond-icon.png",
      color: ALNColors.email_couldnotrespond,
    },
    Ignored: {
      displaytitle: "Ignored",
      icon: "email-ignore-icon.png",
      color: ALNColors.email_ignored,
    },
    IssueReported: {
      displaytitle: "With Issues",
      icon: "email-annotated-icon.png",
      color: ALNColors.email_withissue,
    },
  };

  const emailStates = EmailStatusArray.map((status, index) => ({
    id: index + 1,
    status: status,
    title: status,
    displaytitle: statusConfig[status].displaytitle,
    icon: statusConfig[status].icon,
    color: statusConfig[status].color,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        gap: "20px",
        marginTop: "25px",
        padding: "12px 17px",
        borderRadius: "8px",
        background: ALNColors.background_very_gray
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          gap: '40px',
        }}
      >
        <ALNLabel sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: 550, color: ALNColors.green_very_dark }}>
          Unassigned
        </ALNLabel>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100px",
              background: ALNColors.white,
              border: "1px solid " + ALNColors.background_gray,
              padding: "6px 12px",
              cursor: "pointer",
            }}
            onClick={handleEmailStatus}
          >
            <ALNLabel>Status: All</ALNLabel>
            <DownIcon />
          </Box>
          <ALNBtn
            variant="contained"
            sx={{ bgcolor: ALNColors.email_manual, height: '37px', borderRadius: 1.5 }}
            onClick={handleAssignEmailModalOpen}
          >
            <ALNLabel
              sx={{
                fontSize: "16px !important",
                fontWeight: 350,
                fontFamily: "'Open Sans', sans-serif",
                lineHeight: 1.5,
                color: ALNColors.white,
                letterSpacing: "2%",
                textTransform: "capitalize",
                padding: "0px",
                paddingRight: "12px",
              }}
            >
              Assign Mail
            </ALNLabel>
            <AssignEmailIcon />
          </ALNBtn>
        </Box>
      </Box>
      <Box
        sx={{
          background: ALNColors.white,
          padding: "0px",
        }}
      >
        <Paper
          sx={{
            width: '100%',
            borderRadius: "12px"
          }}
        >
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size='medium'
              sx={{
                minWidth: 750,
                border: "1px solid" + ALNColors.black,
                borderRadius: "12px"
              }}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={unAssignedEmails?.length ?? 0}
              />
              <TableBody>
                {
                  unAssignedEmails.filter((email) => {
                    if (filter === "All") return email;
                    else {
                      if (email.emailstatus === filter) {
                        return email;
                      }
                    }
                  })
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = row.id !== undefined && typeof row.id === 'number' && selected.includes(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => {
                            if (typeof row.id === 'number') {
                              handleClick(event, row.id);
                            }
                          }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell padding="checkbox"
                            sx={{ border: '1px solid ' + ALNColors.black }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ border: '1px solid ' + ALNColors.black, textAlign: "center", color: ALNColors.green_very_dark }}
                          >
                            {row.customer}
                          </TableCell>
                          <TableCell align="right" sx={{ border: '1px solid ' + ALNColors.black, textAlign: "center", color: ALNColors.email_manual }}>{row.email}</TableCell>
                          <TableCell align="right" sx={{ border: '1px solid ' + ALNColors.black, textAlign: "center", color: ALNColors.green_very_dark }}>{row.status}</TableCell>
                          <TableCell align="right" sx={{ border: '1px solid ' + ALNColors.black, textAlign: "center", color: ALNColors.green_very_dark }}>{row.received}</TableCell>
                          <TableCell align="right"
                            sx={{
                              border: '1px solid ' + ALNColors.black,
                              textAlign: "center",
                              backgroundColor:
                                row.timeinqueue === "> 30 min" ? alpha(ALNColors.email_withissue, 0.6) :
                                  row.timeinqueue === "20-30 min" ? alpha(ALNColors.email_withissue, 0.4) :
                                    row.timeinqueue === "10-20 min" ? alpha(ALNColors.email_withissue, 0.2) :
                                      row.timeinqueue === "5-10 min" ? alpha(ALNColors.email_withissue, 0.1) :
                                        row.timeinqueue === "< 5 min" ? alpha(ALNColors.email_withissue, 0.05) :
                                          ALNColors.white,
                              color: ALNColors.green_very_dark
                            }}
                          >
                            {row.timeinqueue}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={unAssignedEmails?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {emailStates.map((item, idx) => (
          <MenuItem key={idx}
          >
            <EmailState
              manualFilter={handleManualFilter}
              activeStatus={filter}
              key={item.id}
              data={item}
            />
          </MenuItem>
        ))}
      </StyledMenu>
      {/* Assign Mail Modal */}
      <AssignMailModal
        assignEmailModalOpen={assignEmailModalOpen}
        handleAssignEmailModalClose={handleAssignEmailModalClose}
        handleAssignEmail={handleAssignEmail}
        handleAssignEmailSend={handleAssignEmailSend}
        users={ordered.map(order => { return { value: order, name: order } })}
        modalTitle="Assign Mail"
        cancelButton="Cancel"
        confirmButton="Assign"
      />
      {/* Alert */}
      <Snackbar
        open={alertModalOpen}
        onClose={handleAlertModalClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={autoHideDuration}
      >
        <Alert severity={alertStatus ? "success" : "error"} sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Unassigned;