import { Settings } from "./Settings";
import {
  EmailAddressRulesApi,
  CompanyApi,
  // Configuration,
  DashboardApi,
  EmailApi,
  MailBoxApi,
  TestServiceApi,
  SupportServiceApi,
  SalesDeskApi,
} from "./services/apis";
import { Configuration } from "./services/runtime";
import { UserApi } from "./services/apis/UserApi"; // Adjust the import path as necessary

export class Services {
  static emailAddressRuleService = new EmailAddressRulesApi(
    new Configuration({ basePath: Settings.getSettings().SERVER_URL }),
  );
  static companyService = new CompanyApi(
    new Configuration({ basePath: Settings.getSettings().SERVER_URL }),
  );
  static emailService = new EmailApi(
    new Configuration({ basePath: Settings.getSettings().SERVER_URL }),
  );
  static mailBoxService = new MailBoxApi(
    new Configuration({ basePath: Settings.getSettings().SERVER_URL }),
  );
  static userService = new UserApi(
    new Configuration({ basePath: Settings.getSettings().SERVER_URL }),
  );

  static testService = new TestServiceApi(
    new Configuration({ basePath: Settings.getSettings().SERVER_URL }),
  );

  static dashboardService = new DashboardApi(
    new Configuration({ basePath: Settings.getSettings().SERVER_URL }),
  );

  static supportService = new SupportServiceApi(
    new Configuration({ basePath: Settings.getSettings().SERVER_URL }),
  );

  static salesDeskService = new SalesDeskApi(
    new Configuration({ basePath: Settings.getSettings().SERVER_URL }),
  );

  static jwtToken: string = "";
}
