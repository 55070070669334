/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a user who can be assigned to handle emails
 * @export
 * @interface AssignableUser
 */
export interface AssignableUser {
    /**
     * Unique identifier for the user
     * @type {string}
     * @memberof AssignableUser
     */
    id: string;
    /**
     * Email address of the user
     * @type {string}
     * @memberof AssignableUser
     */
    emailAddress: string;
    /**
     * Display name of the user (firstName + lastName)
     * @type {string}
     * @memberof AssignableUser
     */
    fullName: string;
}

/**
 * Check if a given object implements the AssignableUser interface.
 */
export function instanceOfAssignableUser(value: object): value is AssignableUser {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('emailAddress' in value) || value['emailAddress'] === undefined) return false;
    if (!('fullName' in value) || value['fullName'] === undefined) return false;
    return true;
}

export function AssignableUserFromJSON(json: any): AssignableUser {
    return AssignableUserFromJSONTyped(json, false);
}

export function AssignableUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignableUser {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'emailAddress': json['emailAddress'],
        'fullName': json['fullName'],
    };
}

export function AssignableUserToJSON(value?: AssignableUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'emailAddress': value['emailAddress'],
        'fullName': value['fullName'],
    };
}

