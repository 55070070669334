import {
    Box,
    Menu, 
    Divider,
    MenuItem, 
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import { ReactComponent as AddPlus } from "../assets/images/addPlug.svg";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import React, { useState, useEffect } from "react";
import { NewUserModal } from "./user/NewUserModal";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../components/label/ALNLabel";
import { ALNColors } from "../utils/color";
import TeamMember from "./TheSalesDesk/TeamMember";
import TimeInQueue from "./TheSalesDesk/TimeInQueue";
import Unassigned from "./TheSalesDesk/Unassigned";
import type { Team } from './TheSalesDesk/types';
import { Services } from "../Services";
import { Global } from "../Global";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { User } from "../services/models/User";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";

const TheSalesDesk = () => {
    const [user, setUser] = useState<User | undefined>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [updateUserModal, setUpdateUserModal] = useState(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [currentlyChanged, setCurrentlyChanged] = useState<
        "password" | "company"
    >("password");
    useEffect(() => {
        Global.getUser().then((user) => {
            setUser(user);
        });
    }, []);

    const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };
    const handleUpdateUserModal = () => {
        setUpdateUserModal(true);
    };
    const handleCloseUpdateUserModal = () => {
        setUpdateUserModal(false);
    };
    const handleLogout = () => {
        Global.setJwtToken(undefined);
    };

    const [resultType, setResultType] = useState("Team Member");
    const [teamLabels, setTeamLabels] = useState<Team[]>([]);
    const [selected, setSelected] = useState("auto");
    const [addTeamModalOpen, setAddTeamModalOpen] = useState(false);

    const onAutoHandle = (event: any, newSelection: any) => {
        if (newSelection !== null) {
            setSelected(newSelection);
        }
    };

    const token = Global.getJwtToken();

    const fetchTeamMemberLists = () => {
        let temp_teams: Team[] = [];
        Services.salesDeskService
            .getAssignableUsers(token!)
            .then((data) => {
            temp_teams = data.map((user) => {
                return {
                    id: user.id,
                    address: user.emailAddress,
                    TeamMember: user.fullName,
                }
            });
            setTeamLabels(temp_teams);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchTeamMemberLists();
        setResultType('Team Member');
        }, [token]);

    const onAddTeamMemberModalOpen = () => {
        setAddTeamModalOpen(true);
    };

    const onAddTeamMemberModalClose = () => {
        setAddTeamModalOpen(false);
        fetchTeamMemberLists();
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: "25px 50px",
                background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
            }}
        >
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
            The Response Desk
            </ALNLabel>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    gap: "10px",
                    padding: "8px 13px",
                }}
                onClick={handleUserInfo}
            >
                <Box
                    component="img"
                    src={avatarImg}
                    alt="avatar"
                    sx={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                    }}
                />
                <Box sx={{ margin: "0 0px" }}>
                    <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
                    <ALNLabel sx={{ display: "none" }}>
                    {"["}
                    {Global.getGlobal().company}
                    {"]"}
                    </ALNLabel>
                </Box>
                <DownIcon />
            </Box>
        </Box>
        <Box 
            sx={{ 
                background: ALNColors.white,
                padding: "25px 25px",
                borderRadius : "16px",
                border: "1px solid "+ALNColors.background_gray,
                minHeight: "100vh",
                overflowX: 'hidden',
            }} 
        >
            {/* Header Part */}
            <Box
                sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddtingTop: "0px",
                marginBottom: "24px"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <ArrowBackIcon
                        style={{
                        marginRight: "14px",
                        cursor: "pointer",
                        }}
                        onClick={() => {
                        }}
                    />
                    <ALNLabel sx={{ fontSize: "24px", lineHeight: "18px", fontWeight: 700 }}>
                        The Sales Desk
                    </ALNLabel>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            marginRight: "5px",
                        }}
                    >
                        {teamLabels.map((team, idx) => (
                            <Box
                                key={idx}
                                sx={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: "50%",
                                    backgroundColor: idx%4 == 0 ? ALNColors.blue_very_bright : idx%4==1 ? ALNColors.green_very_bright : idx%4==2 ? ALNColors.red_very_bright : ALNColors.yellow_black,
                                    padding: "6px",
                                    marginLeft: "-1px",
                                    color: ALNColors.white,
                                    cursor: "pointer",
                                    fontFamily: "OpenSans",
                                    fontSize: '15px',
                                    fontWeight: 590,
                                    lineHeight: '21px',
                                    letterSpacing: 0.02,
                                    textAlign: "center",
                                    alignItems: "center",
                                    alignContent: "center"
                                }}
                            >
                                {team.TeamMember.split(" ")[0][0]! + team.TeamMember.split(" ")[1][0]!}
                            </Box>
                        ))}
                    </Box>
                    <ALNBtn
                        sx={{
                            borderRadius: '5px',
                            padding: '8px 16px',
                            marginRight: "0px",
                            bgcolor: ALNColors.primary_btn,
                            fontFamily: "OpenSans",
                            fontSize: '15px',
                            fontWeight: 590,
                            lineHeight: '21px',
                            letterSpacing: 0.02,
                            textAlign: "left",
                            color: ALNColors.white,
                            cursor: "pointer",
                            textTransform: 'capitalize',
                        }}
                        onClick={ onAddTeamMemberModalOpen }
                    >
                        Add Team Member &nbsp;<AddPlus />
                    </ALNBtn>
                </Box>
            </Box>
            {/* ToggleButtonGroup */}
            <Box
                sx={{
                    paddingLeft: "0px",
                    paddingRight: "34px",
                    gap: "12px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                }}
            >
                <ToggleButtonGroup
                    value={resultType}
                    exclusive
                    onChange={ (e, newValue) => {
                        setResultType(newValue);
                    }}
                    sx= {{
                        width: "390px",
                        padding: "5px",
                        background: ALNColors.background_very_gray,
                        borderRadius: "6px",
                    }}
                >
                    <ToggleButton
                        sx={{
                        minWidth: 130,
                        border: "none",
                        borderRadius: "8px",
                        color: ALNColors.border_very_gray,
                        "&.Mui-selected": {
                            backgroundColor: ALNColors.indigo,
                            color: ALNColors.white,
                            "&:hover": {
                            backgroundColor: ALNColors.white_blue,
                            },
                        },
                        }}
                        value="Team Member"
                    >
                        <ALNLabel
                            sx={{
                                fontFamily: "OpenSans",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "20px",
                                letterSpacing: 0.02,
                                textAlign: "left",
                                textTransform: 'capitalize',
                            }}
                        >
                            Team Member
                        </ALNLabel>
                    </ToggleButton>
                    <ToggleButton
                        sx={{
                        minWidth: 130,
                        borderRadius: "8px",
                        color: ALNColors.border_very_gray,
                        border: "none",
                        "&.Mui-selected": {
                            backgroundColor: ALNColors.indigo,
                            color: ALNColors.white,
                            "&:hover": {
                            backgroundColor: ALNColors.white_blue,
                            },
                        },
                        }}
                        value="Time in Queue"
                    >
                        <ALNLabel
                            sx={{
                                fontFamily: "OpenSans",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "20px",
                                letterSpacing: 0.02,
                                textAlign: "left",
                                textTransform: 'capitalize',
                            }}
                        >
                            Time in Queue
                        </ALNLabel>
                    </ToggleButton>
                    <ToggleButton
                        sx={{
                        minWidth: 130,
                        borderRadius: "8px",
                        color: ALNColors.border_very_gray,
                        border: "none",
                        "&.Mui-selected": {
                            backgroundColor: ALNColors.indigo,
                            color: ALNColors.white,
                            "&:hover": {
                            backgroundColor: ALNColors.white_blue,
                            },
                        },
                        }}
                        value="Unassigned"
                    >
                        <ALNLabel
                            sx={{
                                fontFamily: "OpenSans",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "20px",
                                letterSpacing: 0.02,
                                textAlign: "left",
                                textTransform: 'capitalize',
                            }}
                        >
                            Unassigned
                        </ALNLabel>
                    </ToggleButton>
                </ToggleButtonGroup>
                {/* <Box
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        marginLeft: "15px",
                    }}
                >
                    <ToggleButtonGroup
                        value={selected}
                        exclusive
                        onChange={onAutoHandle}
                        sx={{ 
                            display: "flex", 
                            gap: "0px",
                            padding: "1px",
                            height: 25,
                            borderRadius: "50px",
                            background: ALNColors.indigo,
                        }}
                    >
                        <ToggleButton
                            value="manual"
                            style={{
                                backgroundColor: selected === "manual" ? ALNColors.white : ALNColors.indigo,
                                color: selected === "manual" ? ALNColors.white : ALNColors.black,
                                borderRadius: "50%",
                                width: 25,
                                height: 25,
                            }}
                        >
                            
                        </ToggleButton>
                        <ToggleButton
                            value="auto"
                            style={{
                                backgroundColor: selected === "auto" ? ALNColors.white : ALNColors.indigo,
                                color: selected === "auto" ? ALNColors.white : ALNColors.black,
                                borderRadius: "50%",
                                width: 25,
                                height: 25,
                            }}
                        >
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ALNLabel
                        sx={{
                            fontFamily: "OpenSans",
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: 0.02,
                            textAlign: "left",
                            textTransform: 'capitalize',
                            marginLeft: "10px",
                            color: ALNColors.green_very_dark,
                        }}
                    >
                        {selected === "auto" ? "Auto Assign" : "Auto Assign"}
                    </ALNLabel>
                </Box> */}
            </Box>
            {/* Drag Part */}
            {resultType === "Team Member" && 
                <TeamMember />
            }
            {resultType === "Time in Queue" && 
                <TimeInQueue />
            }
            {resultType === "Unassigned" && 
                <Unassigned />
            }
        </Box>
        <NewUserModal
            isEdit={false}
            open={addTeamModalOpen}
            onClose={() => onAddTeamMemberModalClose()}
            user={{}}
        />
        {/* Profile Menu */}
        <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleProfileMenuClose}
                anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
                }}
        >
            <Divider />
            <MenuItem
                onClick={() => {
                    handleUpdateUserModal();
                    setModalTitle("Change Password");
                    setCurrentlyChanged("password");
                }}
            >
                <ALNLabel variant="body1">Change Password</ALNLabel>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleUpdateUserModal();
                    setModalTitle("Change Company");
                    setCurrentlyChanged("company");
                }}
            >
                <ALNLabel variant="body1">Change Company</ALNLabel>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <Logout />
                <ALNLabel variant="body1">Log out</ALNLabel>
            </MenuItem>
        </Menu>
        {user && (
            <UpdateUserModal
                currentlyChanged={currentlyChanged}
                open={updateUserModal}
                onClose={handleCloseUpdateUserModal}
                user={user!}
                title={modalTitle}
            />
        )}
    </Box>
    );
};

export default TheSalesDesk;
  