import React, { useState } from "react";
import {
  Box,
  MenuItem,
  TextField,
  ListSubheader,
  InputAdornment,
} from "@mui/material";
import { ReactComponent as SearchIconInWhite } from "../../assets/images/searchIconInWhite.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/check-icon.svg";
import { ALNColors } from "../../utils/color";

type User = {
  name: string;
  value: string;
};

interface SearchableSelectProps {
  onClickMenuItem: (value: string) => void;
  users: User [];
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({ onClickMenuItem, users }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>(users[0].value);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleMenuItemClick = (value: string) => {
    setSelectedValue(value);
    onClickMenuItem(value);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box>
      <ListSubheader>
        <TextField
            id="search-listing"
            label=""
            placeholder=""
            variant="standard"
            onChange={handleSearchChange}
            value={searchTerm}
            slotProps={{
            input: {
                startAdornment: (
                <InputAdornment position="start">
                    <SearchIconInWhite />
                </InputAdornment>
                ),
            },
            }}
            sx={{
                color: ALNColors.border_very_gray,
                height: "45px",
                marginTop: "25px",
                "& .MuiInputBase-root": {
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "21px",
                    letterSpacing: "0.02em",
                    textAlign: "left",
                    color: ALNColors.border_very_gray,
                    height: "100%",
                    border: "1px solid " + ALNColors.email_manual,
                    borderRadius: "8px",
                    backgroundColor: ALNColors.white,
                    padding: "8px 20px",
                },
                "& .MuiInputBase-root::before": {
                    border: "none !important",
                },
                "& .MuiInputBase-root::after": {
                    border: "none",
                },
                '& input[type="text"]': {
                    border: "none",
                    paddingLeft: "0",
                },
            }}
        />
      </ListSubheader>
      <Box
        sx={{
            padding: '18px',
            paddingTop: "0px",
            width: '210px',
        }}
      >
        <Box
            sx={{
                boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.14)',
                borderRadius: "7px",
                height: "185px",
                overflowY: "scroll",
                scrollbarWidth: 'none',
                padding: "10px 5px",
                marginTop: "6px",
            }}
        >
            {filteredUsers.map((user) => (
                <MenuItem
                    key={user.value}
                    value={user.value}
                    onClick={() => handleMenuItemClick(user.value)}
                    selected={user.value === selectedValue}
                    sx={{
                        paddingLeft: user.value === selectedValue ? "10px" : "32px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderRadius: "6px",
                        fontSize: "14px",
                        color: ALNColors.border_very_gray,
                        background: user.value === selectedValue ? ALNColors.blue_background : ALNColors.white,
                        gap: "7px"
                    }}
                >
                  {user.value === selectedValue && 
                    <CheckIcon />
                  }
                    {user.name}
                </MenuItem>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchableSelect;
