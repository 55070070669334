export const tab = 9;
export const enter = 13;
export const escape = 27;
export const space = 32;
export const pageUp = 33;
export const pageDown = 34;
export const end = 35;
export const home = 36;
export const arrowLeft = 37;
export const arrowUp = 38;
export const arrowRight = 39;
export const arrowDown = 40;
