/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AssignmentWithEmail } from './AssignmentWithEmail';
import {
    AssignmentWithEmailFromJSON,
    AssignmentWithEmailFromJSONTyped,
    AssignmentWithEmailToJSON,
} from './AssignmentWithEmail';

/**
 * Represents the current assignments for all team members and unassigned queue
 * @export
 * @interface TeamAssignments
 */
export interface TeamAssignments {
    [key: string]: Array<AssignmentWithEmail> | any;
    /**
     * Array of unassigned tickets with their email details
     * @type {Array<AssignmentWithEmail>}
     * @memberof TeamAssignments
     */
    unassigned: Array<AssignmentWithEmail>;
}

/**
 * Check if a given object implements the TeamAssignments interface.
 */
export function instanceOfTeamAssignments(value: object): value is TeamAssignments {
    if (!('unassigned' in value) || value['unassigned'] === undefined) return false;
    return true;
}

export function TeamAssignmentsFromJSON(json: any): TeamAssignments {
    return TeamAssignmentsFromJSONTyped(json, false);
}

export function TeamAssignmentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamAssignments {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'unassigned': ((json['unassigned'] as Array<any>).map(AssignmentWithEmailFromJSON)),
    };
}

export function TeamAssignmentsToJSON(value?: TeamAssignments | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'unassigned': ((value['unassigned'] as Array<any>).map(AssignmentWithEmailToJSON)),
    };
}

