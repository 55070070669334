import React from 'react';
import { Box, Modal } from '@mui/material';
import SearchableSelect from '../SearchableSelect';
import ALNLabel from '../../../components/label/ALNLabel';
import ALNBtn from '../../../components/form/ALNBtn/ALNBtn';
import { ALNColors } from '../../../utils/color';

interface AssignMailModalProps {
  assignEmailModalOpen: boolean;
  handleAssignEmailModalClose: () => void;
  handleAssignEmail: (email: string) => void;
  handleAssignEmailSend: () => void;
  users: Array<{ name: string; value: string }>;
  modalTitle: string;
  cancelButton: string;
  confirmButton: string;
}

function AssignMailModal ({ assignEmailModalOpen, handleAssignEmailModalClose, handleAssignEmail, handleAssignEmailSend, users, modalTitle, cancelButton, confirmButton }: AssignMailModalProps) {
  return (
    <Modal
        open={assignEmailModalOpen}
        onClose={handleAssignEmailModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '245px',
            height: '356px',
            bgcolor: ALNColors.white,
            borderRadius: '12px',
            border: '1px solid '+ALNColors.email_manual,
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "25px",
            paddingRight: "25px"
        }}>
            <ALNLabel
                sx={{
                    fontFamily: "OpenSans",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: 0.02,
                    textAlign: "left",
                    textTransform: 'capitalize',
                    color: ALNColors.email_manual,
                }}
            >
                {modalTitle}
            </ALNLabel>
            <SearchableSelect 
                onClickMenuItem={handleAssignEmail}
                users={users}
            />
            <Box
                sx={{
                    position: "absolute",
                    bottom: "20px",
                    left: "40px",
                    display: "flex",
                    gap: "10px",
                }}
            >
                <ALNBtn variant="contained" onClick = { () => {handleAssignEmailModalClose()} } 
                    sx={{ 
                        background: ALNColors.white,
                        width: "103px"
                    }}
                >
                    <ALNLabel
                        sx={{
                            fontFamily: "OpenSans",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: 0.02,
                            textAlign: "left",
                            textTransform: 'capitalize',
                            color: ALNColors.black,
                        }}
                    >
                        {cancelButton}
                    </ALNLabel>
                </ALNBtn>
                <ALNBtn variant="contained" onClick = { () => {handleAssignEmailSend()} } 
                    sx={{ 
                        background: ALNColors.primary_btn,
                        width: "105px"
                    }}
                >
                    <ALNLabel
                        sx={{
                            fontFamily: "OpenSans",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: 0.02,
                            textAlign: "left",
                            textTransform: 'capitalize',
                            color: ALNColors.white,
                        }}
                    >
                        {confirmButton}
                    </ALNLabel>
                </ALNBtn>
            </Box>
        </Box>
    </Modal>
  );
}
export default AssignMailModal;
