import {
  Box,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  CardContent,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Services } from "../Services";
import { Global } from "../Global";

import MailIcon from "@mui/icons-material/Mail";
import SendingEmail from "./../assets/images/sending-email.svg";
import BarChart from "../components/charts/BarChart";
import LineChart from "../components/charts/LineChart";
import GaugeChart from "../components/charts/GaugeChart";
import PieChart from "../components/charts/PieChart";
import ALNLabel from "../components/label/ALNLabel";
import { DashboardResult } from "../services/models/DashboardResult";
import { TimePeriod } from "../services/models/TimePeriod";
import { MailBox } from "../services/models/MailBox";
import { ALNColors } from "../utils/color";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { User } from "../services/models/User";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";

function Dashboard() {
  const token = Global.getJwtToken();
  const [user, setUser] = useState<User | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
      "password" | "company"
  >("password");
  useEffect(() => {
      Global.getUser().then((user) => {
          setUser(user);
      });
  }, [token]);

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
      setAnchorEl(null);
  };
  const handleUpdateUserModal = () => {
      setUpdateUserModal(true);
  };
  const handleCloseUpdateUserModal = () => {
      setUpdateUserModal(false);
  };
  const handleLogout = () => {
      Global.setJwtToken(undefined);
  };
  const [dashboardData, setDashboardData] = useState<
    DashboardResult | undefined
  >();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Day);
  const [mailbox, setMailbox] = useState<string>(
    Global.getGlobal().CurrentMailbox ?? "",
  );
  const [allMailboxes, setAllMailboxes] = useState<string[]>([]);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  });

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const calculateChartDimensions = useCallback(() => {
    let width = Math.floor((window.innerWidth - 250) * 0.25);
    let height = Math.floor((window.innerHeight - 150) * 0.125);

    setChartDimensions({
      width: width > 300 ? width : 300,
      height: height > 100 ? height : 100,
    });
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailboxes: MailBox[]) => {
        setMailbox(Global.getGlobal().CurrentMailbox ?? "");
        setAllMailboxes(mailboxes.map((mailbox) => mailbox.emailAddress!));
      });
  }, [token]);

  useEffect(() => {
    Services.dashboardService
      .getDashboardData(Global.getJwtToken()!, timePeriod, mailbox)
      .then((data) => {
        setDashboardData(data);
      });
  }, [timePeriod, mailbox]);

  useEffect(() => {
    calculateChartDimensions(); // Initial calculation
    window.addEventListener("resize", calculateChartDimensions); // Recalculate on window resize
    return () => window.removeEventListener("resize", calculateChartDimensions); // Cleanup listener
  }, [calculateChartDimensions]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "25px 50px",
        background:
          "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <ALNLabel
          sx={{
            fontSize: "10px",
            lineHeight: "12px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Dashboard
        </ALNLabel>
        <Box
          sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: "10px",
              padding: "8px 13px",
          }}
          onClick={handleUserInfo}
        >
          <Box
              component="img"
              src={avatarImg}
              alt="avatar"
              sx={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
              }}
          />
          <Box sx={{ margin: "0 0px" }}>
              <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
              <ALNLabel sx={{ display: "none" }}>
              {"["}
              {Global.getGlobal().company}
              {"]"}
              </ALNLabel>
          </Box>
          <DownIcon />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        }}
      >
        <ALNLabel
          sx={{
            fontSize: "40px",
            lineHeight: "50px",
            fontWeight: 400,
            width: "fit-content",
          }}
        >
          Dashboard
        </ALNLabel>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          sx={{
            background: ALNColors.white,
            padding: "16px",
            borderRadius: "16px",
            border: "1px solid " + ALNColors.background_gray,
            flexGrow: 1,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              paddingBottom: "10px",
            }}
          >
            <Box sx={{ minWidth: 200, display: "flex", alignItems: "center" }}>
              <MailIcon
                sx={{
                  marginRight: 1,
                  marginLeft: 5,
                  color: ALNColors.indigo,
                  alignItems: "baseline",
                }}
              />
              <FormControl
                variant="standard"
                sx={{ minWidth: 300, color: ALNColors.indigo }}
              >
                <InputLabel id="mailbox-select-label">Mailbox</InputLabel>
                <Select
                  labelId="mailbox-select-label"
                  id="mailbox-select"
                  value={mailbox}
                  label="Mailbox"
                  onChange={(event) => {
                    Global.getGlobal().CurrentMailbox = event.target.value;
                    setMailbox(event.target.value);
                  }}
                >
                  {allMailboxes.map((mailbox) => (
                    <MenuItem key={mailbox} value={mailbox}>
                      {mailbox}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <ToggleButtonGroup
              value={timePeriod}
              exclusive
              onChange={(event, newTimePeriod) => {
                if (newTimePeriod !== null) {
                  setTimePeriod(newTimePeriod);
                }
              }}
              aria-label="time period"
              sx={{
                minWidth: 500,
                justifyContent: "stretch",
                color: ALNColors.primary,
              }}
            >
              {Object.values(TimePeriod).map((period) => (
                <ToggleButton
                  key={period}
                  value={period}
                  aria-label={period}
                  sx={{
                    minWidth: 150,
                    border: "none",
                    "&.Mui-selected": {
                      backgroundColor: ALNColors.indigo,
                      color: ALNColors.white,
                      "&:hover": {
                        backgroundColor: ALNColors.white_blue,
                      },
                    },
                  }}
                >
                  {period}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Divider></Divider>

          {dashboardData && (
            <Box display="flex" flexDirection="row" flexWrap={"wrap"}>
              <Box
                display="flex"
                width={"60%"}
                flexDirection="column"
                height={chartDimensions.height * 8}
                ref={chartContainerRef}
              >
                <Box flexGrow={1} alignContent={"center"}>
                  <BarChart
                    data={dashboardData.emailVolume}
                    timePeriod={timePeriod}
                    numberOfEmails={dashboardData.numberOfEmails ?? 0}
                    width={chartDimensions.width * 1.5}
                    height={chartDimensions.height}
                  ></BarChart>
                </Box>
                <Divider></Divider>
                <Box flexGrow={1} alignContent={"center"}>
                  <LineChart
                    data={dashboardData.emailVolume}
                    timePeriod={timePeriod}
                    quoteValue={dashboardData.quoteValue ?? 0}
                    width={chartDimensions.width * 1.5}
                    height={chartDimensions.height}
                  ></LineChart>
                </Box>
                <Divider></Divider>
                <Box flexGrow={2}>
                  <ALNLabel
                    variant="h3"
                    sx={{
                      marginTop: 4,
                      marginBottom: 2,
                      align: "center",
                    }}
                  >
                    Processing Time
                  </ALNLabel>
                  <CardContent
                    sx={{
                      backgroundColor: ALNColors.white_green,
                      padding: "0px",
                    }}
                  >
                    <Box display="flex" justifyContent="space-around">
                      <GaugeChart
                        value={dashboardData.autoRespondTime ?? 0}
                        maxValue={10}
                        unit="seconds"
                        color={ALNColors.white_black}
                        width={chartDimensions.width}
                        height={chartDimensions.height * 2}
                        title={"Auto Respond"}
                      />
                      <GaugeChart
                        value={dashboardData.manualRespondTime ?? 0}
                        maxValue={3600}
                        unit="seconds"
                        color={ALNColors.error}
                        width={chartDimensions.width}
                        height={chartDimensions.height * 2}
                        title={"Manual Respond"}
                      />
                    </Box>
                    <Divider></Divider>
                  </CardContent>
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem></Divider>
              <Box flex={2} width={"39%"}>
                <PieChart
                  data={dashboardData.quotePercentage}
                  width={chartDimensions.width * 1.3}
                  height={chartDimensions.height * 4}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Divider />
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Password");
                setCurrentlyChanged("password");
            }}
        >
            <ALNLabel variant="body1">Change Password</ALNLabel>
        </MenuItem>
        <MenuItem
            onClick={() => {
                handleUpdateUserModal();
                setModalTitle("Change Company");
                setCurrentlyChanged("company");
            }}
        >
            <ALNLabel variant="body1">Change Company</ALNLabel>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
            <Logout />
            <ALNLabel variant="body1">Log out</ALNLabel>
        </MenuItem>
      </Menu>
      {user && (
          <UpdateUserModal
              currentlyChanged={currentlyChanged}
              open={updateUserModal}
              onClose={handleCloseUpdateUserModal}
              user={user!}
              title={modalTitle}
          />
      )}
    </Box>
  );
}

export default Dashboard;
