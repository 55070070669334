import React, { useState, useEffect } from "react";
import { Box, Menu, MenuItem, Divider } from "@mui/material";
import { ALNColors } from "../utils/color";
import ALNLabel from "../components/label/ALNLabel";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import avatarImg from "../assets/images/avatar.png";
import { User } from "../services/models/User";
import { Global } from "../Global";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";
  
function Settings() {
    const [user, setUser] = useState<User | undefined>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [updateUserModal, setUpdateUserModal] = useState(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [currentlyChanged, setCurrentlyChanged] = useState<
        "password" | "company"
    >("password");
    let token = Global.getJwtToken();
    useEffect(() => {
        Global.getUser().then((user) => {
            setUser(user);
        });
    }, [token]);

    const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };
    const handleUpdateUserModal = () => {
        setUpdateUserModal(true);
    };
    const handleCloseUpdateUserModal = () => {
        setUpdateUserModal(false);
    };
    const handleLogout = () => {
        Global.setJwtToken(undefined);
    };
return (
    <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            padding: "25px 50px",
            background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
        }}
    >
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
                Settings
            </ALNLabel>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    gap: "10px",
                    padding: "8px 13px",
                }}
                onClick={handleUserInfo}
            >
                <Box
                    component="img"
                    src={avatarImg}
                    alt="avatar"
                    sx={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                    }}
                />
                <Box sx={{ margin: "0 0px" }}>
                    <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
                    <ALNLabel sx={{ display: "none" }}>
                    {"["}
                    {Global.getGlobal().company}
                    {"]"}
                    </ALNLabel>
                </Box>
                <DownIcon />
            </Box>
        </Box>
        <Box
            sx={{
                background: ALNColors.white,
                padding: "25px 25px", 
                borderRadius : "16px",
                border: "1px solid "+ALNColors.background_gray,
                height: 'calc(100vh - 150px)',
            }}
        >
            <Box
                sx={{
                    flex: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddtingTop: "0px",
                }}
            >
            <ArrowBackIcon
                style={{
                    marginRight: "14px",
                    cursor: "pointer",
                    paddingTop: "11px"
            }}
                onClick={() => {
                    // Global.getGlobal().CurrentPage = Pages.Dashboard;
            }}
            />
            <ALNLabel sx={{ fontSize: "24px", paddingTop: "10px", lineHeight: "18px", fontWeight: 700 }}>
                Settings
            </ALNLabel>
            </Box>
        </Box>
        {/* Profile Menu */}
        <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
        >
            <Divider />
            <MenuItem
                onClick={() => {
                    handleUpdateUserModal();
                    setModalTitle("Change Password");
                    setCurrentlyChanged("password");
                }}
            >
                <ALNLabel variant="body1">Change Password</ALNLabel>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleUpdateUserModal();
                    setModalTitle("Change Company");
                    setCurrentlyChanged("company");
                }}
            >
                <ALNLabel variant="body1">Change Company</ALNLabel>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <Logout />
                <ALNLabel variant="body1">Log out</ALNLabel>
            </MenuItem>
        </Menu>
        {user && (
            <UpdateUserModal
                currentlyChanged={currentlyChanged}
                open={updateUserModal}
                onClose={handleCloseUpdateUserModal}
                user={user!}
                title={modalTitle}
            />
        )}
    </Box>
);
};

export default Settings;
  