/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignableUser,
  SalesDeskAssignment,
  TeamAssignments,
} from '../models/index';
import {
    AssignableUserFromJSON,
    AssignableUserToJSON,
    SalesDeskAssignmentFromJSON,
    SalesDeskAssignmentToJSON,
    TeamAssignmentsFromJSON,
    TeamAssignmentsToJSON,
} from '../models/index';

export interface AssignEmailRequest {
    authorization: string;
    mailbox: string;
    id: string;
    assignTo: string;
    prevId?: string;
    nextId?: string;
}

export interface AssignEmail0Request {
    authorization: string;
    mailbox: string;
    id: string;
    assignTo: string;
    prevId?: string;
    nextId?: string;
}

export interface GetAssignableUsersRequest {
    authorization: string;
}

export interface GetAssignableUsers0Request {
    authorization: string;
}

export interface GetSalesDeskAssignmentsRequest {
    authorization: string;
    mailbox: string;
}

export interface GetSalesDeskAssignments0Request {
    authorization: string;
    mailbox: string;
}

export interface GetTeamAssignmentsRequest {
    authorization: string;
    mailbox: string;
}

export interface GetTeamAssignments0Request {
    authorization: string;
    mailbox: string;
}

export interface IgnoreAssignmentRequest {
    authorization: string;
    mailbox: string;
    emailId: string;
}

export interface IgnoreAssignment0Request {
    authorization: string;
    mailbox: string;
    emailId: string;
}

export interface RemoveAssignmentRequest {
    authorization: string;
    mailbox: string;
    emailId: string;
}

export interface RemoveAssignment0Request {
    authorization: string;
    mailbox: string;
    emailId: string;
}

export interface UnassignEmailRequest {
    authorization: string;
    mailbox: string;
    id: string;
    prevId?: string;
    nextId?: string;
}

export interface UnassignEmail0Request {
    authorization: string;
    mailbox: string;
    id: string;
    prevId?: string;
    nextId?: string;
}

/**
 * 
 */
export class SalesDeskApi extends runtime.BaseAPI {

    /**
     * Assigns an email to a specific team member with optional ordering
     */
    async assignEmailRaw(requestParameters: AssignEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SalesDeskAssignment>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling assignEmail().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling assignEmail().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assignEmail().'
            );
        }

        if (requestParameters['assignTo'] == null) {
            throw new runtime.RequiredError(
                'assignTo',
                'Required parameter "assignTo" was null or undefined when calling assignEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['assignTo'] != null) {
            queryParameters['assignTo'] = requestParameters['assignTo'];
        }

        if (requestParameters['prevId'] != null) {
            queryParameters['prevId'] = requestParameters['prevId'];
        }

        if (requestParameters['nextId'] != null) {
            queryParameters['nextId'] = requestParameters['nextId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/assignEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SalesDeskAssignmentFromJSON(jsonValue));
    }

    /**
     * Assigns an email to a specific team member with optional ordering
     */
    async assignEmail(authorization: string, mailbox: string, id: string, assignTo: string, prevId?: string, nextId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SalesDeskAssignment> {
        const response = await this.assignEmailRaw({ authorization: authorization, mailbox: mailbox, id: id, assignTo: assignTo, prevId: prevId, nextId: nextId }, initOverrides);
        return await response.value();
    }

    /**
     * Assigns an email to a specific team member with optional ordering
     */
    async assignEmail_1Raw(requestParameters: AssignEmail0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SalesDeskAssignment>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling assignEmail_1().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling assignEmail_1().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assignEmail_1().'
            );
        }

        if (requestParameters['assignTo'] == null) {
            throw new runtime.RequiredError(
                'assignTo',
                'Required parameter "assignTo" was null or undefined when calling assignEmail_1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['assignTo'] != null) {
            queryParameters['assignTo'] = requestParameters['assignTo'];
        }

        if (requestParameters['prevId'] != null) {
            queryParameters['prevId'] = requestParameters['prevId'];
        }

        if (requestParameters['nextId'] != null) {
            queryParameters['nextId'] = requestParameters['nextId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/assignEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SalesDeskAssignmentFromJSON(jsonValue));
    }

    /**
     * Assigns an email to a specific team member with optional ordering
     */
    async assignEmail_1(authorization: string, mailbox: string, id: string, assignTo: string, prevId?: string, nextId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SalesDeskAssignment> {
        const response = await this.assignEmail_1Raw({ authorization: authorization, mailbox: mailbox, id: id, assignTo: assignTo, prevId: prevId, nextId: nextId }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of users who can be assigned to handle emails
     */
    async getAssignableUsersRaw(requestParameters: GetAssignableUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AssignableUser>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getAssignableUsers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/getAssignableUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssignableUserFromJSON));
    }

    /**
     * Retrieves a list of users who can be assigned to handle emails
     */
    async getAssignableUsers(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AssignableUser>> {
        const response = await this.getAssignableUsersRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of users who can be assigned to handle emails
     */
    async getAssignableUsers_2Raw(requestParameters: GetAssignableUsers0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AssignableUser>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getAssignableUsers_2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/getAssignableUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssignableUserFromJSON));
    }

    /**
     * Retrieves a list of users who can be assigned to handle emails
     */
    async getAssignableUsers_2(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AssignableUser>> {
        const response = await this.getAssignableUsers_2Raw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all assignments for a specific mailbox
     */
    async getSalesDeskAssignmentsRaw(requestParameters: GetSalesDeskAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SalesDeskAssignment>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getSalesDeskAssignments().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling getSalesDeskAssignments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/getSalesDeskAssignments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SalesDeskAssignmentFromJSON));
    }

    /**
     * Retrieves all assignments for a specific mailbox
     */
    async getSalesDeskAssignments(authorization: string, mailbox: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SalesDeskAssignment>> {
        const response = await this.getSalesDeskAssignmentsRaw({ authorization: authorization, mailbox: mailbox }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all assignments for a specific mailbox
     */
    async getSalesDeskAssignments_3Raw(requestParameters: GetSalesDeskAssignments0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SalesDeskAssignment>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getSalesDeskAssignments_3().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling getSalesDeskAssignments_3().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/getSalesDeskAssignments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SalesDeskAssignmentFromJSON));
    }

    /**
     * Retrieves all assignments for a specific mailbox
     */
    async getSalesDeskAssignments_3(authorization: string, mailbox: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SalesDeskAssignment>> {
        const response = await this.getSalesDeskAssignments_3Raw({ authorization: authorization, mailbox: mailbox }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the current state of all assignments grouped by team member
     */
    async getTeamAssignmentsRaw(requestParameters: GetTeamAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamAssignments>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getTeamAssignments().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling getTeamAssignments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/getTeamAssignments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamAssignmentsFromJSON(jsonValue));
    }

    /**
     * Retrieves the current state of all assignments grouped by team member
     */
    async getTeamAssignments(authorization: string, mailbox: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamAssignments> {
        const response = await this.getTeamAssignmentsRaw({ authorization: authorization, mailbox: mailbox }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the current state of all assignments grouped by team member
     */
    async getTeamAssignments_4Raw(requestParameters: GetTeamAssignments0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamAssignments>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getTeamAssignments_4().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling getTeamAssignments_4().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/getTeamAssignments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamAssignmentsFromJSON(jsonValue));
    }

    /**
     * Retrieves the current state of all assignments grouped by team member
     */
    async getTeamAssignments_4(authorization: string, mailbox: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamAssignments> {
        const response = await this.getTeamAssignments_4Raw({ authorization: authorization, mailbox: mailbox }, initOverrides);
        return await response.value();
    }

    /**
     * Marks an email assignment as ignored and removes it from the sales desk
     */
    async ignoreAssignmentRaw(requestParameters: IgnoreAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling ignoreAssignment().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling ignoreAssignment().'
            );
        }

        if (requestParameters['emailId'] == null) {
            throw new runtime.RequiredError(
                'emailId',
                'Required parameter "emailId" was null or undefined when calling ignoreAssignment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['emailId'] != null) {
            queryParameters['emailId'] = requestParameters['emailId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/ignoreAssignment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Marks an email assignment as ignored and removes it from the sales desk
     */
    async ignoreAssignment(authorization: string, mailbox: string, emailId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ignoreAssignmentRaw({ authorization: authorization, mailbox: mailbox, emailId: emailId }, initOverrides);
    }

    /**
     * Marks an email assignment as ignored and removes it from the sales desk
     */
    async ignoreAssignment_5Raw(requestParameters: IgnoreAssignment0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling ignoreAssignment_5().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling ignoreAssignment_5().'
            );
        }

        if (requestParameters['emailId'] == null) {
            throw new runtime.RequiredError(
                'emailId',
                'Required parameter "emailId" was null or undefined when calling ignoreAssignment_5().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['emailId'] != null) {
            queryParameters['emailId'] = requestParameters['emailId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/ignoreAssignment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Marks an email assignment as ignored and removes it from the sales desk
     */
    async ignoreAssignment_5(authorization: string, mailbox: string, emailId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ignoreAssignment_5Raw({ authorization: authorization, mailbox: mailbox, emailId: emailId }, initOverrides);
    }

    /**
     * Permanently removes an assignment from the system
     */
    async removeAssignmentRaw(requestParameters: RemoveAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling removeAssignment().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling removeAssignment().'
            );
        }

        if (requestParameters['emailId'] == null) {
            throw new runtime.RequiredError(
                'emailId',
                'Required parameter "emailId" was null or undefined when calling removeAssignment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['emailId'] != null) {
            queryParameters['emailId'] = requestParameters['emailId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/removeAssignment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permanently removes an assignment from the system
     */
    async removeAssignment(authorization: string, mailbox: string, emailId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeAssignmentRaw({ authorization: authorization, mailbox: mailbox, emailId: emailId }, initOverrides);
    }

    /**
     * Permanently removes an assignment from the system
     */
    async removeAssignment_6Raw(requestParameters: RemoveAssignment0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling removeAssignment_6().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling removeAssignment_6().'
            );
        }

        if (requestParameters['emailId'] == null) {
            throw new runtime.RequiredError(
                'emailId',
                'Required parameter "emailId" was null or undefined when calling removeAssignment_6().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['emailId'] != null) {
            queryParameters['emailId'] = requestParameters['emailId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/removeAssignment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permanently removes an assignment from the system
     */
    async removeAssignment_6(authorization: string, mailbox: string, emailId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeAssignment_6Raw({ authorization: authorization, mailbox: mailbox, emailId: emailId }, initOverrides);
    }

    /**
     * Moves an assignment back to the unassigned queue with optional ordering
     */
    async unassignEmailRaw(requestParameters: UnassignEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SalesDeskAssignment>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling unassignEmail().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling unassignEmail().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling unassignEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['prevId'] != null) {
            queryParameters['prevId'] = requestParameters['prevId'];
        }

        if (requestParameters['nextId'] != null) {
            queryParameters['nextId'] = requestParameters['nextId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/unassignEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SalesDeskAssignmentFromJSON(jsonValue));
    }

    /**
     * Moves an assignment back to the unassigned queue with optional ordering
     */
    async unassignEmail(authorization: string, mailbox: string, id: string, prevId?: string, nextId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SalesDeskAssignment> {
        const response = await this.unassignEmailRaw({ authorization: authorization, mailbox: mailbox, id: id, prevId: prevId, nextId: nextId }, initOverrides);
        return await response.value();
    }

    /**
     * Moves an assignment back to the unassigned queue with optional ordering
     */
    async unassignEmail_7Raw(requestParameters: UnassignEmail0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SalesDeskAssignment>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling unassignEmail_7().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling unassignEmail_7().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling unassignEmail_7().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['prevId'] != null) {
            queryParameters['prevId'] = requestParameters['prevId'];
        }

        if (requestParameters['nextId'] != null) {
            queryParameters['nextId'] = requestParameters['nextId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/salesdesk/unassignEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SalesDeskAssignmentFromJSON(jsonValue));
    }

    /**
     * Moves an assignment back to the unassigned queue with optional ordering
     */
    async unassignEmail_7(authorization: string, mailbox: string, id: string, prevId?: string, nextId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SalesDeskAssignment> {
        const response = await this.unassignEmail_7Raw({ authorization: authorization, mailbox: mailbox, id: id, prevId: prevId, nextId: nextId }, initOverrides);
        return await response.value();
    }

}
