import React from 'react';
import { Draggable } from '../../components/drags/DragComponents';
import { Box } from "@mui/material";
import type {
  DraggableProvided,
  DraggableStateSnapshot,
} from '../../components/drags/DragComponents';
import EmailDataList from './emaildata-list';
import type { EmailData, Team } from './types';
import { ALNColors } from '../../utils/color';

interface Props {
  title: string;
  emailDatas: EmailData[];
  index: number;
  isScrollable?: boolean;
  isCombineEnabled?: boolean;
  useClone?: boolean;
  onAssignEmailModalOpen: (team: string) => void;
  onIgnoreEmailModalOpen: (email: EmailData) => void;
  onRemoveEmailModalOpen: (email: EmailData) => void;
  onDetailedEmailModalOpen: (email: EmailData) => void;
}

const EmailDataLists: React.FC<Props> = ({
  title,
  emailDatas,
  index,
  isScrollable = false,
  isCombineEnabled = false,
  useClone = false,
  onAssignEmailModalOpen,
  onIgnoreEmailModalOpen,
  onRemoveEmailModalOpen,
  onDetailedEmailModalOpen,
}) => {
  return (
    <Draggable draggableId={title} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            marginTop: '5px',
            marginLeft: "0px",
            marginRight: "25px",
            marginBottom: "15px",
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Team Head */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
              backgroundColor: snapshot.isDragging
                ? ALNColors.background_very_gray
                : ALNColors.background_very_gray,
              transition: 'background-color 0.2s ease',
              '&:hover': {
                backgroundColor: ALNColors.primary_btn,
              },
            }}
          >
            {/* TeamMember Title */}
            <Box
              {...provided.dragHandleProps}
              aria-label={`${title} emailData list`}
              sx={{
                position: 'relative',
                transition: 'background-color ease 0.2s',
                flexGrow: 1,
                userSelect: 'none',
                color: ALNColors.green_very_dark,
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '16px',
                lineHeight: '16px',
                fontWeight: 700,
                '&:focus': {
                  outline: '2px solid '+ALNColors.email_manual,
                  outlineOffset: '2px',
                },
                '&:hover': {
                  color: ALNColors.white,
                },
              }}
            >
              {title}
            </Box>
          </Box>
          <EmailDataList
            listId={title}
            listType="QUOTE"
            style={{
              backgroundColor: snapshot.isDragging ? 'lightgray' : undefined,
            }}
            emailDatas={emailDatas}
            internalScroll={isScrollable}
            isCombineEnabled={isCombineEnabled}
            useClone={useClone}
            onAssignEmailModalOpen={(team) => onAssignEmailModalOpen(team)}
            onIgnoreEmailModalOpen={(email) => onIgnoreEmailModalOpen(email)}
            onRemoveEmailModalOpen={(email) => onRemoveEmailModalOpen(email)}
            onDetailedEmailModalOpen={(email) => onDetailedEmailModalOpen(email)}
          />
        </Box>
      )}
    </Draggable>
  );
};

export default EmailDataLists;
